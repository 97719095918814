/* eslint-disable no-undef */
import axios from 'axios';
import Toast from './components/ui/Toast';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_SERVER_URL + '/api/';

export const instance = axios.create({
	baseURL: BASE_URL,
	validateStatus: (status) => status !== 401 && status !== 403
});

export default instance;

// To include the X-CSRFToken in request headers
export const ResponseInterceptor = () => {
	const profileData = useSelector((state) => state.profile.profilesData);

	useEffect(() => {
		if (!profileData) return;
		const token = profileData.uid;
		instance.interceptors.request.use(config => {
			if (config.method !== 'get' && token) {
				config.headers['X-CSRFToken'] = `${token}`;
			}
		return config;
		});
	}, [profileData]);

	return null;
};

// Default GET, PUT, POST, DELETE requests 
export const getRequest = async (url, options = {}) => {
	const config = {
		...options,
		withCredentials: true // Include cookies in the request
	};
	return instance
		.get(url, config)
		.then((response) => {
			if (response.status === 200) return response.data;
			if (response.status === 204) return response.data;
			if (response.status === 401) {
				console.log('getRequest: status: ' + response.status + ': ' + response.data);
				throw new Error('401: You are not authorised');
			}
			if (response.status === 403) {
				console.log('getRequest: status: ' + response.status + ': ' + response.data);
				throw new Error('403: You do not have access to that page');
			}
			if (response.status === 404) {
				console.log('getRequest: status: ' + response.status + ': Not Found');
				throw new Error('404: Not Found');
			}
			if (options?.responseType === 'blob') {
				response.status = '500';
				const reader = new FileReader();
				reader.addEventListener('loadend', () => {
					const data = JSON.parse(reader.result);
					response.status = data.status;
					response.message = data.detail;
					Toast({ status: 'error', message: 'Download failed: ' + data.detail });
					throw new Error(data.detail);
				});

				reader.readAsText(response.data);
			} else {
				console.log('getRequest: status: ' + response.status + ': ' + response.data.detail);
				throw new Error(response.data.detail);
			}
		})
		.catch((error) => {
			console.log('getRequest: ' + error.message);
			// throw new Error(error.message);
		});
};

export const postRequest = async (url, data, options = {}) => {
	
	const config = {
		...options,
		withCredentials: true // Include cookies in the request
	};
	return instance
		.post(url, data, config)
		.then((response) => {
			if (response.status === 200 || response.status === 201) return response.data;
			if (response.status === 401) {
				console.log('postRequest: status: ' + response.status + ': ' + response.data);
				throw new Error('401: You are not authorised');
			}
			if (response.status === 403) {
				console.log('postRequest: status: ' + response.status + ': ' + response.data);
				throw new Error('403: You do not have access to that page');
			}
			console.log('postRequest: status: ' + response.status + ': ' + response.message);
			if (!response?.data?.detail) {
				response.data = { detail: response.status + ': Server Error' };
			}
			throw new Error(response?.data?.detail);
		})
		.catch((error) => {
			let msg = error.message;

			if (msg.includes('network-related') || msg.includes('provider: TCP Provider') || msg.includes('Connection Timeout Expired')) {
				msg = 'Database Connection Error';
			}

			console.log('postRequest: ' + msg);
			throw new Error(msg);
		});
};

export const putRequest = async (url, data) => {
	const config = {
		withCredentials: true // Include cookies in the request
	};
	return instance
		.put(url, data, config)
		.then((response) => {
			if (response.status === 200 || response.status === 201) return response.data;
			if (response.status === 401) {
				console.log('postRequest: status: ' + response.status + ': ' + response.data);
				throw new Error('401: You are not authorised');
			}
			if (response.status === 403) {
				console.log('postRequest: status: ' + response.status + ': ' + response.data);
				throw new Error('403: You do not have access to that page');
			}
			console.log('postRequest: status: ' + response.status + ': ' + response.message + ': ' + response.data.detail);
			if (!response?.data?.detail) {
				if (response.data.errors) {
					var err = Object.keys(response.data.errors)[0];
					response.data = { detail: response.status + ': ' + response.data.errors[err][0] };
				} else {
					response.data = { detail: response.status + ': Server Error' };
				}
			}
			throw new Error(response.data.detail);
		})
		.catch((error) => {
			console.log('putRequest: ' + error.message);
			throw new Error(error.message);
		});
};

export const deleteRequest = async (url, data) => {
	const config = {
		withCredentials: true // Include cookies in the request
	};
	return instance
		.delete(url, config)
		.then(response => {
			if (response.status === 200 || response.status === 201) return response.data;
			if (response.status === 401) {
				console.log("deleteRequest: status: " + response.status + ": " + response.data);
				throw new Error("401: You are not authorised");
			}
			if (response.status === 403) {
				console.log("deleteRequest: status: " + response.status + ": " + response.data);
				throw new Error("403: You do not have access to that page");
			}
			console.log("postRequest: status: " + response.status + ": " + response.message);
			throw new Error(response.data.detail);
		})
		.catch(error => {
		console.log("deleteRequest: " + error.message);
		throw new Error(error.message);
	});
};