import { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPirRequestData, resetPirRequestData } from '../../store/pirRequestActions';

import CreatePIR from './form/CreatePIR';
import SearchHeader from '../../components/ui/SearchHeader';

import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../packages/modal/Modal';
import useToggle from '../../packages/_utils/useToggle';
import Button from '../../packages/button/Button';

const Edit = () => {
	let params = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { spoNumber, id } = params;
	const byId = id;
	const dispatch = useDispatch();
	const pir = useSelector((state) => state.pirRequest.data);

	const [spoNo, setSpoNo] = useState(spoNumber);

	//#region search header
	const [showSearch, setShowSearch] = useState(!pir?.pir);
	const [autoHide, setAutoHide] = useState(true);
	const [searchValue, setSearchValue] = useState(spoNumber || (pir?.pir ?? ''));

	useEffect(() => {
		if (!pir.id) {
			setSearchValue('');
		}
	}, [pir]);

	const setPathName = (spoNum) => {
		let pathname = location.pathname.substring(0, location.pathname.indexOf('edit') + 4);
		if (spoNum) pathname += '/' + spoNum;
		navigate(pathname, { replace: true });
	};

	useEffect(() => {
		return () => {
			// dispatch(resetPirRequestData());
		};
	}, [dispatch]);

	useEffect(() => {
		if (autoHide) {
			setShowSearch(!(pir.id && searchValue !== ''));
		}
	}, [pir, searchValue, autoHide]);
	//#endregion

	const [toggle, isOpen] = useToggle();

	const confirmClick = () => {
		toggle();
		setPathName();
		setSearchValue('');
		setShowSearch(true);
	};

	return (
		<>

			<CreatePIR closeDrawer={toggle} />

			{isOpen && (
				<Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
					<ModalHeader>Clear PIR Project</ModalHeader>
					<ModalBody>
						<div className='d-flex flex-row bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								Clear PIR Project form and start over
								<p className='text-xs'>No changes will be made to the database.</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggle} color='danger'>
							Cancel
						</Button>
						<Button onClick={confirmClick} color='primary' className='mr-1'>
							Clear
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

export default Edit;
