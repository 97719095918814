import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import CommentForm from './CommentForm';
import Comment from './Comment';

import { getPirComments, updatePirComment, createPirComment, deletePirComment, setComments, toggleCommentsRefresh, toggleAddCommentLoading, toggleReplyCommentLoading } from '../../store/pirCommentsActions';
import { updateObject } from '../../utils/utility';
import FormField from '../../packages/form/FormField';
import Button from '../../packages/button/Button';
import { AnnotationIcon, ArrowRightIcon, ReplyIcon, UserCircleIcon, TrashIcon } from '@heroicons/react/solid';

import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../packages/modal/Modal';
import useToggle from '../../packages/_utils/useToggle';
import SpinnerIcon from '../../packages/button/SpinnerIcon';
import { postRequest } from '../../axios';
import Toast from '../ui/Toast';

const Comments = ({ pirRequestId, commentsData, commentType, detailId, info, pirInspectionNo }) => {
	const myComments = useSelector((state) => state.pirComments.allDataComments);
	const profileData = useSelector((state) => state.profile.profilesData);
	const [allComments, setAllComments] = useState([]);
	const dispatch = useDispatch();
	const currentUser = useSelector((state) => state.auth.user);
	const pirComments = useSelector((state) => (commentType === 'detailComment' ? state.pirComments.detailComments : state.pirComments.headerComments));
	const loaded = useSelector((state) => state.pirComments.loaded);
	const loading = useSelector((state) => state.pirComments.loading);
	const replyLoading = useSelector((state) => state.pirComments.replyLoading);
	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
	const [activeComment, setActiveComment] = useState(null);
	const [detailTotalCount, setDetailTotalCount] = useState(null);
	const [commentCount, setCommentCount] = useState(0);
	const [showReply, setShowReply] = useState(false);
	const [showComments, setShowComments] = useState(false);
	const [addCommentOpen, setAddCommentOpen] = useState(false);
	const [replyCommentOpen, setReplyCommentOpen] = useState(false);
	const [replyMessage, setReplyingToMessage] = useState(null);

	useEffect(() => {
		if (myComments !== null && commentType === 'inspectionComment') {
			let pushComments = [];
			myComments.forEach(element => {
				if (element.InspectionDetailId === pirInspectionNo) pushComments.push(element);
			});
			setAllComments(pushComments);
			setCommentCount(pushComments.length);
		}
		else if (myComments !== null && commentType === 'headerComment') {
			setAllComments(myComments);
			setCommentCount(myComments.length);
		}
	}, [myComments]);


	useEffect(() => {
		if (!pirComments || !loaded) return;
		setAddCommentOpen(false);
	}, [pirComments, loaded]);

	const addComment = async (text, replyId) => {
		const angularBracketsRegex = /^[^<>|=]*$/;
		if (!angularBracketsRegex.test(text)) {
			Toast({status: "warning", message: 'Invalid Characters in Comments'});
			return;
		}
		dispatch(toggleAddCommentLoading(true));
		let newComment = {};
		if (commentType === 'inspectionComment') {
			newComment = {
				RequestHeaderId: pirRequestId,
				InspectionDetailId: pirInspectionNo,
				Body: text
			};
		}
		else {
			newComment = {
				RequestHeaderId: pirRequestId,
				Body: text
			};
		}
		const createCommentResponse = await postRequest('comments/', newComment);
		if (createCommentResponse) {
			Toast({status: "success", message: 'Comment Added!'});
			dispatch(toggleAddCommentLoading(false));
			setAllComments([...allComments, createCommentResponse]);
			setCommentCount(commentCount + 1);
			dispatch(setComments([...allComments, createCommentResponse]));
			dispatch(toggleCommentsRefresh(false));
		}
		setActiveComment(null);
	};

	const deleteComment = (pirCommentId) => {
		dispatch(deletePirComment(pirCommentId));
		toggle();
		setAllComments(allComments.filter(x => x.id !== pirCommentId));
		setCommentCount(commentCount - 1);
		dispatch(setComments(allComments.filter(x => x.id !== pirCommentId)));
		dispatch(toggleCommentsRefresh(false));
		setActiveComment(null);
	};

	const replyComment = async (text, replyId) => {
		const angularBracketsRegex = /^[^<>|=]*$/;
		if (!angularBracketsRegex.test(text)) {
			Toast({status: "warning", message: 'Invalid Characters in Comments'});
			return;
		}
		dispatch(toggleReplyCommentLoading(true));
		const newReply = {
			Body: text,
			CommentId: activeComment.id
		};
		const createReplyResponse = await postRequest('commentsreply/', newReply);
		if (createReplyResponse) {
			dispatch(toggleReplyCommentLoading(false));
			var copyComments = JSON.parse(JSON.stringify(allComments));
			Toast({status: "success", message: 'Reply Added!'});
			for (let index = 0; index < copyComments.length; index++) {
				const element = copyComments[index];
				if (element.id === activeComment.id) {
					copyComments[index].Replies.push(createReplyResponse);
				}
			}
			setAllComments(copyComments);
			dispatch(setComments(copyComments));
			dispatch(toggleCommentsRefresh(false));
			setReplyCommentOpen(false);
			setActiveComment(null);
		}
	};

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		return date.toLocaleString();
	};

	const replies = (data) => {
		return (data.map(x => {
			return (
			<p className='px-4'>
				<div style={{display:"flex",alignItems:'center'}}>
					<UserCircleIcon className="w-6 h-6 text-secondary-2 mr-1" />
					<b style={{textTransform: 'uppercase'}}>
						{dropdownsData.Users.find(u => u.id === x.AuditUserId).username}
					</b>
					<span style={{ fontSize: '12px', color: '#6B7280' }}>
						&nbsp;({formatDate(x.AuditDateTime)})
					</span> - {x.Body}
				</div>
			</p>
		);}));
	};

	const updateComment = (text, spoCommentId) => {
		// get the comment
		const comment = pirComments.find((c) => c.id === spoCommentId);
		const updatedComment = updateObject(comment, { body: text, spo: allComments });
		dispatch(updatePirComment(updatedComment));
		setActiveComment(null);
	};

	const [toggle, isOpen] = useToggle();

	return (
		<>
			<div className='flex items-center px-4 py-2 bg-tertiary-cool-2 text-primary-2 h-10'>
				<h3 className='font-semibold mr-4'>
					Comments ({commentCount})
				</h3>
				<div className={`flex gap-x-2 text-xs font-bold items-center text-primary-2 flex-row-reversse cursor-pointer`}
					onClick={() => setShowComments(!showComments)}>
					<FormField
						label='Show Comments'
						name='showComments'
						type='checkbox'
						checked={showComments}
						onClick={() => setShowComments(!showComments)}
					/>
				</div>
			</div>
			<div className={`${!showComments && 'hidden'} px-4 py-2 border text-sm !border-secondary-3 shadow-sm bg-tertiary-cool-4`}>
				{allComments.map(x => {
					return (
						<div>
							{/* <span style={{fontSize: '12px'}}>Date here</span> */}
							<div style={{display:"flex",alignItems:'center'}}>
								<UserCircleIcon className="w-6 h-6 text-secondary-2 mr-1" />
								<span>
									<b style={{textTransform: 'uppercase'}}>{dropdownsData.Users.find(u => u.id === x.AuditUserId).username}</b>
									<span style={{ fontSize: '12px', color: '#6B7280' }}>
										&nbsp;({formatDate(x.AuditDateTime)})&nbsp;{x.InspectionDetailId && <>[{x.InspectionDetailId}]</>}
									</span> - <span>{x.Body}</span>
								</span> {/* Parent comment */}
								<ReplyIcon className='h-5 ml-2' style={{cursor: 'pointer'}}
								onClick={() => {
									if (profileData.role === 'View') return;
									setActiveComment({id: x.id});
									setReplyingToMessage({id: x.id, message: x.Body});
									setReplyCommentOpen(true);
									setAddCommentOpen(false);
								}}/>
								<TrashIcon className='h-5 ml-2' style={{color: '#c12127', cursor: 'pointer'}}
								onClick={() => {
									if (profileData.role === 'View') return;
									setActiveComment({ id: x.id, type: 'deleting' });
									toggle();
									// return deleteComment(x.id);
								}}/>
							</div>
							<span>{replies(x.Replies)}</span> {/* Child comment */}
						</div>
					);}
				)}
				{' '}
				{replyLoading ? <SpinnerIcon className={'!border-t-red-800'} /> : <p></p>}
				{replyCommentOpen && (
					<div className='flex-1 bg-tertiary-cool-4 px-2 mt-2 pt-2 rounded'>
						<i>Replying to - {replyMessage.message}</i>
						<CommentForm
							submitLabel='Reply'
							handleSubmit={replyComment}
							hasCancelButton
							handleCancel={() => {
								setReplyCommentOpen(false);
							}}
						/>
					</div>
				)}
				{currentUser.role !== 'view' ? (
					<Button
						className='mt-4 text-sm flex items-center'
						onClick={() => {
							setActiveComment(null);
							setAddCommentOpen(true);
							setReplyCommentOpen(false);
						}}
						disabled={profileData.role === 'View'}
					>
						{' '}
						{loading ? <SpinnerIcon className={'!border-t-red-800'} /> : <AnnotationIcon className='h-4 pr-2' />}
						Add Comment
					</Button>
				) : null}
				{addCommentOpen && (
					<div className='flex-1 bg-tertiary-cool-4 px-2 mt-2 pt-2 rounded'>
						<CommentForm
							submitLabel='Add'
							handleSubmit={addComment}
							hasCancelButton
							handleCancel={() => {
								setAddCommentOpen(false);
							}}
						/>
					</div>
				)}
			</div>

			<Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
				<ModalHeader>Delete Comment</ModalHeader>
				<ModalBody>
					<div className='d-flex flex-row bd-highlight mb-3'>
						<div className='p-2 bd-highlight'>
							<p>Are you sure you want to remove this comment?</p>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={toggle} color='danger' className='ignore-click-outside'>
						Cancel
					</Button>
					<Button onClick={() => deleteComment(activeComment.id)} color='primary' className='mr-1 ignore-click-outside'>
						Confirm
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Comments;

Comments.propTypes = {
	handleSubmit: PropTypes.func,
	pirRequestId: PropTypes.number,
	commentsData: PropTypes.array,
	commentType: PropTypes.string,
	detailId: PropTypes.number,
	pirInspectionNo: PropTypes.number,
	info: PropTypes.string
};
