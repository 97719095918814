import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	data: null
};

const sampleSlice = createSlice({
	name: 'sample',
	initialState: initialState,
	reducers: {
		getData: (state, action) => {
			state.data = action.payload;
		}
	}
});

export const sampleActions = sampleSlice.actions;

export default sampleSlice;
