import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { partsInspection, createPirInspection, updatePirInspection, getImageUrls } from '../../../store/pirInspectionActions';
import useToggle from '../../../packages/_utils/useToggle';
import { getFormOptions, resetPirRequestData, resetChanged, getPirDetailsById } from '../../../store/pirRequestActions';
import MainDetails from './MainDetails';
import InspectionDetails from './InspectionDetails';
import { setLoading } from '../../../store/globalActions';

const Form = (props) => {
	const { pirRequestNo, Id, byId, closeDrawer, fromUpload, isNew } = props;
	const pirRequestIdNo = Id || pirRequestNo;

	const pir = useSelector((state) => state.pirRequest.data);
	const allData = useSelector((state) => state.pirRequest.allData);
	const pirDetailsById = useSelector((state) => state.pirRequest.pirDetailsById);
	const uploaded = useSelector((state) => state.pirRequest.uploaded);
	const uploading = useSelector((state) => state.pirRequest.uploading);
	const changed = useSelector((state) => state.pirRequest.changed);
	const formOptions = useSelector((state) => state.pirRequest.formOptions);
	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);

	const dispatch = useDispatch();
	const [currentStep, setCurrentStep] = useState(0);
	// store the pir data and update it before mapping back to the store
	const [data, setData] = useState();
	const [isMultipleLocations, setMultipleLocations] = useState(false);

	useEffect(() => {
		dispatch(getPirDetailsById(pirRequestIdNo || 0));
		return () => {};
	}, [dispatch]);

	useEffect(() => {
		dispatch(partsInspection(pirRequestIdNo || 0));
		return () => {};
	}, [dispatch]);

	useEffect(() => {
		dispatch(getImageUrls(pirRequestIdNo || 0));
		return () => {};
	}, [dispatch]);

	useEffect(() => {
		if (pirDetailsById) {
			if (pirDetailsById.Locations.length > 1) setMultipleLocations(true);
			else setMultipleLocations(false);
		}
	}, [pirDetailsById]);

	useEffect(() => {
		if (formOptions.spoStatus.length === 0) dispatch(getFormOptions());
	}, [dispatch, formOptions]);

	const tempGetData = useCallback(
		(id) => {
			// return allData.filter((m) => m.id === id)[0];
			return pirDetailsById;
		},
		[pirDetailsById]
	);

	useEffect(() => {
		if (!pirRequestIdNo || formOptions.spoStatus.length === 0) return;
		if (!uploading && !fromUpload) {
			setData(tempGetData(pirRequestIdNo));
		}
	}, [pirRequestIdNo, dispatch, byId, formOptions, uploading, fromUpload, tempGetData]);

	useEffect(() => {
		if ((changed || !pir.id) && !uploaded) {
			if (allData && !allData.id && !pir.id) return;
			setData(tempGetData(pir.id));
			if (changed) {
				dispatch(setLoading());
				setCurrentStep(0);
				dispatch(resetChanged());
			}
		}
	}, [pir, changed, dispatch, uploaded, allData, tempGetData]);

	const handleNextStep = (newData, final = false, id = null) => {
		if (final && id===null) {
			dispatch(createPirInspection(newData));
			toggle();
			return;
		}
		if (final) {
			dispatch(updatePirInspection(id, newData));
			toggle();
			return;
		}
		console.log('handle next', data);
		console.log('Dropdowns', dropdownsData);
		setData(newData);
		setCurrentStep((prev) => prev + 1);
	};

	const handlePrevStep = (newData) => {
		setData(tempGetData(pirRequestIdNo));
		setCurrentStep((prev) => prev - 1);
	};

	const stepTitles = ['Request Details', 'Parts Inspection'];

	const steps = [
		<MainDetails
			isNew={isNew}
			data={data || pir}
			next={handleNextStep}
			isMultipleLocation={isMultipleLocations}
			typeOptions={formOptions.spoType}
			statusOptions={formOptions.spoStatus}
			ownerOptions={formOptions.users}
			closeDrawer={closeDrawer}
		/>,
		<InspectionDetails 
			pirId={pirRequestIdNo}
			data={data}
			next={handleNextStep}
			prev={handlePrevStep}
		/>
	];

	const [toggle] = useToggle();

	return (
		<>
			{(
				<div className='shadow-sm overflgow-hidden sm:rounded-md mx-auto min-w-full max-w-fit'>
					<div className='px-4 py-2 bg-tertiary-cool-2 text-left sm:px-6 rounded-t'>
						<h2 className='text-primary-2 font-semibold'>
							{stepTitles[currentStep]} &gt;{' '}
							{!pirRequestIdNo && !pir?.id
								? 'New Pir Request Creation'
								: `PIR Request : ${!byId ? pirRequestIdNo || pir.pir : !pir ? '' : pir.pir}`}
						</h2>
					</div>

					{steps[currentStep]}
				</div>
			)}
		</>
	);
};

export default Form;

Form.propTypes = {
	pirRequestNo: PropTypes.number,
	Id: PropTypes.string,
	byId: PropTypes.string,
	closeDrawer: PropTypes.func,
	fromUpload: PropTypes.string,
	isNew:PropTypes.bool
};
