import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import './RichTextEditor.css';

const TextEditor = ({ originalData, editorData }) => {
	const blocksFromHtml = htmlToDraft(originalData);
	const { contentBlocks, entityMap } = blocksFromHtml;
	const contentDataState = ContentState.createFromBlockArray(contentBlocks, entityMap);

	// const contentDataState = ContentState.createFromBlockArray(convertFromHTML(originalData));
	const editorDataState = EditorState.createWithContent(contentDataState);
	const [editorState, setEditorState] = useState(editorDataState);

	const onEditorStateChange = (es) => {
		setEditorState(es);
		// html = html.replace('style="color: rgb(0,200,0);"', "");
		// html = html.toString().replace(/ style="color: rgb\(21,128,61\);"/g, "");
		// html = html.toString().replace(/ style="color: rgb\(185,28,28\);"/g, "");
		// const contentDataState = ContentState.createFromBlockArray(convertFromHTML(html));
		// const editorDataState = EditorState.createWithContent(contentDataState);
		// console.log(draftToHtml(convertToRaw(editorDataState.getCurrentContent())))
		// editorData(newhtml)
	};

	useEffect(() => {
		let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		html = html.toString().replace(/ style="color: rgb\(21,128,61\);"/g, '');
		html = html.toString().replace(/ style="color: rgb\(185,28,28\);"/g, '');
		editorData(html);
	}, [editorData, editorState]);

	// console.log(convertToRaw(editorState.getCurrentContent()));
	// console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	return (
		<div>
			<Editor
				editorState={editorState}
				toolbarClassName='editor-toolbar'
				wrapperClassName='editor-wrapper'
				editorClassName='editor'
				onEditorStateChange={onEditorStateChange}
				toolbar={{
					options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'remove', 'history'],
					inline: {
						options: ['bold', 'italic', 'underline']
					}
				}}
			/>
			{/* <textarea className="w-full"
                disabled
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
            ></textarea> */}
		</div>
	);
};

export default TextEditor;

TextEditor.propTypes = {
	originalData: PropTypes.string,
	editorData: PropTypes.string
};
