import PropTypes from 'prop-types';
import style from './Styles';

const TextError = (props) => {
	const { children } = props;
	return <div className={style.error}>{children}</div>;
};

export default TextError;

TextError.propTypes = {
	children: PropTypes.node
};
