import Intro from '../components/intro/Intro';
import Jumbotron from '../components/ui/Jumbotron';

const Home = () => (
	<>
		<Jumbotron className='w-full text-primary-2 bg-gradient-to-r from-tertiary-cool-3 via-tertiary-cool-3-5 to-tertiary-cool-4'>
			<p className='w-full max-w-fit font-bold text-primary-1 text-2xl trdacking-loose justsify-self-start'>
				Welcome to the Product Inspection Request application
			</p>
			<div className='relative flex flex-1 w-full h-full lg:w-full justify-center items-center px-6'>
				<div
					style={{ backgroundImage: 'url(/hero-image.png)' }}
					className='border-2 border-tertiary-cool-2-5 absolute opacfity-80 w-full h-96 top-0 p-12 mx-auto flex flex-col md:flex-row items-center mhy-12 md:mhy-12 bg-no-repeat bg-center bg-cover'
				></div>
				<div className='text-primary-1 w-full h-96 mdy-12 p-12 top-0 mx-auto flex flex-col md:flex-row items-center justify-center'></div>
				<div className='w-11/12'>
					<Intro />
				</div>
			</div>
		</Jumbotron>

		<p>
			Please contact
			<a href='mailto:pir-application-support_smb@jaguarlandrover.com' className='text-blue-500'>
				{' '}
				pir-application-support_smb@jaguarlandrover.com{' '}
			</a>
			for application support.
		</p>
	</>
);

export default Home;
