import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { mailingGroupActions } from './mailingGroupSlice';
import Toast from '../components/ui/Toast';

export const getMailingGroups = () => {
	return async (dispatch) => {
		try {
			const mailingGroups = await getRequest('mailinggroup');
			dispatch(mailingGroupActions.replaceMailingGroups(mailingGroups));
		} catch (error) {
			console.error('getMailingGroup: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(mailingGroupActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createMailingGroup = (mailingGroupData) => {
	return async (dispatch) => {
		try {
			const mailingGroup = await postRequest('mailinggroup/', mailingGroupData);
			Toast({ status: 'success', message: `Mailing Group: ${mailingGroup.Name} successfully added` });
			dispatch(mailingGroupActions.updateMailingGroup(mailingGroup));
		} catch (error) {
			console.error('createMailingGroup: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateMailingGroup = (mailingGroupData) => {
	return async (dispatch) => {
		try {
			const response = await putRequest(`mailinggroup/${mailingGroupData.id}/`, mailingGroupData);
			Toast({ status: 'success', message: 'Mailing Group Updated!' });
			dispatch(mailingGroupActions.updateMailingGroup(mailingGroupData));
		} catch (error) {
			console.error('updateMailingGroup: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteMailingGroup = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`mailinggroup/${id}/`);
			Toast({ status: 'success', message: `Mailing Group Deleted!` });
			dispatch(mailingGroupActions.removeMailingGroup(id));
		} catch (error) {
			console.error('deleteMailingGroup: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
