import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { vehiclePlatformActions } from './vehiclePlatformSlice';
import Toast from '../components/ui/Toast';

export const getVehiclePlatforms = () => {
	return async (dispatch) => {
		try {
			const vehiclePlatforms = await getRequest('vehicleplatforms');
			dispatch(vehiclePlatformActions.replaceVehiclePlatforms(vehiclePlatforms));
		} catch (error) {
			console.error('getVehiclePlatform: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(vehiclePlatformActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createVehiclePlatform = (vehiclePlatformData) => {
	return async (dispatch) => {
		try {
			const vehiclePlatform = await postRequest('vehicleplatforms/', vehiclePlatformData);
			Toast({ status: 'success', message: `Added to Vehicle Platform!` });
			dispatch(vehiclePlatformActions.updateVehiclePlatform(vehiclePlatform));
		} catch (error) {
			console.error('createVehiclePlatform: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateVehiclePlatform = (vehiclePlatformData) => {
	return async (dispatch) => {
		try {
			const vehiclePlatform = await putRequest(`vehicleplatforms/${vehiclePlatformData.id}/`, vehiclePlatformData);
			Toast({ status: 'success', message: `Updated Vehicle Platform!` });
			dispatch(vehiclePlatformActions.updateVehiclePlatform(vehiclePlatform));
		} catch (error) {
			console.error('updateMailinglist: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteVehiclePlatform = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`vehicleplatforms/${id}`);
			Toast({ status: 'success', message: `Vehicle Platform Deleted!` });
			dispatch(vehiclePlatformActions.removeVehiclePlatform(id));
		} catch (error) {
			console.error('deleteVehiclePlatform: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
