import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { rejectionActions } from './rejectionSlice';
import Toast from '../components/ui/Toast';

export const getRejections = () => {
	return async (dispatch) => {
		try {
			const rejections = await getRequest('rejectioncode');
			dispatch(rejectionActions.replaceRejections(rejections));
		} catch (error) {
			console.error('getRejection: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(rejectionActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createRejection = (rejectionData) => {
	return async (dispatch) => {
		try {
			const rejection = await postRequest('rejectioncode/', rejectionData);
			Toast({ status: 'success', message: `Rejection: ${rejection.Name} successfully added` });
			dispatch(rejectionActions.updateRejection(rejection));
		} catch (error) {
			console.error('createRejection: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateRejection = (rejectionData) => {
	return async (dispatch) => {
		try {
			const response = await putRequest(`rejectioncode/${rejectionData.id}/`, rejectionData);
			Toast({ status: 'success', message: 'Rejection Updated!' });
			dispatch(rejectionActions.updateRejection(rejectionData));
		} catch (error) {
			console.error('updateRejection: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteRejection = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`rejectioncode/${id}/`);
			Toast({ status: 'success', message: `Rejection Deleted!` });
			dispatch(rejectionActions.removeRejection(id));
		} catch (error) {
			console.error('deleteRejection: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
