import { configureStore } from '@reduxjs/toolkit';

import authSlice from './authSlice';
import contactsSlice from './contactsSlice';
import mailingGroupSlice from './mailingGroupSlice';
import dashboardSlice from './dashboardSlice';
import globalSlice from './globalSlice';
import settingsSlice from './settingsSlice';
import pirRequestSlice from './pirRequestSlice';
import pirInspectionSlice from './pirInspectionSlice';
import pirCommentsSlice from './pirCommentsSlice';
import sampleSlice from './sampleSlice';
import dropdownSlice from './dropdownSlice';
import pirIssueSlice from './pirIssueSlice';
import mailingListSlice from './mailingListSlice';
import usersSlice from './usersSlice';
import reasonSlice from './reasonSlice';
import statusSlice from './statusSlice';
import waitingSlice from './waitingSlice';
import rejectionSlice from './rejectionSlice';
import regionSlice from './regionSlice';
import brandSlice from './brandSlice';
import vehiclePlatformSlice from './vehiclePlatformSlice';
import locationSlice from './locationSlice';
import profileSlice from './profileSlice';

const store = configureStore({
	reducer: {
		auth: authSlice.reducer,
		contacts: contactsSlice.reducer,
		dashboard: dashboardSlice.reducer,
		dropdown: dropdownSlice.reducer,
		global: globalSlice.reducer,
		settings: settingsSlice.reducer,
		pirComments: pirCommentsSlice.reducer,
		pirRequest: pirRequestSlice.reducer,
		pirInspection: pirInspectionSlice.reducer,
		sample: sampleSlice.reducer,
		pirIssue: pirIssueSlice.reducer,
		mailingGroup: mailingGroupSlice.reducer,
		mailingList: mailingListSlice.reducer,
		users: usersSlice.reducer,
		reason: reasonSlice.reducer,
		status: statusSlice.reducer,
		waiting: waitingSlice.reducer,
		rejection: rejectionSlice.reducer,
		region: regionSlice.reducer,
		brand: brandSlice.reducer,
		vehiclePlatform: vehiclePlatformSlice.reducer,
		location: locationSlice.reducer,
		profile: profileSlice.reducer
	}
});

export default store;
