import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { reasonActions } from './reasonSlice';
import Toast from '../components/ui/Toast';

export const getReasons = () => {
	return async (dispatch) => {
		try {
			const reasons = await getRequest('reason');
			dispatch(reasonActions.replaceReasons(reasons));
		} catch (error) {
			console.error('getReason: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(reasonActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createReason = (reasonData) => {
	return async (dispatch) => {
		try {
			const reason = await postRequest('reason/', reasonData);
			Toast({ status: 'success', message: `Reason: ${reason.Name} successfully added` });
			dispatch(reasonActions.updateReason(reason));
		} catch (error) {
			console.error('createReason: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateReason = (reasonData) => {
	return async (dispatch) => {
		try {
			const response = await putRequest(`reason/${reasonData.id}/`, reasonData);
			Toast({ status: 'success', message: 'Reason Updated!' });
			dispatch(reasonActions.updateReason(reasonData));
		} catch (error) {
			console.error('updateReason: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteReason = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`reason/${id}/`);
			Toast({ status: 'success', message: `Reason Deleted!` });
			dispatch(reasonActions.removeReason(id));
		} catch (error) {
			console.error('deleteReason: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
