import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../packages/button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { sendGenericEmail } from '../../store/genericEmailActions';
import FormField from '../../packages/form/FormField';
import { MailIcon, PencilIcon, PlusIcon } from '@heroicons/react/solid';

import Tippy from '@tippyjs/react';
import RichTextEditor from '../../components/richTextEditor/RichTextEditor';
import { updateObject } from '../../utils/utility';
import SelectInput from '../../components/ui/SelectInput';
import useToggle from '../../packages/_utils/useToggle';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../packages/modal/Modal';
import Toast from '../../components/ui/Toast';

const emailDetailObject = {
	toList: [],
	ccList: [],
	subject: '',
	body: ''
};

const GenericEmail = () => {

	const profileData = useSelector((state) => state.profile.profilesData);
	if (!profileData.is_active) {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}
	
	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
	const dispatch = useDispatch();

	const [editorData, setEditorData] = useState();

	// mailing lists
	// const mailingLists = useSelector((state) => state.mailingList.data);
	let mailingLists = [];
	let apiMailingLists = [];
	let apiContacts = [];
	let emailToOptionsList = [];
	let emailCcOptionsList = [];
	let mailingGroupsOptionList = [];
	if (dropdownsData.Contacts.length > 0) {
		apiMailingLists = dropdownsData.MailingList;
		apiContacts = dropdownsData.Contacts;
		const contacts = dropdownsData.Contacts;
		contacts.forEach(element => {
			const pushData = { value: element.id, label: element.Email };
			emailToOptionsList.push(pushData);
			emailCcOptionsList.push(pushData);
		});
		const mailingGroups = dropdownsData.MailingGroup;
		mailingGroups.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			mailingLists.push(pushData);
			mailingGroupsOptionList.push(pushData);
		});
	}
	const [selectedMailingList, setSelectedMailingList] = useState('');

	// email details to send to api
	const [emailDetails, setEmailDetails] = useState(emailDetailObject);

	// email options
	const [emailToOptions, setEmailToOptions] = useState(emailToOptionsList);
	const [emailCcOptions, setEmailCcOptions] = useState(emailCcOptionsList);
	const [emailGroupOptions, setEmailGroupOptions] = useState([]);
	const [selectedEmailGroupOptions, setSelectedEmailGroupOptions] = useState();
	const [selectedEmailToOptions, setSelectedEmailToOptions] = useState([]);
	const [selectedEmailCcOptions, setSelectedEmailCcOptions] = useState([]);

	// toggle options
	const [emailConfirmationToggle, emailConfirmationIsOpen] = useToggle();
	const [groupListToggle, groupListIsOpen] = useToggle();
	const [toListToggle, toListIsOpen] = useToggle();
	const [ccListToggle, ccListIsOpen] = useToggle();

	// update email address select inputs
	const selectHandle = (e, type) => {
		const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
		if (e.length > 0) {
			const checkEmail = e[e.length - 1];
			if (!emailRegex.test(checkEmail)) {
				Toast({ status: "warning", message: 'Invalid Email' });
				return;
			}
		}
		if (type === 'group') {
			const newOption = e.filter((m) => !emailGroupOptions.map((o) => o.label).includes(m));
			if (newOption.length > 0) setEmailGroupOptions([...emailGroupOptions, { value: newOption[0], label: newOption[0] }]);
			setSelectedEmailGroupOptions(e);
		} else if (type === 'to') {
			const newOption = e.filter((m) => !emailToOptions.map((o) => o.label).includes(m));
			if (newOption.length > 0) setEmailToOptions([...emailToOptions, { value: newOption[0], label: newOption[0] }]);
			setSelectedEmailToOptions(e);
		} else {
			const newOption = e.filter((m) => !emailCcOptions.map((o) => o.label).includes(m));
			if (newOption.length > 0) setEmailCcOptions([...emailCcOptions, { value: newOption[0], label: newOption[0] }]);
			setSelectedEmailCcOptions(e);
		}
	};

	const emailHandler = () => {
		const sendEmailData = {
			Subject: emailDetails.subject,
			Body: editorData,
			ToEmail: selectedEmailToOptions,
			CcEmail: selectedEmailCcOptions,
			BccEmail: [],
		};

		dispatch(sendGenericEmail(sendEmailData));
		emailConfirmationToggle();
	};

	const onChangeHandler = (e) => {
		const upd = updateObject(emailDetails, { [e.currentTarget.name]: e.currentTarget.value });
		setEmailDetails(upd);
	};

	const onSelectChangeHandler = (value, field) => {
		let updatedMailingList = updateObject(selectedMailingList, {
			[field]: value
		});
		setSelectedMailingList(updatedMailingList);
		let emailList = [];
		if (value) {
			const mailingListsFilter = apiMailingLists.filter((x) => x.MailingGroupId === value);
			mailingListsFilter.forEach(element => {
				const pushData = apiContacts.find((x) => x.id === element.ContactId).Email;
				emailList.push(pushData);
			});
			setSelectedEmailToOptions(emailList);
		}
		else {
			setSelectedEmailToOptions([]);
		}
	};

	const handleToggle = (type) => {
		if (type === 'group') {
			groupListToggle();
		} else if (type === 'to') {
			if (ccListIsOpen) ccListToggle();
			if (groupListIsOpen) groupListToggle();
			toListToggle();
		} else {
			if (toListIsOpen) toListToggle();
			if (groupListIsOpen) groupListToggle();
			ccListToggle();
		}
	};

	const confirmClick = () => {
		emailHandler();
		emailConfirmationToggle();
	};

	const confirmationMessage = () => {
		const content = (
			<div className='d-flex flex-row bd-highlight mb-3'>
				<div className='p-2'>
					<p>This will email the recipients.</p>
					<p>Please confirm?</p>
				</div>
			</div>
		);
		return content;
	};

	return (
		<>
			<h2 className='mb-4 text-primary-1 text-lg font-bold md:text-2xl'>Generic Email</h2>
			<div className='p-3 bg-tertiary-cool-4 text-primary-1 m-2 rounded max-w-5xl'>
				<div className='flex flex-col md:flex-row items-center bodrder-b border-secondary-2 mb-2'>
					<div className='pr-4 h5 w-1/5'>Mailing Groups</div>
					<div className='flex-1 flex flex-col md:flex-row'>
						<div className='w-full flex mx-2 py-2 items-center font-semibold'>
							<SelectInput
								className='border rounded w-60 mx-4'
								name={'mailingGroups'}
								value={selectedMailingList.mailingGroupId}
								label={'Groups'}
								options={mailingGroupsOptionList}
								onChangeHandler={(e) => onSelectChangeHandler(e, 'mailingGroupId')}
							/>
						</div>
					</div>
				</div>
				<div className='flex flex-col md:flex-row items-center bodrder-b border-secondary-2 mb-2'>
					<div className='pr-4 h5 w-1/5'>To/Cc Addresses </div>
					<div className='flex-1 flex flex-col md:flex-row'>
						<div className='w-full flex mx-2 py-2 items-center font-semibold'>
							<p>
								To: <span className='pr-2'>{`${selectedEmailToOptions?.length ?? 0} Email Addresses`}</span>
							</p>
							<Tippy
								arrow={true}
								content={<span className='bg-secondary-2 text-sm rounded px-2 py-1 shadow text-primary-2'>View/Edit To: emails</span>}
							>
								<Button
									type='button'
									onClick={() => handleToggle('to')}
									className={`!p-1 my-1 cursor-pointer rounded-sm text-primary-2 hover:text-primary-1 ${
										toListIsOpen ? '!bg-tertiary-cool-2' : 'bg-tertiary-cool-1'
									} hover:bg-tertiary-cool-2`}
								>
									<PencilIcon className='h-6 ' />
								</Button>
							</Tippy>
						</div>
					</div>
					<div className='flex-1 flex flex-col md:flex-row'>
						<div className='w-full flex mx-2 py-2 items-center font-semibold'>
							<p>
								Cc: <span className='pr-2'>{`${selectedEmailCcOptions?.length ?? 0} Email Addresses`}</span>
							</p>
							<Tippy
								arrow={true}
								content={<span className={`bg-secondary-2 text-sm rounded px-2 py-1 shadow text-primary-2`}>View/Edit Cc: emails</span>}
							>
								<Button
									type='button'
									onClick={() => handleToggle('cc')}
									className={`!p-1 my-1 cursor-pointer rounded-sm text-primary-2 hover:text-primary-1 ${
										ccListIsOpen ? '!bg-tertiary-cool-2' : 'bg-tertiary-cool-1'
									} hover:bg-tertiary-cool-2`}
								>
									<PencilIcon className='h-6 ' />
								</Button>
							</Tippy>
						</div>
					</div>
				</div>

				{toListIsOpen && (
					<div className='bordser-b border-secondary-2 pb-4 mb-2'>
						<SelectInput
							name={'ToList'}
							className={'text-sm'}
							isClearable={true}
							isCreatable={true}
							byLabel={true}
							isMulti={true}
							label={'To Email Addresses'}
							options={emailToOptions}
							value={selectedEmailToOptions}
							onChangeHandler={(e) => selectHandle(e, 'to')}
						/>
					</div>
				)}
				{ccListIsOpen && (
					<div className='borsder-b border-secondary-2 pb-4 mb-2'>
						<SelectInput
							name={'CcList'}
							className={'text-sm'}
							isClearable={true}
							isCreatable={true}
							byLabel={true}
							isMulti={true}
							label={'Cc Email Addresses'}
							options={emailCcOptions}
							value={selectedEmailCcOptions}
							onChangeHandler={(e) => selectHandle(e, 'cc')}
						/>
					</div>
				)}
				<div className='flex flex-col md:flex-row border-y border-secondary-2 pt-2 pb-4 mb-2'>
					<div className='w-32 font-bold h-6 mx-2 mt-3 text-primary-1'>Subject:</div>
					<div className='flex-1 flex flex-col md:flex-row'>
						<div className='w-full flex-1 mx-2 py-2'>
							<FormField
								autoComplete='off'
								name='subject'
								placeholder='Subject'
								value={emailDetails.subject}
								onChange={(e) => onChangeHandler(e)}
							/>
						</div>
					</div>
				</div>
				<div className='flex flex-col md:flex-row border-b border-secondary-2 pb-4 mb-2'>
					<div className='flex-1 flex flex-col md:flex-row'>
						<div className='w-full flex-1 mx-2 py-2 dark:text-primary-2'>
							<RichTextEditor originalData={emailDetails.body} editorData={(e) => setEditorData(e)} />
						</div>
					</div>
				</div>
				<div className='flex flex-col md:flex-row mb-2'>
					<div className='flex-1 flex flex-col md:flex-row items-center'>
						{!profileData.view ? (
							<div className='w-fuldl fdlex-1 mx-2 py-2'>
								<Button 
									onClick={emailConfirmationToggle} 
									className='flex items-center'
									disabled={selectedEmailToOptions.length===0 && selectedEmailCcOptions.length===0}
								>
									<MailIcon className='h-6 w-6 mr-4 ' />
									Send Email
								</Button>
							</div>
							) : (
							<p className="text-sm text-red-500 mt-3">
							You don't have the required access to Send Email
							</p>
						)}
					</div>
				</div>
			</div>

			{emailConfirmationIsOpen && (
				<Modal isOpen={emailConfirmationIsOpen} toggle={emailConfirmationToggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Send Email Confirmation</ModalHeader>
					<ModalBody>{confirmationMessage()}</ModalBody>
					<ModalFooter>
						<Button onClick={emailConfirmationToggle} color='danger' className='ignore-click-outside'>
							Cancel
						</Button>
						<Button onClick={confirmClick} color='primary' className='mr-1 ignore-click-outside'>
							Send
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

export default GenericEmail;
