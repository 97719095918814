import { postRequest } from '../axios';
import { authActions } from './authSlice';
import Toast from '../components/ui/Toast';

const sampleUsers = [
	{
		surname: '1',
		givenName: 'User',
		userName: 'user1',
		email: 'usera@jlr.com',
		role: 'user',
		token: 'xxx'
	},
	{
		surname: 'Trasler',
		givenName: 'Kevin',
		userName: 'ktrasler',
		email: 'ktrasler@jaguarlandrover.com',
		role: 'admin',
		token: 'xxx'
	},
	{
		surname: 'User',
		givenName: 'Sample',
		userName: 'ssuser',
		email: 'ssuser@jaguarlandrover.com',
		role: 'admin',
		token: 'xxx'
	},
];

export const setLoading = (state) => {
	return (dispatch) => {
		dispatch(authActions.setLoading(state));
	};
};
export const login = (userData) => {
	return async (dispatch) => {
		try {
			const user = await postRequest('login', userData);
			// const user = { user: sampleUsers[0] };
			console.log(user);
			dispatch(authActions.replaceUser(user));
		} catch (error) {
			console.error('login: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const logout = () => {
	return async (dispatch) => {
		try {
			// const response = await postRequest('users/logout', {});
			Toast({ status: 'info', message: 'user logged out' });
			dispatch(authActions.replaceUser({}));
		} catch (error) {
			dispatch(authActions.replaceUser({}));
			console.error('logout: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const getUsers = () => {
	return async (dispatch) => {
		try {
			const users = sampleUsers; //await getRequest('users/getusers');
			dispatch(
				authActions.replaceUsers({
					users: users || []
				})
			);
		} catch (error) {
			console.error('getUsers: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const getFilteredUsers = (queryString) => {
	return async (dispatch) => {
		try {
			// dispatch(authActions.replaceFilteredUser([]));
			const filteredUsers = sampleUsers; //await getRequest(`users/filtered/${queryString}`);
			dispatch(authActions.replaceFilteredUser(filteredUsers));
		} catch (error) {
			dispatch(authActions.setNetworkError());
			console.error('getUser: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(authActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const getUser = () => {
	return async (dispatch) => {
		try {
			const user = sampleUsers[2]; //await getRequest('users');
			dispatch(authActions.replaceUser({ user: user }));
		} catch (error) {
			dispatch(authActions.setNetworkError());
			console.error('getUser: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(authActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createUser = (userData) => {
	return async (dispatch) => {
		try {
			const user = sampleUsers[2]; //await postRequest('users/create', userData);
			Toast({ status: 'success', message: `User: ${user.userName} successfully registered` });
			dispatch(authActions.updateUser(user));
		} catch (error) {
			console.error('createUser: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateUser = (userData) => {
	return async (dispatch) => {
		try {
			// const response = await putRequest('users', userData);
			userData = sampleUsers[2];
			Toast({ status: 'success', message: 'Updated User' });
			dispatch(authActions.updateUser(userData));
		} catch (error) {
			console.error('updateUser: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteUser = (id) => {
	return async (dispatch) => {
		try {
			// await deleteRequest(`users/delete/${id}`);
			Toast({ status: 'success', message: `User deleted` });
			dispatch(authActions.removeUser({ id }));
		} catch (error) {
			console.error('deleteUser: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
