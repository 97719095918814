import { createSlice } from '@reduxjs/toolkit';
// import { sortAz } from '../utils/utility';

const initialValues = {
	data: [],
	loading: null
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState: initialValues,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		replacesettings: (state, action) => {
			console.log(action.payload);
			state.data = action.payload;
		},
		removeMarket: (state, action) => {
			const id = action.payload;
			state.changed = true;
			state.data = state.data.filter((d) => d.id !== id);
		},
		updateMarket: (state, action) => {
			const data = action.payload;
			const updatedData = [...state.data.filter((d) => d.id !== data.id), data];
			state.data = updatedData; //.sort(sortAz);
		}
	}
});

export const settingsActions = settingsSlice.actions;

export default settingsSlice;
