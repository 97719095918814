import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AdjustmentsIcon, BellIcon, ChatAltIcon, MailIcon, MenuIcon, UserCircleIcon, XIcon } from '@heroicons/react/solid';
import { LogoutIcon } from '@heroicons/react/outline';

import useClickOutside from '../../packages/_utils/useClickOutside';
import { logout } from '../../store/authActions';

const Navbar = (props) => {
	const { sideNavOpen, setSideNavOpen } = props;
	const profileData = useSelector((state) => state.profile.profilesData);
	const [profile, setProfile] = useState(false);

	const [notificationIcon] = useState(false);
	const [notifications] = useState(0);
	const [messagesIcon] = useState(false);
	const [messages] = useState(0);
	// const [userAvatar, setUserAvatar] = useState("https://i.pravatar.cc/100");
	const [userAvatar] = useState();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const logoutUrl = process.env.REACT_APP_SERVER_URL + '/saml2/logout/';

	let domNode = useClickOutside(() => {
		setProfile(false);
	});

	const adfsName = profileData.username ? profileData.first_name + ' ' + profileData.last_name : 'Login';

	const style = {
		navBar: {
			button: 'focus:outline-none flex h-20 w-full items-center justify-start hover:text-primary-2 focus:bg-secondary-2 focus:text-primary-2 hover:bg-primary-1 text-primary-1 transition-colors duration-300 ease-in-out relative',
			icon: `fill-stroke flex flex-none h-full w-20 items-center p-4`,
			text: 'flex w-full h-full text-base items-center p-2'
		}
	};

	return (
		<nav className='h-20 flex items-stretch justify-between bg-primary-2 shadow-md fixed w-full z-30'>
			<div className='flex w-full pr-2'>
				<div className='flex h-full flex-none w-52'>
					
					<button
						onClick={() => setSideNavOpen(!sideNavOpen)}
						className={`ignore-click-outside group flex flex-none items-center text-primary-1 justify-cenfter p-4 w-20 focus:outline-none focus:shadow-outline ease-in-out duration-300 hover:bg-primary-1 hover:text-primary-2 ${
							sideNavOpen && 'bg-secondary-on'
						}`}
					>
						{sideNavOpen ? <XIcon /> : <MenuIcon />}
					</button>
					<Link to='/' className='flex flex-initial justify-center w-full items-center space-x-3'>
						<img src='/JLR_Logo.png' width="75" style={{ height: '50px', important: true }} alt='' />
					</Link>
				</div>
				<div className='w-full hidden lg:flex'>
					<div className='w-full flex items-center pl-8 justify-end'>
						{notificationIcon && (
							<div className='h-full w-20 flex items-center justify-center border-l'>
								<button className={style.navBar.button}>
									<div className={style.navBar.icon}>
										<BellIcon />
									</div>
									{/* Notification Count */}
									{notifications > 0 && (
										<div className='w-6 h-6 rounded-full text-primary-2 text-sm font-bold flex justify-center items-center bg-danger border !border-primary-2 absolute top-3 right-3'>
											{notifications}
										</div>
									)}
								</button>
							</div>
						)}
						{messagesIcon && (
							<div className='h-full w-20 flex items-center justify-center border-l cursor-pointer hdidden'>
								<button className={style.navBar.button}>
									<div className={style.navBar.icon}>
										<ChatAltIcon />
									</div>
									{/* Messages Count */}
									{messages > 0 && (
										<div className='w-6 h-6 rounded-full text-primary-2 text-sm font-bold flex justify-center items-center bg-primary border !border-primary-2 absolute top-3 right-3'>
											{messages}
										</div>
									)}
								</button>
							</div>
						)}

						<div
							ref={domNode}
							className='flex items-center relative cursor-pointer h-full border-l border-primary-2'
							onClick={() => {
								if (!profileData.username) window.location.replace(process.env.REACT_APP_SERVER_URL + '/saml2/login/');
								else setProfile(!profile);
							}}
						>
							<div className={`transition-opacity duration-300 ease-in-out ${profile ? 'opacity-100' : 'opacity-0'}`}>
								<ul className={`p-2 w-full border-r bg-primary-2 absolute left-0 shadow-md mt-12 ${!profile && 'pointer-events-none'}`}>
									<li className='flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out text-primary-1 hover:bg-primary-1 hover:text-primary-2 cursor-pointer items-center'>
										<Link className='flex items-center hover:text-primary-2' to='/genericemail'>
											<MailIcon className='h-8 w-8' />
											<span className='text-sm ml-2 w-full'>Email</span>
										</Link>
									</li>
									<li className='flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out text-primary-1 hover:bg-primary-1 hover:text-primary-2 cursor-pointer items-center'>
										<Link className='flex items-center hover:text-primary-2' to='/settings'>
											<AdjustmentsIcon className='h-8 w-8' />
											<span className='text-sm ml-2 w-full'>Settings</span>
										</Link>
									</li>
									<li className='flex h-14 w-full px-2 justify-between transition-colors duration-300 ease-in-out text-primary-1 hover:bg-primary-1 hover:text-primary-2 cursor-pointer items-center'>
										<Link className='flex items-center hover:text-primary-2' to={logoutUrl}>
											<LogoutIcon className='h-8 w-8' />
											<span className='text-sm ml-2 w-full'>Logout</span>
										</Link>
									</li>
								</ul>
							</div>
							<div className='flex items-center h-full text-primary-1 p-2 hover:bg-primary-1 hover:text-primary-2 transition-colors duration-300 ease-in-out'>
								<div className='grid'>
									{userAvatar ? (
										<img className='flex rounded-full h-10 w-10 object-cover border-2 border-primary-2' src={userAvatar} alt='avatar' />
									) : (
										<UserCircleIcon className='w-10 h-10 right-3 text-secondary-2' />
									)}
									{/* Online notification */}
									{/* <div className="w-2.5 h-2.5 flex !-mb-3 self-end place-self-center	rounded-full bg-success border-2 border-primary-2 absolute" /> */}
								</div>
								<p className='text-inherit text-sm mx-3'>{adfsName}</p>
								{/* <div className="cursor-pointer text-inherit w-6">
                                    {profile ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                  </div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	);
};

Navbar.propTypes = {
	sideNavOpen: PropTypes.bool,
	setSideNavOpen: PropTypes.func
};

export default Navbar;
