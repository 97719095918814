import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/ui/Formik/FormikControl';
import Button from '../../../packages/button/Button';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '../../../packages/modal/Modal';
import useToggle from '../../../packages/_utils/useToggle';
import { createPirRequest, getPirDetailsById, uploadImage } from '../../../store/pirRequestActions';
import Toast from '../../../components/ui/Toast';
import { getRequest, postRequest } from '../../../axios';
import { Navigate, useNavigate } from 'react-router-dom';
import SearchHeader from '../../../components/ui/SearchHeader';
import { getImageUrls, getInspectionDetailsByPirId } from '../../../store/pirInspectionActions';

const CreatePIR = (props) => {

	const profileData = useSelector((state) => state.profile.profilesData);
	if (profileData.role === 'View') {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}
	if (!profileData.is_active) {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}

	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
	const [toggle, isOpen] = useToggle();
	const [fileToggle, isFileOpen] = useToggle();
	const allData = useSelector((state) => state.pirRequest.allData);
	const [submitData, setSubmitData] = useState({});
	const [files, setFiles] = useState([]);
	const [submittedPir, setSubmittedPir] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [showSearch, setShowSearch] = useState(true);
	const [autoHide, setAutoHide] = useState(showSearch);
	const [toggleExistingPir, isExistingPirOpen] = useToggle();
	const navigate = useNavigate();

	let locationOptions = [];
	let platformOptions = [];
	let reasonOptions = [];
	let userOptions = [];
	if (dropdownsData.Locations.length > 0) {
		const locations = dropdownsData.Locations;
		locations.forEach(element => {
			const pushData = { value: element.id, label: element.Name + ' (' + element.Code +')'};
			locationOptions.push(pushData);
		});
		const platforms = dropdownsData.Platforms;
		platforms.forEach(element => {
			const pushData = { value: element.id, label: element.Name + ' (' + element.Description + ')'};
			platformOptions.push(pushData);
		});
		const reasons = dropdownsData.Reasons;
		reasons.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			reasonOptions.push(pushData);
		});
		const users = dropdownsData.Users;
		users.forEach(element => {
			const pushData = { value: element.id, label: element.first_name + ' ' + element.last_name };
			userOptions.push(pushData);
		});
	}

	const data = {};
	
	const dispatch = useDispatch();

	const handleSubmit = async (values) => {

		// Null values handling (Mandatory Fields)

		if (values.Product === null || values.Product === undefined) {
			Toast({status: "warning", message: 'Product is a mandatory field'});
			return;
		}

		if (values.Description === null || values.Description === undefined) {
			Toast({status: "warning", message: 'Part Description is a mandatory field'});
			return;
		}

		if (values.LocationId === null || values.LocationId === undefined) {
			Toast({status: "warning", message: 'Location is a mandatory field'});
			return;
		}

		if (values.DateCode === null || values.DateCode === undefined) {
			Toast({status: "warning", message: 'Date Code is a mandatory field'});
			return;
		}

		if (values.DateCode) {
			if (values.DateCode.length !== 3 || !/^[a-zA-Z0-9]{3}$/.test(values.DateCode)) {
				Toast({status: "warning", message: 'Only 3 alphanumeric characters are allowed in DateCode'});
				return;
			}
		}

		if (values.ReasonId === null || values.ReasonId === undefined) {
			Toast({status: "warning", message: 'Reason for Request is a mandatory field'});
			return;
		}

		if (values.AdditionalDetails === null || values.AdditionalDetails === undefined) {
			Toast({status: "warning", message: 'Issue Description is a mandatory field'});
			return;
		}

		if (values.AdditionalContactInfo === null || values.AdditionalContactInfo === undefined) {
			Toast({status: "warning", message: 'Additional Contact Info is a mandatory field'});
			return;
		}

		// Pattern Validations

		const commaHyphenRegex = /^[a-zA-Z0-9,-]+$/;

		if (!commaHyphenRegex.test(values.Product)) {
			Toast({status: "warning", message: 'Invalid Characters in Product'});
			return;
		}

		if (!commaHyphenRegex.test(values.Vin)) {
			Toast({status: "warning", message: 'Invalid Characters in VIN'});
			return;
		}

		if (!commaHyphenRegex.test(values.WERSNumber)) {
			Toast({status: "warning", message: 'Invalid Characters in WERS Number'});
			return;
		}

		const angularBracketsRegex = /^[^<>|=]*$/;

		if (!angularBracketsRegex.test(values.Description)) {
			Toast({status: "warning", message: 'Invalid Characters in Description'});
			return;
		}

		if (!angularBracketsRegex.test(values.AdditionalDetails)) {
			Toast({status: "warning", message: 'Invalid Characters in Issue Description'});
			return;
		}

		if (!angularBracketsRegex.test(values.AdditionalContactInfo)) {
			Toast({status: "warning", message: 'Invalid Characters in Additional Contact Info'});
			return;
		}

		if(files.length > 0) {
			let totalSize = 0;
			for (let index = 0; index < files.length; index++) {
				const file = files[index];
				totalSize = totalSize + file.size;
			}
			if (totalSize > 23000000) {
				Toast({status: "warning", message: 'File size exceeded 25MB'});
				return;
			}
		}

		// Get form values
		let submitValues = {};
		let platformSubmitValues = [];
		let locationSubmitValues = [];

		if (values.PlatformId) platformSubmitValues.push({ "VehiclePlatformId": values.PlatformId });
		if (values.LocationId) {
			values.LocationId.forEach(element => {
				locationSubmitValues.push({ "PirLocationId": element });
			});
		}

		submitValues = {
			Product: values.Product,
			Description: values.Description,
			Vin: values.Vin,
			DateCode: values.DateCode,
			Comments: [],
			Urls: [],
			Locations: locationSubmitValues,
			VehiclePlatforms: platformSubmitValues,
			ReasonId: values.ReasonId,
			RequesterId: values.RequestedById,
			AdditionalContactInfo: values.AdditionalContactInfo,
			AdditionalDetails: values.AdditionalDetails,
			WERSNumber: values.WERSNumber
		};

		setSubmitData(submitValues);

		// Handling PIRs with existing product
		const response = await getRequest('pirrequest/?Product=' + values.Product + '&DateCode=' + values.DateCode);
		if (response) {
			if (response.count > 0) {
				toggle();
			}
			else {
				setSubmittedPir(true);
				const response = await postRequest('pirrequest/', submitValues);
				if (response) {
					Toast({status: "success", message: 'PIR ' + response.id + ' Created Successfully!'});
					if(files.length > 0) {
						const headers = new Headers();
						headers.append('X-CSRFToken', profileData.uid);
						for (let index = 0; index < files.length; index++) {
							const file = files[index];
							const fileObject = new FormData();
							fileObject.append('Url', file);
							fileObject.append('Name', file.name);
							fileObject.append('RequestHeaderId', response.id);
							const fileResponse = await fetch(process.env.REACT_APP_SERVER_URL + '/api/url/', {
								method: 'POST',
								body: fileObject,
								credentials: 'include', // include cookies
								headers: headers
							});
							if (fileResponse) {
								const number = index + 1;
								Toast({status: "success", message: 'File ' + number + ' Uploaded Successfully!'});
							}
						}
					}
				}
			}
		}
	};

	const handleCreate = async () => {
		setSubmittedPir(true);
		const response = await postRequest('pirrequest/', submitData);
		if (response) {
			Toast({status: "success", message: 'PIR ' + response.id + ' Created Successfully!'});
			if(files.length > 0) {
				const headers = new Headers();
				headers.append('X-CSRFToken', profileData.uid);
				for (let index = 0; index < files.length; index++) {
					const file = files[index];
					const fileObject = new FormData();
					fileObject.append('Url', file);
					fileObject.append('Name', file.name);
					fileObject.append('RequestHeaderId', response.id);
					const fileResponse = await fetch(process.env.REACT_APP_SERVER_URL + '/api/url/', {
						method: 'POST',
						body: fileObject,
						credentials: 'include', // include cookies
						headers: headers
					});
					if (fileResponse) {
						const number = index + 1;
						Toast({status: "success", message: 'File ' + number + ' Uploaded Successfully!'});
					}
				}
			}
		}
		toggle();
	};

	const handleFileChange = async (event) => {
		const selectedFiles = Array.from(event.target.files);
		let notImageFlag = false;
		selectedFiles.forEach(element => {
			const splitArray = element.name.split(".");
			const extension = splitArray[splitArray.length - 1].toLowerCase();
			if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
				notImageFlag = true;
				return;
			}
		});
		if (notImageFlag) {
			Toast({status: "warning", message: 'Only image files are allowed! Please select again!'});
			return;
		}
		setFiles(selectedFiles);
	};

	const checkNonExistingPir = (pirNo) => {
		const existingNo = allData.find(x => x.id === Number(pirNo));
		if (existingNo === undefined) return true;
		else return false;
	};

	const searchClickHandler = (e) => {
		if (!e || e === '') {
			return;
		}
		if (checkNonExistingPir(e)) {
			toggleExistingPir();
			return;
		}
		dispatch(getPirDetailsById(e));
		dispatch(getImageUrls(e));
		dispatch(getInspectionDetailsByPirId(e));
		navigate(`/pir/view/${e}`);
	};
	
	const searchChangeHandler = (e) => {
		setSearchValue(e);
	};

	return (
		<>
			<SearchHeader
				label="PIR Number"
				onClick={searchClickHandler}
				onChange={searchChangeHandler}
				value={searchValue}
				isSearch={true}
				showSearch={showSearch}
				setShowSearch={e => { setShowSearch(e); setAutoHide(!autoHide); resetData();}}
			/>
			<span style={{fontSize: '25px', fontWeight: 'bold'}}>Create a new Product Inspection Request</span>
			<br /> <br /> <hr />
			<Formik 
			initialValues={data} 
			onSubmit={handleSubmit} 
			encType="multipart/form-data"
			// enableReinitialize 
			id="createNewPIR">
				{({ submitForm, errors, values, setFieldTouched }) => {
					return (
						<>
							<Form>
								<div className='px-4 pt-2 bg-tertiary-cool-4 sm:pt-4 sm:pXb-4 '>
									<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='input'
											type='text'
											label='Product'
											name='Product'
											required={true}
										/>
										<FormikControl
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control='input'
											type='text'
											label='Part Description'
											name='Description'
											required={true}
										/>
										<FormikControl
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control="select"
											label="Location"
											name="LocationId"
											placeholder="Select a Location"
											isMulti={true}
											options={locationOptions}
											required={true}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Date Code'
											name='DateCode'
											required={true}
										/>
										<FormikControl
											className='pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit'
											control='input'
											type='text'
											label='VIN'
											name='Vin'
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control="select"
											label="Vehicle Model"
											name="PlatformId"
											placeholder="Select a Model"
											isMulti={false}
											options={platformOptions}
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control="select"
											label="Reason for Request"
											name="ReasonId"
											placeholder="Select a Reason"
											isMulti={false}
											options={reasonOptions}
											required={true}
										/>
										<FormikControl
											className='pb-4 !col-span-8 md:!col-span-8 lg:!col-span-8 xl:!col-span-12 2xl:!col-span-12'
											control='input'
											type='text'
											label='Issue Description'
											name='AdditionalDetails'
											placeholder= 'Please ensure that you include all relevant details such as measurements, colour code etc.'
											required={true}
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control="select"
											label="Requested By"
											name="RequestedById"
											placeholder="Select Name"
											isMulti={false}
											options={userOptions}
										/>
										<FormikControl
											control='input'
											type='text'
											label='WERS Number'
											name='WERSNumber'
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control='input'
											type='text'
											label='Additional Contact Info'
											name='AdditionalContactInfo'
											placeholder='Contact Name and Phone Number'
											required={true}
										/>
										<Button
											type='button'
											disabled={false}
											onClick={fileToggle}
											// color='danger'
											className={`bg-tertiary-cool-2 lg:!col-span-2 place-self-center flex items-center`}
										>
											Attach Images
										</Button>
									</div>
								</div>
							</Form>
							<hr />
							<div className='grid grid-cols-3 gap-4px-4 py-3 bg-white text-left sm:px-6 rounded-b'>
								<Button
									onClick={() => {submitForm();}}
									color="success"
									className={`place-self-end col-start-3`}
									disabled={submittedPir}
								>
									Submit
								</Button>
							</div>
						</>
					);
				}}
			</Formik>
			{isOpen && (
				<Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Existing PIR</ModalHeader>
					<ModalBody>
						<div className='d-flex flex-row bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								A PIR for this <b>Product</b> & <b>Date Code</b> already exists. Are you sure you want to create another one?
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggle} color='danger'>
							Cancel
						</Button>
						<Button onClick={handleCreate} color='primary' className='mr-1'>
							Create
						</Button>
					</ModalFooter>
				</Modal>
			)}
			{isFileOpen && (
				<Modal isOpen={isFileOpen} toggle={fileToggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Attach Images</ModalHeader>
					<ModalBody>
						<div className='d-flex flex-row bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
							
								<span
								style={{ color: '#525252', fontSize: '16px', fontWeight: 'bold'}}
								className={`lg:!col-span-1 place-self-center items-center`}>Attach Images - </span>
								<input accept="image/*" type="file" multiple className={`lg:!col-span-3 place-self-center items-center`}
								onChange={handleFileChange} />
								<p className="text-sm text-red-500 mt-3">
									Allowed Extensions - .jpg, .jpeg, .png
								</p>
								<br/>
								<div>
									{files.length > 0 && (
										<div>
											<span style={{ color: '#525252', fontSize: '15px', fontWeight: 'bold'}}>
												Selected Images:
											</span>
											<ul>
											{files.map((file, index) => (
												<li key={index}>{file.name}</li>
											))}
											</ul>
										</div>
									)}
								</div>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={fileToggle} color='danger'>
							Cancel
						</Button>
						<Button onClick={fileToggle} color='primary' className='mr-1'>
							Okay
						</Button>
					</ModalFooter>
				</Modal>
			)}
			{isExistingPirOpen && (
				<Modal isOpen={isExistingPirOpen} toggle={toggleExistingPir} animate={true} closeOnClickOutside={true}>
					<ModalHeader>Incorrect PIR Number</ModalHeader>
					<ModalBody>
						<div className='d-flex flex-row bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								<p>The PIR Number you searched for does not exist</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggleExistingPir} color='danger' className='ignore-click-outside'>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

export default CreatePIR;

CreatePIR.propTypes = {
	closeDrawer: PropTypes.func
};
