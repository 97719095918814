import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { mailingListActions } from './mailingListSlice';
import Toast from '../components/ui/Toast';

export const getMailingLists = () => {
	return async (dispatch) => {
		try {
			const mailingLists = await getRequest('mailinglist');
			dispatch(mailingListActions.replaceMailingLists(mailingLists));
		} catch (error) {
			console.error('getMailingList: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(mailingListActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createMailingList = (mailingListData) => {
	return async (dispatch) => {
		try {
			const mailingList = await postRequest('mailinglist/', mailingListData);
			Toast({ status: 'success', message: `Added to Mailing List!` });
			dispatch(mailingListActions.updateMailingList(mailingList));
		} catch (error) {
			console.error('createMailingList: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateMailingList = (mailingListData) => {
	return async (dispatch) => {
		try {
			const mailingList = await putRequest(`mailinglist/${mailingListData.id}/`, mailingListData);
			Toast({ status: 'success', message: `Updated Mailing List!` });
			dispatch(mailingListActions.updateMailingList(mailingList));
		} catch (error) {
			console.error('updateMailinglist: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteMailingList = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`mailinglist/${id}`);
			Toast({ status: 'success', message: `Mailing List Deleted!` });
			dispatch(mailingListActions.removeMailingList(id));
		} catch (error) {
			console.error('deleteMailingList: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
