import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/ui/Formik/FormikControl';
import * as Yup from 'yup';
import SearchInput from '../../../components/ui/SearchInput';
import Button from '../../../packages/button/Button';
import Comments from '../../../components/comments/Comments';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '../../../packages/modal/Modal';
import useToggle from '../../../packages/_utils/useToggle';
import { getAllPirRequestData, resetAllPirRequestData, resetUploaded, deletePirRequest, resetPirRequestData } from '../../../store/pirRequestActions';
import Toast from '../../../components/ui/Toast';
// import { resetComments } from '../../../store/pirCommentsActions';
import { ArrowRightIcon } from '@heroicons/react/solid';

import { Role } from '../../../data/roles';
import { setComments } from '../../../store/pirCommentsActions';
import { tableStyles } from '../../settings/Settings';
import { updateLocationId } from '../../../store/pirInspectionActions';
import { StatusPill } from '../../../components/ui/Table';

const validationSchema = Yup.object().shape({
	Description: Yup.string().nullable().required('Required')
});

const MainDetails = (props) => {
	const { data, next, statusOptions, ownerOptions, isMultipleLocation, closeDrawer, isNew } = props;
	const profileData = useSelector((state) => state.profile.profilesData);
	const dispatch = useDispatch();
	const inspectionData = useSelector((state) => state.pirInspection.parstInspectionData);
	const imageUrls = useSelector((state) => state.pirInspection.urlsData);
	const refreshComments = useSelector((state) => state.pirComments.refreshCommentsData);
	let isClosedDate = false;
	var tempUrls = JSON.parse(JSON.stringify(imageUrls));
	if (tempUrls.length > 0) {
		for (let index = 0; index < tempUrls.length; index++) {
			const element = tempUrls[index];
			element.index = index;
		}
	}
	const noOfImages = useSelector((state) => state.pirInspection.urlsData.length);
	const [currentUrl, setCurrentUrl] = useState(tempUrls[0]);
	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);

	if (dropdownsData.Locations.length > 0 && data.id) {

		var tempData = JSON.parse(JSON.stringify(data));

		tempData.KpiLabel = '0.00';

		let LocationLabel = '';
		let ReasonLabel = '';
		let RequesterLabel = '';
		let AuditUserLabel = '';
		let PlatformLabel = '';
		let StatusLabel;

		if (data.LocationId) LocationLabel = dropdownsData.Locations.find(x => x.id === data.LocationId).Name;
		if (data.ReasonId) ReasonLabel = dropdownsData.Reasons.find(x => x.id === data.ReasonId).Name;
		if (data.RequesterId) RequesterLabel = dropdownsData.Users.find(x => x.id === data.RequesterId).first_name + ' ' + dropdownsData.Users.find(x => x.id === data.RequesterId).last_name;
		if (data.AuditUserId) AuditUserLabel = dropdownsData.Users.find(x => x.id === data.AuditUserId).first_name + ' ' + dropdownsData.Users.find(x => x.id === data.AuditUserId).last_name;
		if (data.Locations.length > 0) {
			data.Locations.forEach(element => {
				const pushData = dropdownsData.Locations.find(x => x.id === element.PirLocationId).Name;
				if (LocationLabel === '') LocationLabel =  pushData;
				else LocationLabel = LocationLabel + ', ' + pushData;
			});
		}

		//Status in Choose Location Popup

		if (data.Locations.length > 0) {
			tempData.Locations.forEach(temp => {
				temp.Status = 'New';
			});
		}
		if (data.RequestHeaderId.length > 0) {
			for (let index = 0; index < data.RequestHeaderId.length; index++) {
				const element = data.RequestHeaderId[index];
				if (element.StatusId !== null) {
					tempData.Locations.forEach(temp => {
						if(temp.PirLocationId === element.LocationId) {
							temp.Status = dropdownsData.Status.find(x => x.id === element.StatusId).Name;
						}
					});
				}
			}
		}

		if (data.VehiclePlatforms.length > 0) {
			const vehicleId = data.VehiclePlatforms[0].VehiclePlatformId;
			if (vehicleId !== undefined && vehicleId !== null) {
				PlatformLabel = dropdownsData.Platforms.find(x => x.id === data.VehiclePlatforms[0].VehiclePlatformId).Name + ' ('
								+ dropdownsData.Platforms.find(x => x.id === data.VehiclePlatforms[0].VehiclePlatformId).Description + ')';
				tempData.PlatformDisplay = PlatformLabel;
			}
		}
		else {
			tempData.PlatformDisplay = '';
		}

		// KPI Calculations
		let diffInHours = '0h 0m';
		if (data.RequestHeaderId.length === 0) {
			const date = new Date(data.AuditDateTime);
			const now = new Date();
			const diffInMilliseconds = now.getTime() - date.getTime();
			diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
		}
		else {
			const statusCheck = data.RequestHeaderId[0].StatusId;
			if (statusCheck === 1) {
				const date = new Date(data.AuditDateTime);
				const now = new Date();
				const diffInMilliseconds = now.getTime() - date.getTime();
				diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
			}
			else {
				const auditDate = new Date(data.AuditDateTime);
				const closingDate = new Date(data.RequestHeaderId[0].AuditDateTime);
				const diffInMilliseconds = closingDate.getTime() - auditDate.getTime();
				diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
			}
		}

		const hours = Math.floor(diffInHours);
		const minutes = Math.round((diffInHours - hours) * 60);
		const formattedKpi = hours.toString() + 'h ' + minutes.toString() + 'm';

		if (data.RequestHeaderId.length > 0 && data.RequestHeaderId[0].StatusId === 2) {
			tempData.CloseDateLabel = data.RequestHeaderId[0].AuditDateTime;
			isClosedDate = true;
		}

		// To avoid reset of comments during switching between main details and inspection screens
		if (refreshComments) {
			dispatch(setComments(data.Comments));
		}

		//Status

		if (data.RequestHeaderId.length === 0) {
			StatusLabel = 'New';
		} else {
			let allCancelled = data.RequestHeaderId.every(item => item.StatusId === 3);
			let hasNull = data.RequestHeaderId.some(item => item.StatusId === null);
			let hasInProgress = data.RequestHeaderId.some(item => item.StatusId === 1);

			if (allCancelled) {
				StatusLabel = 'Cancelled';
			} else if (hasNull) {
				StatusLabel = 'New';
			} else if (hasInProgress && !hasNull) {
				StatusLabel = 'In Progress';
			} else {
				StatusLabel = 'Closed';
			}
		}
		if (data.Locations.length > data.RequestHeaderId.length) StatusLabel = 'New';

		tempData.KpiLabel = formattedKpi;
		tempData.LocationDisplay = LocationLabel;
		tempData.ReasonDisplay = ReasonLabel;
		tempData.RequesterDisplay = RequesterLabel;
		tempData.AuditUserDisplay = AuditUserLabel;
		tempData.StatusDisplay = StatusLabel;
		Object.preventExtensions(tempData);
	}

	const handleImagePrev = () => {
		const currentIndex = currentUrl.index;
		if (currentIndex === 0) return;
		setCurrentUrl(tempUrls[currentIndex - 1]);
	};

	const handleImageNext = () => {
		const currentIndex = currentUrl.index;
		if (currentIndex === (tempUrls.length-1)) return;
		setCurrentUrl(tempUrls[currentIndex + 1]);
	};

	const currentUser = useSelector((state) => state.auth.user);

	const handleSubmit = (values) => {
		next(values, true);
	};

	const handleNext = (values) => {
		next(values);
	};

	const [toggleChooseLocation, isChooseLocationOpen] = useToggle();

	const handleMultipleLocations = (multipleInspections) => {
		if (multipleInspections.Locations.length === 1) {
			const selectedInspection = inspectionData.find(x => x.LocationId === multipleInspections.Locations[0].PirLocationId);
			dispatch(updateLocationId(multipleInspections.Locations[0].PirLocationId));
			handleNext(selectedInspection);
		}
		else toggleChooseLocation();
	};

	const handleLocationSelection = (location) => {
		const selectedInspection = inspectionData.find(x => x.LocationId === location.PirLocationId);
		dispatch(updateLocationId(location.PirLocationId));
		handleNext(selectedInspection);
	};

	const [toggle, isOpen] = useToggle();
	const [imageToggle, isImagesOpen] = useToggle();
	const [noImageToggle, isNoImagesOpen] = useToggle();
	const [deleteValue, setDeleteValue] = useState("");
	const [isReadOnly, setIsReadOnly] = useState(true);

	useEffect(() => {
		setIsReadOnly(currentUser.role === 'view');
	}, [currentUser]);

	const handleDelete = () => {
		toggle();
	};

	const handleImageModal = () => {
		if (tempUrls.length < 1) {
			noImageToggle();
			return;
		}
		setCurrentUrl(tempUrls[0]);
		imageToggle();
	};

	const confirmClick = () => {
		if (deleteValue !== `Delete`) {
			Toast({ status: 'warning', message: 'Input text does not match' });
			return;
		}
		dispatch(deletePirRequest(data.id, data.pir));
		if (closeDrawer) closeDrawer();
		dispatch(resetPirRequestData());
		toggle();
	};

	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = `data:image/png;base64,${currentUrl.Base64Url}`;
		link.download = 'image.png';
		link.click();
	  };

	return (
		<>
			<Formik initialValues={tempData} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize validateOnMount id='spoMainDetails'>
				{({ submitForm, errors, values, setFieldTouched }) => {
					return (
						<>
							<Form>
								<div className='px-4 pt-4 bg-tertiary-cool-4 sm:pt-6 sm:pXb-4 '>
									<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 xl:!col-span-2 2xl:!col-span-1 min-w-fift'
											control='input'
											disabled={true}
											type='text'
											label='PIR Number'
											name='id'
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='input'
											type='text'
											label='Product'
											name='Product'
											disabled={true && !isNew}
										/>
										<FormikControl
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control='input'
											type='text'
											label='Part Description'
											name='Description'
											disabled={true && !isNew}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Date Code'
											name='DateCode'
											disabled={true && !isNew}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='input'
											type='text'
											label='VIN'
											name='Vin'
											disabled={true && !isNew}
										/>
										<FormikControl
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control='textarea'
											type='text'
											label='Issue Description'
											name='AdditionalDetails'
											disabled={true && !isNew}
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control='input'
											type='text'
											label='Vehicle Model'
											name='PlatformDisplay'
											disabled={true}
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control='input'
											type='text'
											label='Location'
											name='LocationDisplay'
											disabled={true && !isNew}
										/>
										<FormikControl
											control='input'
											type='text'
											label='Reason'
											name='ReasonDisplay'
											disabled={true && !isNew}
										/>
										<FormikControl
											control='input'
											type='text'
											label='WERS Number'
											name='WERSNumber'
											disabled={true && !isNew}
										/>
										<FormikControl 
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control='input' 
											type='text' 
											label='Additional Contact Info' 
											name='AdditionalContactInfo' 
											disabled={true && !isNew} 
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='date'
											type='date'
											label='Create Date'
											disabled={true}
											name='AuditDateTime'
										/>
										<FormikControl
											// className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Audit User Name'
											name='AuditUserDisplay'
											disabled={true && !isNew}
										/>
										<FormikControl
											// className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Requested By'
											name='RequesterDisplay'
											disabled={true && !isNew}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Status'
											name='StatusDisplay'
											disabled={true && !isNew}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='KPI'
											name='KpiLabel'
											disabled={true}
										/>
										{isClosedDate && <FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='date'
											type='date'
											label='Close Date'
											disabled={true}
											name='CloseDateLabel'
										/>}
										{!isNew && <Button
											type='button'
											disabled={false}
											onClick={handleImageModal}
											// color='danger'
											className={`bg-tertiary-cool-2 lg:!col-span-2 place-self-center flex items-center`}
										>
											Show Images
										</Button>}
									</div>
								</div>
							</Form>
							<hr />
							{data.id && <Comments commentType='headerComment' commentsData={data.Comments} pirRequestId={data.id}/>}
							<div className='grid grid-cols-3 gap-4px-4 py-3 bg-white text-left sm:px-6 rounded-b'>
								{currentUser.role !== Role.view && (
									<>
										<Button
											type='button'
											disabled={data.spoStatusId > 2 || profileData.role === 'View'}
											onClick={handleDelete}
											color='danger'
											className={`place-self-start col-start-1`}
										>
											Delete
										</Button>
									</>
								)}
								<Button
									type='button'
									onClick={() => {
										return handleMultipleLocations(tempData);
									}}
									className={`bg-tertiary-cool-2 col-start-3 place-self-end flex items-center`}
								>
									{isMultipleLocation ? 'Choose Location' : <>Inspection <ArrowRightIcon className='h-4 ml-2' /></>}
								</Button>
							</div>
						</>
					);
				}}
			</Formik>
			{isOpen && (
				<Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Delete PIR</ModalHeader>
					<ModalBody>
						<div className='d-flex flex-row bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								Please type <span className='text-danger font-bold'>Delete </span> and click delete.
							</div>
							<SearchInput onClick={confirmClick} onChange={(e) => setDeleteValue(e)} />
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggle} color='danger'>
							Cancel
						</Button>
						<Button disabled={deleteValue === '' ? true : false} onClick={confirmClick} color='primary' className='mr-1'>
							Delete
						</Button>
					</ModalFooter>
				</Modal>
			)}
			{isImagesOpen && (
				<Modal isOpen={isImagesOpen} toggle={imageToggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Attached Images</ModalHeader>
					<ModalBody>
						<div className=' bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								<img src={`data:image/png;base64,${currentUrl.Base64Url}`}/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
							<Button onClick={handleImagePrev} disabled={currentUrl.index === 0}>
								Prev
							</Button>
							<Button onClick={handleImageNext} disabled={currentUrl.index === tempUrls.length-1}>
								Next
							</Button>
					</ModalFooter>
					<ModalFooter>
							<Button onClick={imageToggle} color='danger'>
								Close
							</Button>
							<div>
								<b>Total images - </b> {noOfImages}
							</div>
							<Button onClick={handleDownload}>
								Download
							</Button>
					</ModalFooter>
				</Modal>
			)}
			{isNoImagesOpen && (
				<Modal isOpen={isNoImagesOpen} toggle={noImageToggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Attached Images</ModalHeader>
					<ModalBody>
						<div className=' bd-highlight mb-3'>
							<div className='p-2 bd-highlight'><span>No Images Uploaded for the selected PIR</span>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
							<Button onClick={noImageToggle} color='danger'>
								Close
							</Button>
					</ModalFooter>
				</Modal>
			)}
			{isChooseLocationOpen && (
				<Modal isOpen={isChooseLocationOpen} toggle={toggleChooseLocation} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Choose a Location for Inspection</ModalHeader>
					<ModalBody>
					<div className='flex fle justify-between items-center'>
						<table className={`${tableStyles.table}`}>
							<thead className={tableStyles.thead.base}>
							<tr>
								<th>Location Name</th>
								<th>Status</th>
								<th>Select</th>
							</tr>
							</thead>
							<tbody>
							{tempData.Locations.length > 0 &&
								tempData.Locations.map((location, i) => (
								<tr key={i} className={tableStyles.tbody.tr}>
									<td> {dropdownsData.Locations.find(x => x.id === location.PirLocationId).Name
										+ ' (' + dropdownsData.Locations.find(x => x.id === location.PirLocationId).Code + ')'}
									</td>
									<td>
										<StatusPill value={location.Status} />{" "}
									</td>
									<td className={tableStyles.tbody.td.actions}>
										<Button
											color="primary"
											className={`bg-tertiary-cool-2 col-start-3 place-self-end flex items-center`}
											size="sm"
											onClick={() => handleLocationSelection(location)}
											disabled={profileData.role === 'View'}
										>
											Select
											<ArrowRightIcon className='h-4 ml-2' />
										</Button>
									</td>
								</tr>
								))}
							</tbody>
						</table>
						</div>
					</ModalBody>
					<ModalFooter>
							<Button onClick={toggleChooseLocation} color='danger'>
								Close
							</Button>
					</ModalFooter>
				</Modal>
			)}

		</>
	);
};

export default MainDetails;

MainDetails.propTypes = {
	data: PropTypes.object,
	next: PropTypes.func,
	isNew: PropTypes.bool,
	isMultipleLocation: PropTypes.bool,
	typeOptions: PropTypes.array,
	statusOptions: PropTypes.array,
	ownerOptions: PropTypes.array,
	closeDrawer: PropTypes.func
};
