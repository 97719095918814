import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/ui/Formik/FormikControl';
import Button from '../../packages/button/Button';

import { createUser } from '../../store/authActions';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const initialValues = {
	surname: 'User',
	givenName: 'Pir',
	email: 'spousr@jlr.com',
	password: 'usrspo',
	role: 'user'
};

// Yup validation schema
const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email').required('Email required'),
	password: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Password required')
});

const Register = () => {
	let location = useLocation();
	const dispatch = useDispatch();

	const handleSubmit = (formData) => {
		dispatch(createUser(formData));
	};

	return (
		<div className='m-auto max-w-md'>
			<h2 className='mb-4 text-primary-1 text-lg font-bold md:text-2xl'>User Registration</h2>

			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnBlur={false}>
				{(formik) => (
					<Form>
						<div className=' border p-6 bg-white shadow-sm'>
							<FormikControl control='input' type='text' label='First Name' name='givenName' />
							<FormikControl control='input' type='text' label='Surname' name='surname' />
							<FormikControl control='input' type='text' label='Email' name='email' />
							<FormikControl control='input' type='text' label='Role' name='role' />
							<FormikControl
								// className="!col-span-2 md:!col-span-3 xl:!col-span-5 2xl:!col-span-6"
								control='input'
								type='password'
								label='Password'
								name='password'
								autoComplete='off'
							/>

							<Button type='submit'>Submit</Button>
						</div>
					</Form>
				)}
			</Formik>
			<Link className='my-4 block' to={'/login'} state={{ ...location, prevPath: location.pathname }}>
				Login
			</Link>
		</div>
	);
};

export default Register;
