import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateObject } from "../../utils/utility";
import useToggle from "../../packages/_utils/useToggle";
import {
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
} from "../../store/locationActions";
import Button from "../../packages/button/Button";
import Icon from "../../components/ui/Icon";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";
import { tableStyles } from "./Settings";
import SelectInput from "../../components/ui/SelectInput";

import { Role } from "../../data/roles";
import Toast from "../../components/ui/Toast";

const initialLocation = {
  id: 0,
  Code: "",
  Name: "",
  LocationType: "",
  Region: ""
};

const Location = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profilesData);
  const currentUser = useSelector((state) => state.auth.user);

  const locations = useSelector((state) => state.location.data);

  // const locationTypes = useSelector((state) => state.dropdown.dropdownsData).LocationTypes;
  // const regions = useSelector((state) => state.dropdown.dropdownsData).Regions;
  // const [locationTypesOptionList, setLocationTypesOptionList] = useState();

  var tempData = JSON.parse(JSON.stringify(locations));
	Object.preventExtensions(tempData);

  // if (locationTypes.length > 0 && regions.length > 0) {
  //   for (let index = 0; index < tempData.length; index++) {
  //       const element = tempData[index];
  //       tempData[index].LocationTypeName = locationTypes.find(x => x.id === element.LocationTypeId).Name;
  //       const regionId = locationTypes.find(x => x.id === element.LocationTypeId).RegionId;
  //       tempData[index].RegionName = regions.find(x => x.id === regionId).Name;
  //   }
  // }

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  // useEffect(() => {
  //   const options = locationTypes.map((option, i) => {
  //     return { value: option.id, label: option.Name };
  //   });
  //   setLocationTypesOptionList(options);
  // }, [locationTypes]);

  const [toggle, isOpen] = useToggle();
  const [modalTitle, setModalTitle] = useState("");
  const [selectedLocation, setSelectedLocation] =
    useState(initialLocation);

  const addClick = () => {
    setModalTitle("Add Location");
    toggle();
    setSelectedLocation(initialLocation);
  };

  const createClick = () => {

    if (selectedLocation.Name === "" || selectedLocation.Code === "" || selectedLocation.LocationType === "" || selectedLocation.Region === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

    const location = {
      Name: selectedLocation["Name"],
      Code: selectedLocation["Code"],
      LocationType: selectedLocation["LocationType"],
      Region: selectedLocation["Region"]
    };
    dispatch(createLocation(location));
    toggle();
  };

  const updateClick = () => {

    if (selectedLocation.Name === "" || selectedLocation.Code === "" || selectedLocation.LocationType === "" || selectedLocation.Region === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

    const location = {
      id: selectedLocation["id"],
      Name: selectedLocation["Name"],
      Code: selectedLocation["Code"],
      LocationType: selectedLocation["LocationType"],
      Region: selectedLocation["Region"]
    };
    dispatch(updateLocation(location));
    toggle();
  };

  const editClick = (location) => {
    setModalTitle("Edit Location");
    setSelectedLocation(location);
    toggle();
  };

  const deleteClick = (id) => {
    dispatch(deleteLocation(id));
  };

  const onChangeHandler = (e, field) => {
    let updatedLocation = updateObject(selectedLocation, {
      [field]: e.target.value,
    });
    setSelectedLocation(updatedLocation);
  };

  const onLocationTypeChangeHandler = (value, field) => {
    let updatedLocation = updateObject(selectedLocation, {
      [field]: value,
    });
    setSelectedLocation(updatedLocation);
  };

  return (
    <div className="">
      <div className="flex fle justify-between items-center">
        <p className="text-left w-full">{`${tempData.length} Location${
          tempData.length > 1 ? "s" : ""}`}</p>
        {currentUser.role !== Role.view && (
          <div className="flex-none">
            <Button onClick={addClick} color="primary" disabled={profileData.role === 'View'}>
              Add to Location
            </Button>
          </div>
        )}
      </div>

      <table className={`${tableStyles.table}`}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>Code</th>
            <th>Name</th>
            <th>Location Type</th>
            <th>Region</th>
            {currentUser.role !== Role.view && (
              <th className={tableStyles.thead.th.actions}>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {tempData &&
            tempData.map((m, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{m.Code}</td>
                <td>{m.Name}</td>
                <td>{m.LocationType}</td>
                <td>{m.Region}</td>
                {currentUser.role !== Role.view && (
                  <td className={tableStyles.tbody.td.actions}>
                    <Button
                      color="primary"
                      className="btn mr-1"
                      size="sm"
                      onClick={() => editClick(m)}
                      disabled={profileData.role === 'View'}
                    >
                      <Icon iconName="Pencil" />
                    </Button>
                    <Button
                      color="danger"
                      className="btn mr-1"
                      size="sm"
                      onClick={() => deleteClick(m.id)}
                      disabled={profileData.role !== 'Admin'}
                    >
                      <Icon iconName="Trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        animate={true}
        closeOnClickOutside={true}
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 w-full bd-highlight">
              <div className="input-group">
                <div className="input-group mb-3">
                    <span className="input-group-text">Code</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedLocation.Code}
                    onChange={(e) => onChangeHandler(e, "Code")}
                    />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Name</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedLocation.Name}
                    onChange={(e) => onChangeHandler(e, "Name")}
                    />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">LocationType</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedLocation.LocationType}
                    onChange={(e) => onChangeHandler(e, "LocationType")}
                    />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Region</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedLocation.Region}
                    onChange={(e) => onChangeHandler(e, "Region")}
                    />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
          {selectedLocation.id === 0 ? (
            <Button onClick={createClick} color="primary" className="mr-1">
              Add
            </Button>
          ) : null}
          {selectedLocation.id !== 0 ? (
            <Button onClick={updateClick} color="primary" className="mr-1">
              Update
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Location;
