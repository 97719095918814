import PropTypes from 'prop-types';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

const PrivateRoute = ({ roles }) => {
	let location = useLocation();
	const user = useSelector((state) => state.auth.user);

	if (!roles) {
		// console.log('network error', user)
		// not logged in so redirect to login page with the return url
		return <Navigate to='/networkError' state={{ from: '/' }} />;
	}

	// console.log(user.userName, user.role)
	if (!user.userName) {
		// console.log('not signed in')
		// not logged in so redirect to login page with the return url
		return <Navigate to='/login' state={{ from: location }} />;
	}

	// check if route is restricted by role
	if (roles && roles.indexOf(user.role) === -1) {
		// console.log('unauthorised')
		// role not authorised so redirect to home page
		return <Navigate to={{ pathname: '/unauthorised', state: { from: location } }} />;
	}

	// authorised so return component
	return <Outlet />;
};

export default PrivateRoute;

PrivateRoute.propTypes = {
	roles: PropTypes.array
};
