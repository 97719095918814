import PropTypes from 'prop-types';

const Jumbotron = ({ children, className }) => {
	return <div className={`${className} font-jlr-emeric-semi-bold shadow-md font-light rounded-sm p-10 pxd-8 mb-8`}>{children}</div>;
};

Jumbotron.propTypes = {
	children: PropTypes.array,
	className: PropTypes.string
};

export default Jumbotron;
