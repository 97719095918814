import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

import style from './Styles';

const Textarea = (props) => {
	const { label, name, className, ...rest } = props;
	return (
		<div className={`${className || style.formControl}`}>
			<label htmlFor={name} className={style.label}>
				{label}
			</label>
			<Field as='textarea' id={name} name={name} {...rest} className={style.input} />
			<ErrorMessage component={TextError} name={name} />
		</div>
	);
};

export default Textarea;

Textarea.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
};
