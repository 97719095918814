import React, { useCallback, useEffect, useState } from 'react';

import './Intro.css';

const Intro = () => {
	const [runAnimation, setRunAnimation] = useState(true);

	const handleRunAmimation = useCallback(() => setRunAnimation(!runAnimation), [runAnimation]);

	useEffect(() => {
		if (!runAnimation) handleRunAmimation('10vh');
	}, [handleRunAmimation, runAnimation]);

	return (
		<div id='container'>
			<div id='logoWrapper' onClick={handleRunAmimation} onKeyPress={handleRunAmimation} className={runAnimation ? 'run-animation' : ''}>
				<svg id='appHeadline' width='357' height='113' viewBox='0 0 357 113' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<defs>
						<filter id='demo1'>
							{/*<!--Blur effect-->*/}
							<feGaussianBlur stdDeviation='5' result='blur1' />
							{/* <!--Lighting effect-->*/}
							<feSpecularLighting result='spec1' in='blur1' specularExponent='1000' lightingColor='#cccccc'>
								{/* <!--Light source effect-->*/}
								<fePointLight x='-200' y='0' z='200'>
									<animate attributeName='x' values='-200;516;800' dur='3s' repeatCount='indefinite' begin='2.5s' restart='always' />
									{/*<!-- <animate attributeName="y" values="0;123;1000" dur="3s" repeatCount="indefinite" /> -->*/}
								</fePointLight>
							</feSpecularLighting>
							{/*<!--Composition of inputs-->*/}
							<feComposite in='SourceGraphic' in2='spec1' operator='arithmetic' k1='0' k2='1' k3='1' k4='0' />
						</filter>
					</defs>
					<g filter='url(#demo1)'>
						<path d='M24.1304 77.13H50.3104C64.9304 77.13 74.2804 73.73 81.0804 67.44C88.2204 60.64 91.9604 50.44 91.9604 38.37C91.9604 26.81 88.3904 17.63 82.4404 11.51C74.2804 3.34999 61.8704 0.289993 44.7004 0.289993H0.670349V113H24.1304V77.13ZM24.1304 57.58V20.18H46.0603C54.2204 20.18 59.8303 21.37 63.4003 24.77C66.2904 27.49 67.9903 32.08 67.9903 38.37C67.9903 44.49 66.1203 50.95 63.5704 54.52C62.0404 56.73 60.1704 57.58 56.4304 57.58H24.1304Z' />
						<path d='M159.26 113H182.72V0.289993H159.26V113Z' />
						<path d='M328.287 110.62C329.647 113 330.327 113 332.537 113H356.167L330.497 70.5C341.547 63.53 349.197 51.97 349.197 37.01C349.197 10.32 332.197 0.289993 301.767 0.289993H257.397V113H280.857V75.09H304.487C307.037 75.09 307.547 75.09 308.907 77.3L328.287 110.62ZM280.857 55.54V20.18H302.447C311.967 20.18 317.577 21.54 321.147 24.6C324.037 27.32 325.227 31.23 325.227 37.01C325.227 43.13 323.867 48.74 321.317 52.48C319.787 54.69 317.917 55.54 314.687 55.54H280.857Z' />
					</g>
				</svg>

				<svg id='appStrapline' width='392' height='22' viewBox='0 0 392 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path d='M4.26043 11.936H7.95643C10.0204 11.936 11.3404 11.456 12.3004 10.568C13.3084 9.60799 13.8364 8.16799 13.8364 6.46399C13.8364 4.83199 13.3324 3.53599 12.4924 2.67199C11.3404 1.51999 9.58843 1.08799 7.16443 1.08799H0.948425V17H4.26043V11.936ZM4.26043 9.17599V3.89599H7.35643C8.50843 3.89599 9.30043 4.06399 9.80443 4.54399C10.2124 4.92799 10.4524 5.57599 10.4524 6.46399C10.4524 7.32799 10.1884 8.23999 9.82843 8.74399C9.61243 9.05599 9.34843 9.17599 8.82043 9.17599H4.26043Z' />
					<path d='M26.7936 16.664C26.9856 17 27.0816 17 27.3936 17H30.7296L27.1056 11C28.6656 10.016 29.7456 8.38399 29.7456 6.27199C29.7456 2.50399 27.3456 1.08799 23.0496 1.08799H16.7856V17H20.0976V11.648H23.4336C23.7936 11.648 23.8656 11.648 24.0576 11.96L26.7936 16.664ZM20.0976 8.88799V3.89599H23.1456C24.4896 3.89599 25.2816 4.08799 25.7856 4.51999C26.1936 4.90399 26.3616 5.45599 26.3616 6.27199C26.3616 7.13599 26.1696 7.92799 25.8096 8.45599C25.5936 8.76799 25.3296 8.88799 24.8736 8.88799H20.0976Z' />
					<path d='M40.3859 17.24H40.4339C44.6339 17.24 48.4259 14.072 48.4259 9.05599C48.4259 4.01599 44.6339 0.847992 40.4339 0.847992H40.3859C36.1859 0.847992 32.3939 4.01599 32.3939 9.05599C32.3939 14.072 36.1859 17.24 40.3859 17.24ZM35.8499 9.05599C35.8499 5.86399 37.5779 4.08799 40.3859 4.08799H40.4339C43.2419 4.08799 44.9699 5.86399 44.9699 9.05599C44.9699 12.224 43.2419 14.024 40.4339 14.024H40.3859C37.5779 14.024 35.8499 12.2 35.8499 9.05599Z' />
					<path d='M56.9515 17C60.0955 17 62.4475 16.4 64.0555 15.008C65.5675 13.712 66.4075 11.72 66.4075 9.05599C66.4075 6.34399 65.5675 4.37599 64.0555 3.07999C62.4475 1.68799 60.0955 1.08799 56.9515 1.08799H51.6475V17H56.9515ZM54.9595 14.12V3.96799H56.8555C59.1835 3.96799 60.7675 4.32799 61.7515 5.28799C62.5675 6.07999 62.9755 7.27999 62.9755 9.03199C62.9755 10.784 62.5675 12.008 61.7515 12.8C60.7675 13.76 59.1835 14.12 56.8555 14.12H54.9595Z' />
					<path d='M83.4895 1.08799H80.2495V10.16C80.2495 11.6 80.0815 12.488 79.4335 13.136C78.8575 13.712 77.7055 14.072 76.5055 14.072C75.3055 14.072 74.1775 13.712 73.5775 13.136C72.9295 12.488 72.7615 11.6 72.7615 10.16V1.08799H69.4495V10.448C69.4495 12.728 70.0255 14.216 71.1535 15.344C72.4015 16.592 74.2975 17.24 76.4575 17.24C78.6415 17.24 80.5615 16.592 81.7855 15.344C82.9135 14.216 83.4895 12.728 83.4895 10.448V1.08799Z' />
					<path d='M100.17 12.08L97.7462 13.256C96.5462 13.832 95.5142 14.072 94.5302 14.072C91.6022 14.072 89.9942 12.056 89.9942 9.03199C89.9942 5.62399 91.8422 4.01599 94.0262 4.01599C95.3462 4.01599 96.3062 4.56799 97.4822 5.64799L98.9462 6.99199L101.082 5.62399C99.9782 2.64799 97.6022 0.847992 94.2662 0.847992C90.1142 0.847992 86.5382 3.96799 86.5382 9.05599C86.5382 13.736 89.5382 17.24 94.1702 17.24C97.2182 17.24 99.8102 15.632 101.466 13.76L100.17 12.08Z' />
					<path d='M111.778 3.87199H117.082V1.08799H103.162V3.87199H108.466V17H111.778V3.87199Z' />
					<path d='M126.124 17H129.436V1.08799H126.124V17Z' />
					<path d='M144.899 11.936C144.467 11.288 143.339 9.82399 142.283 8.50399L136.595 1.37599C136.403 1.13599 136.307 1.08799 135.995 1.08799H133.499V17H136.691V6.12799C137.075 6.65599 137.987 7.90399 138.827 8.95999L144.995 16.712C145.187 16.952 145.307 17 145.619 17H148.091V1.08799H144.899V11.936Z' />
					<path d='M151.334 15.56C152.99 16.472 155.606 17.24 157.718 17.24C161.558 17.24 163.766 15.272 163.766 12.2C163.766 9.77599 162.47 8.50399 158.63 7.39999L157.142 6.96799C155.462 6.48799 154.598 6.07999 154.598 5.19199C154.598 4.18399 155.678 3.75199 157.766 3.75199C158.654 3.75199 159.998 3.87199 161.222 4.01599L162.494 4.15999L162.998 1.92799C161.294 1.23199 159.398 0.847992 157.574 0.847992C154.19 0.847992 151.502 2.33599 151.502 5.21599C151.502 7.68799 153.278 8.98399 156.47 9.91999L158.102 10.4C159.95 10.952 160.646 11.312 160.646 12.344C160.646 13.64 159.518 14.336 157.91 14.336C156.83 14.336 155.63 14.168 153.806 13.76L152.174 13.4L151.334 15.56Z' />
					<path d='M170.313 11.936H174.009C176.073 11.936 177.393 11.456 178.353 10.568C179.361 9.60799 179.889 8.16799 179.889 6.46399C179.889 4.83199 179.385 3.53599 178.545 2.67199C177.393 1.51999 175.641 1.08799 173.217 1.08799H167.001V17H170.313V11.936ZM170.313 9.17599V3.89599H173.409C174.561 3.89599 175.353 4.06399 175.857 4.54399C176.265 4.92799 176.505 5.57599 176.505 6.46399C176.505 7.32799 176.241 8.23999 175.881 8.74399C175.665 9.05599 175.401 9.17599 174.873 9.17599H170.313Z' />
					<path d='M182.838 1.08799V17H194.55V14.216H186.15V10.256H193.518V7.49599H186.15V3.87199H194.31V1.08799H182.838Z' />
					<path d='M210.626 12.08L208.202 13.256C207.002 13.832 205.97 14.072 204.986 14.072C202.058 14.072 200.45 12.056 200.45 9.03199C200.45 5.62399 202.298 4.01599 204.482 4.01599C205.802 4.01599 206.762 4.56799 207.938 5.64799L209.402 6.99199L211.538 5.62399C210.434 2.64799 208.058 0.847992 204.722 0.847992C200.57 0.847992 196.994 3.96799 196.994 9.05599C196.994 13.736 199.994 17.24 204.626 17.24C207.674 17.24 210.266 15.632 211.922 13.76L210.626 12.08Z' />
					<path d='M222.233 3.87199H227.537V1.08799H213.617V3.87199H218.921V17H222.233V3.87199Z' />
					<path d='M230.399 17H233.711V1.08799H230.399V17Z' />
					<path d='M244.997 17.24H245.045C249.245 17.24 253.037 14.072 253.037 9.05599C253.037 4.01599 249.245 0.847992 245.045 0.847992H244.997C240.797 0.847992 237.005 4.01599 237.005 9.05599C237.005 14.072 240.797 17.24 244.997 17.24ZM240.461 9.05599C240.461 5.86399 242.189 4.08799 244.997 4.08799H245.045C247.853 4.08799 249.581 5.86399 249.581 9.05599C249.581 12.224 247.853 14.024 245.045 14.024H244.997C242.189 14.024 240.461 12.2 240.461 9.05599Z' />
					<path d='M267.659 11.936C267.227 11.288 266.099 9.82399 265.043 8.50399L259.355 1.37599C259.163 1.13599 259.067 1.08799 258.755 1.08799H256.259V17H259.451V6.12799C259.835 6.65599 260.747 7.90399 261.587 8.95999L267.755 16.712C267.947 16.952 268.067 17 268.379 17H270.851V1.08799H267.659V11.936Z' />
					<path d='M291.027 16.664C291.219 17 291.315 17 291.627 17H294.963L291.339 11C292.899 10.016 293.979 8.38399 293.979 6.27199C293.979 2.50399 291.579 1.08799 287.283 1.08799H281.019V17H284.331V11.648H287.667C288.027 11.648 288.099 11.648 288.291 11.96L291.027 16.664ZM284.331 8.88799V3.89599H287.379C288.723 3.89599 289.515 4.08799 290.019 4.51999C290.427 4.90399 290.595 5.45599 290.595 6.27199C290.595 7.13599 290.403 7.92799 290.043 8.45599C289.827 8.76799 289.563 8.88799 289.107 8.88799H284.331Z' />
					<path d='M297.841 1.08799V17H309.553V14.216H301.153V10.256H308.521V7.49599H301.153V3.87199H309.313V1.08799H297.841Z' />
					<path d='M320.036 17.24C320.42 17.24 320.828 17.192 321.212 17.144L323.468 21.464L326.708 20.168L324.092 16.184C326.372 14.84 328.028 12.392 328.028 9.05599C328.028 4.01599 324.236 0.847992 320.036 0.847992H319.988C315.788 0.847992 311.996 4.01599 311.996 9.05599C311.996 14.096 315.788 17.24 320.036 17.24ZM315.452 9.05599C315.452 5.86399 317.18 4.08799 319.988 4.08799H320.036C322.844 4.08799 324.572 5.86399 324.572 9.05599C324.572 12.224 322.844 14.024 320.036 14.024H319.988C317.18 14.024 315.452 12.2 315.452 9.05599Z' />
					<path d='M345.121 1.08799H341.881V10.16C341.881 11.6 341.713 12.488 341.065 13.136C340.489 13.712 339.337 14.072 338.137 14.072C336.937 14.072 335.809 13.712 335.209 13.136C334.561 12.488 334.393 11.6 334.393 10.16V1.08799H331.081V10.448C331.081 12.728 331.657 14.216 332.785 15.344C334.033 16.592 335.929 17.24 338.089 17.24C340.273 17.24 342.193 16.592 343.417 15.344C344.545 14.216 345.121 12.728 345.121 10.448V1.08799Z' />
					<path d='M348.938 1.08799V17H360.65V14.216H352.25V10.256H359.618V7.49599H352.25V3.87199H360.41V1.08799H348.938Z' />
					<path d='M363.586 15.56C365.242 16.472 367.858 17.24 369.97 17.24C373.81 17.24 376.018 15.272 376.018 12.2C376.018 9.77599 374.722 8.50399 370.882 7.39999L369.394 6.96799C367.714 6.48799 366.85 6.07999 366.85 5.19199C366.85 4.18399 367.93 3.75199 370.018 3.75199C370.906 3.75199 372.25 3.87199 373.474 4.01599L374.746 4.15999L375.25 1.92799C373.546 1.23199 371.65 0.847992 369.826 0.847992C366.442 0.847992 363.754 2.33599 363.754 5.21599C363.754 7.68799 365.53 8.98399 368.722 9.91999L370.354 10.4C372.202 10.952 372.898 11.312 372.898 12.344C372.898 13.64 371.77 14.336 370.162 14.336C369.082 14.336 367.882 14.168 366.058 13.76L364.426 13.4L363.586 15.56Z' />
					<path d='M385.942 3.87199H391.246V1.08799H377.326V3.87199H382.63V17H385.942V3.87199Z' />
				</svg>
			</div>
		</div>
	);
};

export default Intro;
