import PropTypes from 'prop-types';

const SimpleCard = ({ children, className }) => {
	return (
		<div className='flex items-center justify-between w-full'>
			<div
				className={`${className} flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-primary-2 dadrk:bg-secondary-4 shadow-md text-primary-1`}
			>
				{/* <div className="w-full lg:w-2/3 h-64 dark:bg-gray-800" />
                <div className="w-full lg:w-1/3 h-24 dark:border-gray-700 lg:h-64 border-t lg:border-t-0 lg:border-r lg:border-l lg:rounded-r dark:bg-gray-700 bg-gray-100" /> */}
				{children}
			</div>
		</div>
	);
};

SimpleCard.propTypes = {
	children: PropTypes.any,
	className: PropTypes.string
};

export default SimpleCard;
