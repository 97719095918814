import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useToggle from '../../packages/_utils/useToggle';
import Button from '../../packages/button/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../packages/modal/Modal';

import { getContacts, updateContact, createContact, deleteContact } from '../../store/contactsActions';

import { updateObject } from '../../utils/utility';
import Icon from '../../components/ui/Icon';

import { Role } from '../../data/roles';
import SearchInput from '../../components/ui/SearchInput';
import { tableStyles } from './Settings';
import { Navigate } from 'react-router';
import Toast from '../../components/ui/Toast';

const initialContact = {
	id: 0,
	UserName: '',
	GivenName: '',
	Surname: '',
	Email: ''
};

const Contacts = () => {

	const profileData = useSelector((state) => state.profile.profilesData);
	if (!profileData.is_active) {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}
	
	const dispatch = useDispatch();
	const [toggle, isOpen] = useToggle();
	const currentUser = useSelector((state) => state.auth.user);
	const contacts = useSelector((state) => state.contacts.data);
	const [selectedContact, setSelectedContact] = useState(initialContact);
	const [searchValue, setSearchValue] = useState('');
	const [filteredList, setFilteredList] = useState(contacts);

	const [modalTitle, setModalTitle] = useState('');

	useEffect(() => {
		dispatch(getContacts());
	}, [dispatch]);

	const addClick = () => {
		setModalTitle('Create New Contact');
		toggle();
		setSelectedContact(initialContact);
	};

	const editClick = (contact) => {
		setModalTitle('Edit Contact');
		setSelectedContact(contact);
		toggle();
	};

	const createClick = () => {

		const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
		const checkEmail = selectedContact.Email;

		if (!emailRegex.test(checkEmail)) {
			Toast({ status: "warning", message: 'Invalid Email' });
			return;
		}

		if (selectedContact.UserName === "" || selectedContact.GivenName === "" || selectedContact.Surname === "" || selectedContact.Email === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

		const existingUserName = contacts.find(x => x.UserName === selectedContact.UserName);
		const existingEmail = contacts.find(x => x.Email === selectedContact.Email);

		if (existingUserName) {
			Toast({ status: "warning", message: 'Existing Username' });
			return;
		}

		if (existingEmail) {
			Toast({ status: "warning", message: 'Existing Email' });
			return;
		}

		const newContact = {
			UserName: selectedContact.UserName,
			GivenName: selectedContact.GivenName,
			Surname: selectedContact.Surname,
			Email: selectedContact.Email
		};
		dispatch(createContact(newContact));
		toggle();
		setSearchValue(selectedContact.Email);
	};

	const updateClick = () => {
		
		const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
		const checkEmail = selectedContact.Email;

		if (!emailRegex.test(checkEmail)) {
			Toast({ status: "warning", message: 'Invalid Email' });
			return;
		}
		
		if (selectedContact.UserName === "" || selectedContact.GivenName === "" || selectedContact.Surname === "" || selectedContact.Email === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

		const newContact = {
			UserName: selectedContact.UserName,
			GivenName: selectedContact.GivenName,
			Surname: selectedContact.Surname,
			Email: selectedContact.Email,
			id: selectedContact.id
		};
		dispatch(updateContact(newContact));
		toggle();
		setSearchValue(selectedContact.Email);
	};

	const deleteClick = (id) => {
		dispatch(deleteContact(id));
	};

	const onChangeHandler = (e, field) => {
		let updatedContact = updateObject(selectedContact, {
			[field]: e.target.value
		});
		setSelectedContact(updatedContact);
	};

	const searchClickHandler = (e) => {};

	const searchChangeHandler = (e) => {
		setSearchValue(e);
		setFilteredList(filterList(e));
	};

	// Region filter
	const filterList = useCallback(
		(value) => {
			if (!value) return contacts;
			return contacts.filter(
				(c) =>
					c.UserName?.toLowerCase().includes(value.toLowerCase()) ||
					c.Email?.toLowerCase().includes(value.toLowerCase()) ||
					c.GivenName?.toLowerCase().includes(value.toLowerCase()) ||
					c.Surname?.toLowerCase().includes(value.toLowerCase())
			);
		},
		[contacts]
	);

	useEffect(() => {
		setFilteredList(filterList(searchValue));
	}, [filterList, searchValue]);
	// Endregion

	return (
		<div className=''>
			<div className='flex fle justify-between items-center'>
				<div className='flex'>
					<SearchInput
						label={'Filter'}
						// icon={<SearchIcon />}
						className='w-40 shadow-sm ml-4 dark:text-primary-2'
						iconPosition='right'
						onClick={searchClickHandler}
						onChange={searchChangeHandler}
						value={searchValue}
						placeholder={`Start typing to filter list`}
					/>
				</div>
				<p className='text-left w-full pl-4'>{`${filteredList.length} Contact${filteredList.length > 1 ? 's' : ''} ${
					filteredList.length < contacts.length ? 'Filtered' : ''
				}`}</p>
				{currentUser.role !== Role.view && (
					<div className='flex-none'>
						<Button onClick={addClick} color='primary' disabled={profileData.role === 'View'}>
							Add Contact
						</Button>
					</div>
				)}
			</div>

			<table className={tableStyles.table}>
				<thead className={tableStyles.thead.base}>
					<tr>
						<th>User Name</th>
						<th>Email</th>
						<th>First Name</th>
						<th>Last Name</th>
						{currentUser.role !== Role.view && <th className={tableStyles.thead.th.actions}>Actions</th>}
					</tr>
				</thead>
				<tbody>
					{filteredList &&
						filteredList.map((contact, i) => (
							<tr key={i} className={tableStyles.tbody.tr}>
								<td>{contact.UserName}</td>
								<td>{contact.Email}</td>
								<td>{contact.GivenName}</td>
								<td>{contact.Surname}</td>
								{currentUser.role !== Role.view && (
									<td className={tableStyles.tbody.td.actions}>
										<Button color='primary' className='btn mr-1 ' size='sm' 
										onClick={() => editClick(contact)} disabled={profileData.role === 'View'}>
											<Icon iconName='Pencil' />
										</Button>
										<Button color='danger' className='btn mr-1' size='sm' 
										onClick={() => deleteClick(contact.id)} disabled={profileData.role !== 'Admin'}>
											<Icon iconName='Trash' />
										</Button>
									</td>
								)}
							</tr>
						))}
				</tbody>
			</table>

			<Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
				<ModalHeader>{modalTitle}</ModalHeader>
				<ModalBody>
					<div className='d-flex flex-row bd-highlight mb-3'>
						<div className='p-2 w-96 bd-highlight'>
							{modalTitle!=='Edit Contact' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>User Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedContact.UserName ?? ''}
									onChange={(e) => onChangeHandler(e, 'UserName')}
								/>
							</div>}
							{modalTitle==='Edit Contact' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>User Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedContact.UserName ?? ''}
									onChange={(e) => onChangeHandler(e, 'UserName')}
									disabled={true}
								/>
							</div>}

							{modalTitle!=='Edit Contact' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>Email</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedContact.Email ?? ''}
									onChange={(e) => onChangeHandler(e, 'Email')}
								/>
							</div>}

							{modalTitle==='Edit Contact' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>Email</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedContact.Email ?? ''}
									onChange={(e) => onChangeHandler(e, 'Email')}
									disabled={true}
								/>
							</div>}

							<div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>First Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedContact.GivenName ?? ''}
									onChange={(e) => onChangeHandler(e, 'GivenName')}
								/>
							</div>

							<div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>Last Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedContact.Surname ?? ''}
									onChange={(e) => onChangeHandler(e, 'Surname')}
								/>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={toggle} color='danger'>
						Cancel
					</Button>
					{selectedContact.id === 0 ? (
						<Button onClick={createClick} color='primary' className='mr-1'>
							Create
						</Button>
					) : null}

					{selectedContact.id !== 0 ? (
						<Button onClick={updateClick} color='primary' className='mr-1'>
							Update
						</Button>
					) : null}
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default Contacts;
