import PropTypes from 'prop-types';
import { Field } from 'formik';

import style from './Styles';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import Tippy from '@tippyjs/react';

const TableInput = (props) => {
	const { label, name, disabled, type, selected, labelClass, className, quantityValue } = props;

	const getValue = (field) => {
		let inputValue;
		let fieldValue = field.value;

		switch (type) {
			case 'number':
				inputValue = parseInt(fieldValue);
				break;
			default:
				inputValue = fieldValue;
		}

		return inputValue || '';
	};

	return (
		<td
			style={{ tabIndex: -1 }}
			className={`md:table-cell w-full md:w-auto flex flex-row p-0 relative border !border-secondary-3 mXin-w-[80px] ${!selected && 'hidden'}`}
		>
			<Field name={name}>
				{({ field, meta, form }) => {
					return (
						<>
							<label htmlFor={name} className={`${style.tableInputlabel} ${labelClass} md:hidden`}>
								{label} :
							</label>
							<input
								{...field}
								disabled={disabled}
								className={`${style.tableInput} ${className}`}
								value={getValue(field)}
								onChange={(e) => {
									let val = e.target.value;
									if (quantityValue) {
										const nm = field.name.replace('quantityPerVehicle', 'spoQuantity');
										form.setFieldValue(nm + 'Original', parseInt(quantityValue));
										form.setFieldValue(nm, parseInt(quantityValue) * val);
									}
									if (field.name.includes('spoQuantity')) {
										form.setFieldValue(field.name + 'Original', e.target.value / parseInt(quantityValue));
									}
									field.onChange(e);
								}}
								autoComplete='off'
							/>

							{meta.error && (
								<span className='flex items-censter absolute top-0 h-full right-0 text-red-600 dark:text-yellow-400 hiddden'>
									<Tippy arrow={true} content={<span className='bg-red-600 rounded px-2 py-1 shadow text-white'>{meta.error}</span>}>
										<ExclamationCircleIcon className='h-5 w-5 outline-none' />
									</Tippy>
								</span>
							)}
						</>
					);
				}}
			</Field>
		</td>
	);
};

export default TableInput;

TableInput.propTypes = {
	children: PropTypes.node,
	label: PropTypes.string,
	name: PropTypes.string,
	disabled: PropTypes.string,
	type: PropTypes.string,
	selected: PropTypes.string,
	labelClass: PropTypes.string,
	className: PropTypes.string,
	quantityValue: PropTypes.string
};
