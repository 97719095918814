import { getRequest } from '../axios';
import { globalActions } from './globalSlice';
import { profileActions } from './profileSlice';

export const getActiveProfile = () => {
	return async (dispatch) => {
        try {
            dispatch(globalActions.setLoading({ loading: true, label: 'Logging In...' }));
            const details = await fetch(process.env.REACT_APP_SERVER_URL + '/api/profile/', {
                credentials: "include",
                method: 'GET',
            });
            const json = await details.json();
            if (json.username) {
                dispatch(globalActions.setLoading({ loading: false }));
                dispatch(profileActions.updateLoginStatus(true));
                if (json.role === 'View') {
                    json.view = true;
                    dispatch(profileActions.replaceProfiles(json));
                }
                else {
                    dispatch(profileActions.replaceProfiles(json));
                }
            } else {
                window.location.replace(process.env.REACT_APP_SERVER_URL + '/saml2/login/');
            }
        } catch (error) {
            
        }
	};
};