import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SearchHeader from '../../components/ui/SearchHeader';
import IssueEmail from './IssueEmail';
import { getPirEmailData, resetPirIssue, resetPir, setPirNumberAction, resetEmailData } from '../../store/pirIssueActions';
import useToggle from '../../packages/_utils/useToggle';
import Button from '../../packages/button/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../packages/modal/Modal';

const Issue = () => {

  const profileData = useSelector((state) => state.profile.profilesData);
	if (!profileData.is_active) {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}

  const dispatch = useDispatch();
  const pir = useSelector((state) => state.pirIssue.data);
  const allData = useSelector((state) => state.pirRequest.allData);
  const [toggleFlag, flag] = useToggle();


  //#region Search Header
  const [searchValue, setSearchValue] = useState(pir?.pir ?? '');
  const [showSearch, setShowSearch] = useState(!pir?.pir);
  const [autoHide, setAutoHide] = useState(showSearch);
  const [toggle, isOpen] = useToggle();
  let pirNo;

  const checkNonExistingPir = (pirNo) => {
    const existingNo = allData.find(x => x.id === Number(pirNo));
    if (existingNo === undefined) return true;
    else return false;
  };

  const searchClickHandler = (e) => {
    setAutoHide(true);
    if (!e || e === '') {
      resetData();
    }
    else {
      resetData();
      if (checkNonExistingPir(e)) {
        toggle();
        return;
      }
      dispatch(getPirEmailData(e));
      dispatch(setPirNumberAction(e));
      pirNo = e;
      if (!flag) toggleFlag();
    }
  };

  const searchChangeHandler = (e) => {
    if (!e || e === '') {
      resetData();
      if(flag) toggleFlag();
    }
    setSearchValue(e);
  };

  const resetData = useCallback(() => {
    dispatch(resetPir());
    dispatch(resetPirIssue());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);


  useEffect(() => {
    if (autoHide) {
      setShowSearch(!(pir.id && searchValue !== ''));
    }
    if (!pir.id && !showSearch) {
      setShowSearch(true);
      setSearchValue('');
    }
  }, [pir, searchValue, autoHide, showSearch]);
  //#endregion Search Header

  return (
    <>
      <SearchHeader
        header="Product Inspection Request Details"
        label="PIR Number"
        onClick={searchClickHandler}
        onChange={searchChangeHandler}
        value={searchValue}
        isSearch={true}
        showSearch={showSearch}
        setShowSearch={e => { setShowSearch(e); setAutoHide(!autoHide); resetData();}}
      />
      {
        flag?
          <>
            <IssueEmail pirNumber={pirNo} />
          </> :
          <p className='text-xl mt-4 text-primary-1'>Please search for a PIR first</p>
      }
      <Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
				<ModalHeader>Incorrect PIR Number</ModalHeader>
				<ModalBody>
					<div className='d-flex flex-row bd-highlight mb-3'>
						<div className='p-2 bd-highlight'>
							<p>The PIR Number you searched for does not exist</p>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={toggle} color='danger' className='ignore-click-outside'>
						Close
					</Button>
				</ModalFooter>
			</Modal>
    </>
  );
};

export default Issue;