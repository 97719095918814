import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../packages/button/Button';
import { getData } from '../../store/sampleActions';

const Sample = () => {
	const data = useSelector((state) => state.sample.data);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getData());
	}, []);

	const setNewData = (data) => {
		dispatch(getData(data));
	};

	return (
		<>
			<h1 className='text-2xl font-semibold text-secondary-1 pb-2'>Sample</h1>
			<div>{data}</div>
			<Button onClick={() => setNewData('Something else has been set')}>Click Here</Button>
		</>
	);
};

export default Sample;
