import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useToggle from '../../packages/_utils/useToggle';
import Button from '../../packages/button/Button';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../packages/modal/Modal';

import { getUsers, updateUser, createUser, deleteUser } from '../../store/usersActions';

import { updateObject } from '../../utils/utility';
import Icon from '../../components/ui/Icon';

import { Role } from '../../data/roles';
import SearchInput from '../../components/ui/SearchInput';
import { tableStyles } from './Settings';
import SelectInput from '../../components/ui/SelectInput';
import { StatusPill } from '../../components/ui/Table';
import FormField from '../../packages/form/FormField';
import Toast from '../../components/ui/Toast';

const initialUser = {
	id: 0,
	username: '',
	first_name: '',
	last_name: '',
	email: '',
	Role: 'View',
	is_active: false,
};

const Users = () => {
	const dispatch = useDispatch();
	const profileData = useSelector((state) => state.profile.profilesData);
	const [toggle, isOpen] = useToggle();
	const currentUser = useSelector((state) => state.auth.user);
	const users = useSelector((state) => state.users.data);
	const [selectedUser, setSelectedUser] = useState(initialUser);
	const [searchValue, setSearchValue] = useState('');
	const [filteredList, setFilteredList] = useState(users);

	const rolesArray = [
		{ value: 'View', label: 'View' },
		{ value: 'User', label: 'User' },
		{ value: 'Admin', label: 'Admin' }
	];

	const statusPillOptions = ['View', 'User', 'Admin'];

	const [modalTitle, setModalTitle] = useState('');

	useEffect(() => {
		dispatch(getUsers());
	}, [dispatch]);

	const addClick = () => {
		setModalTitle('Create New User');
		toggle();
		setSelectedUser(initialUser);
	};

	const editClick = (user) => {
		setModalTitle('Edit User');
		setSelectedUser(user);
		toggle();
	};

	const createClick = () => {

		const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
		const checkEmail = selectedUser.email;

		if (!emailRegex.test(checkEmail)) {
			Toast({ status: "warning", message: 'Invalid Email' });
			return;
		}
		
		if (selectedUser.username === "" || selectedUser.first_name === "" || selectedUser.last_name === "" || selectedUser.email === "" || selectedUser.Role === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

		const existingUserName = users.find(x => x.username === selectedUser.username);
		const existingEmail = users.find(x => x.email === selectedUser.email);
		
		if (existingUserName) {
			Toast({ status: "warning", message: 'Existing Username' });
			return;
		}

		if (existingEmail) {
			Toast({ status: "warning", message: 'Existing Email' });
			return;
		}

		const newUser = {
			username: selectedUser.username,
			first_name: selectedUser.first_name,
			last_name: selectedUser.last_name,
			email: selectedUser.email,
			Role: selectedUser.Role
		};
		dispatch(createUser(newUser));
		toggle();
		setSearchValue(selectedUser.username);
	};

	const updateClick = () => {

		const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
		const checkEmail = selectedUser.email;

		if (!emailRegex.test(checkEmail)) {
			Toast({ status: "warning", message: 'Invalid Email' });
			return;
		}

		if (selectedUser.username === "" || selectedUser.first_name === "" || selectedUser.last_name === "" || selectedUser.email === "" || selectedUser.Role === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

		const newUser = {
			username: selectedUser.username,
			first_name: selectedUser.first_name,
			last_name: selectedUser.last_name,
			id: selectedUser.id,
			email: selectedUser.email,
			Role: selectedUser.Role,
			is_active: selectedUser.is_active
		};
		dispatch(updateUser(newUser));
		toggle();
		setSearchValue(selectedUser.username);
	};

	const deleteClick = (id) => {
		dispatch(deleteUser(id));
	};

	const onChangeHandler = (e, field) => {
		let updatedUser = updateObject(selectedUser, {
			[field]: e.target.value
		});
		setSelectedUser(updatedUser);
	};

	const onRoleChangeHandler = (value, field) => {
		let updatedRole = updateObject(selectedUser, {
		  [field]: value,
		});
		setSelectedUser(updatedRole);
	};

	const onActiveChangeHandler = (e, field) => {
		const value = e.target.checked;
		let updatedRole = updateObject(selectedUser, {
		  [field]: value,
		});
		setSelectedUser(updatedRole);
	};

	const searchClickHandler = (e) => {};

	const searchChangeHandler = (e) => {
		setSearchValue(e);
		setFilteredList(filterList(e));
	};

	// Filter
	const filterList = useCallback(
		(value) => {
			if (!value) return users;
			return users.filter(
				(c) =>
					c.username?.toLowerCase().includes(value.toLowerCase()) ||
					c.first_name?.toLowerCase().includes(value.toLowerCase()) ||
					c.last_name?.toLowerCase().includes(value.toLowerCase()) ||
					c.email?.toLowerCase().includes(value.toLowerCase()) ||
					c.Role?.toLowerCase().includes(value.toLowerCase())
			);
		},
		[users]
	);

	useEffect(() => {
		setFilteredList(filterList(searchValue));
	}, [filterList, searchValue]);
	// Endregion

	return (
		<div className=''>
			<div className='flex fle justify-between items-center'>
				<div className='flex'>
					<SearchInput
						label={'Filter'}
						// icon={<SearchIcon />}
						className='w-40 shadow-sm ml-4 dark:text-primary-2'
						iconPosition='right'
						onClick={searchClickHandler}
						onChange={searchChangeHandler}
						value={searchValue}
						placeholder={`Start typing to filter list`}
					/>
				</div>
				<p className='text-left w-full pl-4'>{`${filteredList.length} User${filteredList.length > 1 ? 's' : ''} ${
					filteredList.length < users.length ? 'Filtered' : ''
				}`}</p>
				{currentUser.role !== Role.view && (
					<div className='flex-none'>
						<Button onClick={addClick} color='primary' disabled={profileData.role !== 'Admin'}>
							Add User
						</Button>
					</div>
				)}
			</div>

			<table className={tableStyles.table}>
				<thead className={tableStyles.thead.base}>
					<tr>
						<th>User Name</th>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Email</th>
						<th>Role</th>
						<th>Active</th>
						{currentUser.role !== Role.view && <th className={tableStyles.thead.th.actions}>Actions</th>}
					</tr>
				</thead>
				<tbody>
					{filteredList &&
						filteredList.map((user, i) => (
							<tr key={i} className={tableStyles.tbody.tr}>
								<td>{user.username}</td>
								<td>{user.first_name}</td>
								<td>{user.last_name}</td>
								<td>{user.email}</td>
								<td>
									<StatusPill value={user.Role} options={statusPillOptions} />{" "}
								</td>
								<td>
									<FormField
										name="userActive"
										type="checkbox"
										disabled
										checked={user.is_active}
										onClick={(e) => onChangeHandler(e, "is_active")}
									/>
								</td>
								{currentUser.role !== Role.view && (
									<td className={tableStyles.tbody.td.actions}>
										<Button color='primary' className='btn mr-1 ' size='sm' onClick={() => editClick(user)}
										disabled={profileData.role !== 'Admin'}>
											<Icon iconName='Pencil' />
										</Button>
										<Button color='danger' className='btn mr-1' size='sm' onClick={() => deleteClick(user.id)}
										disabled={profileData.role !== 'Admin'}>
											<Icon iconName='Trash' />
										</Button>
									</td>
								)}
							</tr>
						))}
				</tbody>
			</table>

			<Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
				<ModalHeader>{modalTitle}</ModalHeader>
				<ModalBody>
					<div className='d-flex flex-row bd-highlight mb-3'>
						<div className='p-2 w-96 bd-highlight'>
							{modalTitle!=='Edit User' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>User Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedUser.username ?? ''}
									onChange={(e) => onChangeHandler(e, 'username')}
								/>
							</div>}
							{modalTitle==='Edit User' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>User Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedUser.username ?? ''}
									onChange={(e) => onChangeHandler(e, 'username')}
									disabled={true}
								/>
							</div>}

							<div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>First Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedUser.first_name ?? ''}
									onChange={(e) => onChangeHandler(e, 'first_name')}
								/>
							</div>

							<div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>Last Name</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedUser.last_name ?? ''}
									onChange={(e) => onChangeHandler(e, 'last_name')}
								/>
							</div>

							{modalTitle!=='Edit User' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>Email</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedUser.email ?? ''}
									onChange={(e) => onChangeHandler(e, 'email')}
								/>
							</div>}
							{modalTitle==='Edit User' && <div className='input-group mb-3 grid grid-cols-3'>
								<span className='input-group-text'>Email</span>
								<input
									type='text'
									className='form-control !w-full col-span-2'
									value={selectedUser.email ?? ''}
									onChange={(e) => onChangeHandler(e, 'email')}
									disabled={true}
								/>
							</div>}

							<div className={`flefx flexd-col w-full text-sm items-center mb-4`}>
								<SelectInput
									className="border rounded ml-4"
									name={"Role"}
									value={selectedUser.Role}
									label={"Role"}
									options={rolesArray}
									onChangeHandler={(e) => onRoleChangeHandler(e, "Role")}
								/>
							</div>
							{modalTitle==='Edit User' && <div>
								<FormField
									name='is_active'
									type='checkbox'
									checked={selectedUser.is_active}
									onChange={(e) => onActiveChangeHandler(e, 'is_active')}
								/>
							</div>}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button onClick={toggle} color='danger'>
						Cancel
					</Button>
					{selectedUser.id === 0 ? (
						<Button onClick={createClick} color='primary' className='mr-1'>
							Create
						</Button>
					) : null}

					{selectedUser.id !== 0 ? (
						<Button onClick={updateClick} color='primary' className='mr-1'>
							Update
						</Button>
					) : null}
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default Users;
