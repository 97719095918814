import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Formik, Form } from "formik";
import FormikControl from "../../../components/ui/Formik/FormikControl";

import Button from "../../../packages/button/Button";

import { ArrowLeftIcon } from "@heroicons/react/solid";

import FormField from '../../../packages/form/FormField';
import { useNavigate } from 'react-router-dom';

const PartsDetails = () => {

    const selectedLocationId = useSelector((state) => state.pirInspection.selectedLocationId);
    const data = useSelector((state) => state.pirInspection.selectedInspection);
    const [formData, setFormData] = useState(data);
    const [showRejection, setShowRejection] = useState(false);
    const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
    const navigate = useNavigate();

    if (formData === undefined) {
        var tempData = {};
        let LocationLabel = dropdownsData.Locations.find(x => x.id === selectedLocationId).Name;
        tempData.LocationLabel = LocationLabel;
        Object.preventExtensions(tempData);
    }

    else if (dropdownsData.Locations.length > 0) {
        var tempData = JSON.parse(JSON.stringify(formData));
        let StatusLabel = '';
        let WaitingLabel = '';
        let UserLabel = '';
        let LocationLabel = '';
        if (formData.StatusId) StatusLabel = dropdownsData.Status.find(x => x.id === formData.StatusId).Name;
        if (formData.WaitingId) WaitingLabel = dropdownsData.Waiting.find(x => x.id === formData.WaitingId).Name;
        if (formData.InspectorId) UserLabel = dropdownsData.Users.find(x => x.id === formData.InspectorId).first_name + ' ' + dropdownsData.Users.find(x => x.id === formData.InspectorId).last_name;
        if (formData.Rejection.length > 0 && formData.Rejection[0].CodeId) {
            const RejectionLabel = dropdownsData.RejectCode.find(x => x.id === formData.Rejection[0].CodeId).Name;
            tempData.RejectionLabel = RejectionLabel;
        }
        LocationLabel = dropdownsData.Locations.find(x => x.id === selectedLocationId).Name;
        tempData.LocationLabel = LocationLabel;
        tempData.StatusLabel = StatusLabel;
        tempData.WaitingLabel = WaitingLabel;
        tempData.UserLabel = UserLabel;
        Object.preventExtensions(tempData);
    }

    useEffect(() => {
        setFormData(data);
    }, [data]);

    return (
    <>
      <span style={{fontSize: '25px', fontWeight: 'bold'}}>Inspection Report for {tempData.LocationLabel}</span>
      <br /> <br /> <hr />
      <Formik
        initialValues={tempData}
        enableReinitialize
        id="existingInspectionDetails"
      >
        {({ submitForm, errors, values, resetForm, setFieldTouched }) => {
          return (
            <>
            <Form>
                <div className="md:flex px-4 py-4 bg-tertiary-cool-4 md:p-6">
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
                        <FormikControl
                            className='pb-4 !col-span-2 sm:!col-span-1 xl:!col-span-2 2xl:!col-span-1 min-w-fift'
                            control='input'
                            disabled={true}
                            type='text'
                            label='PIR Id'
                            name='RequestHeaderId'
                        />
                        <FormikControl
                            className='pb-4 !col-span-2 sm:!col-span-1 xl:!col-span-2 2xl:!col-span-1 min-w-fift'
                            control='input'
                            disabled={true}
                            type='text'
                            label='Inspection Id'
                            name='id'
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Location'
                            name='LocationLabel'
                            disabled={true}
                        />
                        <FormikControl
                            className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
                            control='input'
                            type='text'
                            label='Inspection Report'
                            name='Report'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Phone'
                            name='Phone'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Stock Sample Correct'
                            name='StockSampleCorrect'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Complaint Justified'
                            name='ComplaintJustified'
                            disabled={true}
                        />
                        <FormikControl
                            className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
                            control='input'
                            type='text'
                            label='Waiting Comment'
                            name='WaitingLabel'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Status'
                            name='StatusLabel'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Inspection Completed by'
                            name='UserLabel'
                            disabled={true}
                        />
                        <FormikControl
                            className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
                            control='date'
                            type='date'
                            label='Audit DateTime'
                            disabled={true}
                            name='AuditDateTime'
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='DateCode Checked'
                            name='DateCodeChecked'
                            disabled={true}
                        />
                        <span style={{ color: '#525252', fontSize: '14px', fontWeight: 'bold'}}>Stock Rejection</span>
                        <FormField
                            // label='Stock Rejection'
                            style={{ marginLeft: '40px' }}
                            name='showRejection'
                            type='checkbox'
                            checked={showRejection}
                            onClick={() => setShowRejection(!showRejection)}
                        />
                    </div>
                </div>
            </Form>

            <Form>
                {showRejection && <div className="md:flex px-4 py-4 bg-tertiary-cool-4 md:p-6">
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
                        <FormikControl
                            control='input'
                            type='text'
                            label='Rejection Quantity'
                            name='Rejection[0].Quantity'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Rejection Stock Type'
                            name='Rejection[0].StockType'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Rejection RMR'
                            name='Rejection[0].RMR'
                            disabled={true}
                        />
                        <FormikControl
                            className="pb-4 !col-span-4 sm:!col-span-3 lg:!col-span-4 xl:!col-span-4 min-w-fsit"
                            control='input'
                            type='text'
                            label='Rejection Reason'
                            name='RejectionLabel'
                            disabled={true}
                        />
                        <FormikControl
                            control='input'
                            type='text'
                            label='Date Code'
                            name='Rejection[0].DateCode'
                            disabled={true}
                        />
                    </div>
                </div>}
            </Form>
              
            <hr />
            <div className="grid grid-cols-3 gap-4px-4 py-3 bg-white text-left md:px-6 rounded-b">
                <Button
                    type="button"
                    onClick={() => {
                        navigate(`/pir/view/${tempData.RequestHeaderId}`);
                    }}
                    className={`bg-tertiary-cool-2 col-start-1 place-self-start flex items-center`}
                    >
                    <ArrowLeftIcon className="h-4 mr-2" />
                    PIR Details
                </Button>
            </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default PartsDetails;