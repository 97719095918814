import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateObject } from "../../utils/utility";
import useToggle from "../../packages/_utils/useToggle";
import {
  getVehiclePlatforms,
  createVehiclePlatform,
  updateVehiclePlatform,
  deleteVehiclePlatform,
} from "../../store/vehiclePlatformActions";
import Button from "../../packages/button/Button";
import Icon from "../../components/ui/Icon";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";
import { tableStyles } from "./Settings";
import SelectInput from "../../components/ui/SelectInput";

import { Role } from "../../data/roles";
import Toast from "../../components/ui/Toast";

const initialVehiclePlatform = {
  id: 0,
  Name: "",
  Description: "",
};

const VehiclePlatform = () => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.profile.profilesData);
  const currentUser = useSelector((state) => state.auth.user);

  const vehiclePlatforms = useSelector((state) => state.vehiclePlatform.data);

  var tempData = JSON.parse(JSON.stringify(vehiclePlatforms));
	Object.preventExtensions(tempData);

  useEffect(() => {
    dispatch(getVehiclePlatforms());
  }, [dispatch]);

  const [toggle, isOpen] = useToggle();
  const [modalTitle, setModalTitle] = useState("");
  const [selectedVehiclePlatform, setSelectedVehiclePlatform] =
    useState(initialVehiclePlatform);

  const addClick = () => {
    setModalTitle("Add Vehicle Platform");
    toggle();
    setSelectedVehiclePlatform(initialVehiclePlatform);
  };

  const createClick = () => {

    if (selectedVehiclePlatform.Name === "" || selectedVehiclePlatform.Description === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

    const vehiclePlatform = {
      Name: selectedVehiclePlatform["Name"],
      Description: selectedVehiclePlatform["Description"],
    };
    dispatch(createVehiclePlatform(vehiclePlatform));
    toggle();
  };

  const updateClick = () => {

    if (selectedVehiclePlatform.Name === "" || selectedVehiclePlatform.Description === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

    const vehiclePlatform = {
      id: selectedVehiclePlatform["id"],
      Name: selectedVehiclePlatform["Name"],
      Description: selectedVehiclePlatform["Description"],
    };
    dispatch(updateVehiclePlatform(vehiclePlatform));
    toggle();
  };

  const editClick = (vehiclePlatform) => {
    setModalTitle("Edit Vehicle Platform");
    setSelectedVehiclePlatform(vehiclePlatform);
    toggle();
  };

  const deleteClick = (id) => {
    dispatch(deleteVehiclePlatform(id));
  };

  const onChangeHandler = (e, field) => {
    let updatedVehiclePlatform = updateObject(selectedVehiclePlatform, {
      [field]: e.target.value,
    });
    setSelectedVehiclePlatform(updatedVehiclePlatform);
  };

  return (
    <div className="">
      <div className="flex fle justify-between items-center w-1/2">
        <p className="text-left w-full">{`${tempData.length} Vehicle Platform${
          tempData.length > 1 ? "s" : ""}`}</p>
        {currentUser.role !== Role.view && (
          <div className="flex-none">
            <Button onClick={addClick} color="primary" disabled={profileData.role === 'View'}>
              Add to Vehicle Platform
            </Button>
          </div>
        )}
      </div>

      <table className={`${tableStyles.table} w-1/2`}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>Model</th>
            <th>Description</th>
            {currentUser.role !== Role.view && (
              <th className={tableStyles.thead.th.actions}>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {tempData &&
            tempData.map((m, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{m.Name}</td>
                <td>{m.Description}</td>
                {currentUser.role !== Role.view && (
                  <td className={tableStyles.tbody.td.actions}>
                    <Button
                      color="primary"
                      className="btn mr-1"
                      size="sm"
                      onClick={() => editClick(m)}
                      disabled={profileData.role === 'View'}
                    >
                      <Icon iconName="Pencil" />
                    </Button>
                    <Button
                      color="danger"
                      className="btn mr-1"
                      size="sm"
                      onClick={() => deleteClick(m.id)}
                      disabled={profileData.role !== 'Admin'}
                    >
                      <Icon iconName="Trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        animate={true}
        closeOnClickOutside={true}
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 w-full bd-highlight">
              <div className="input-group">
                <div className="input-group mb-3">
                    <span className="input-group-text">Model</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedVehiclePlatform.Name}
                    onChange={(e) => onChangeHandler(e, "Name")}
                    />
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text">Description</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedVehiclePlatform.Description}
                    onChange={(e) => onChangeHandler(e, "Description")}
                    />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
          {selectedVehiclePlatform.id === 0 ? (
            <Button onClick={createClick} color="primary" className="mr-1">
              Add
            </Button>
          ) : null}
          {selectedVehiclePlatform.id !== 0 ? (
            <Button onClick={updateClick} color="primary" className="mr-1">
              Update
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default VehiclePlatform;
