import { createSlice, current } from '@reduxjs/toolkit';

import { getRandomNumber } from '../utils/utility';

import _ from 'underscore';

export const initialValues = {
	id: null,
	Timestamp: null,
	Product: null,
	KPI: null,
	EmailAddress: null,
	DistributionCentre: null,
	RetailerCode: null,
	DateCode: null,
	Phone: null,
	Contact: null,
	Description: null,
	status: [],
	vehiclePlatforms: [],
	Urls: [],
	requestComments: [],
};

const initialFormOptions = {
	users: [],
	markets: [],
	spoType: [],
	spoStatus: [
		{ value: 1, label: 'Closed' },
		{ value: 2, label: 'Open' }
	],
	vehiclePlatforms: []
};

const pirRequestSlice = createSlice({
	name: 'pirRequest',
	initialState: {
		allData: [],
		latestRecord: null,
		allRecordCount: 0,
		allRecordNext: null,
		allRecordPrevious: null,
		data: initialValues,
		formOptions: initialFormOptions,
		changed: false,
		uploaded: false,
		uploading: false,
		loading: true,
		searchValue: null,
		existingSpo: null,
		existingPir: null,
		isDrawerOpen: false,
		pirDetailsById: null,
	},
	reducers: {
		replaceFormOptions: (state, action) => {
			state.formOptions = action.payload.formOptions ?? initialFormOptions;
		},
		setSearchValue: (state, action) => {
			state.searchValue = action.payload;
		},
		setUploading: (state, action) => {
			state.uploading = action.payload ?? false;
		},
		resetUploaded: (state) => {
			state.uploaded = false;
		},
		resetChanged: (state) => {
			state.changed = false;
		},
		existingSpo: (state, action) => {
			state.existingSpo = action.payload;
		},
		resetPirRequestData: (state, action) => {
			state.data = initialValues;
			state.changed = false;
			state.loading = false;
			// state.uploaded = false;
			// state.uploading = false;
		},
		setError: (state) => {
			state.error = true;
		},
		// data
		replaceSpoData: (state, action) => {
			state.data = action.payload?.data ?? initialValues;
			if (action.payload?.uploaded) state.uploaded = action.payload?.uploaded;
			if (!action.payload) state.uploading = false;
			state.changed = true;
			state.error = false;
		},
		updateField: (state, action) => {
			const currentData = current(state.data);
			const newData = { ...currentData, [action.payload.field]: action.payload.value };
			state.data = newData;
		},
		updateSpoData: (state, action) => {
			state.data = action.payload;
			state.changed = true;
			state.error = false;
		},
		// all data
		replacePirRequestAllData: (state, action) => {
			const data = action.payload?.allData ?? [];
			state.allData = _.sortBy(data, 'initialSpoDate').reverse();
			state.changed = true;
			state.error = false;
		},
		removePirAllData: (state, action) => {
			const id = action.payload;
			var data = state.allData.filter((d) => d.id !== id);
			// state.allData = _.sortBy(data, 'initialPirDate').reverse();
			state.allData = data;
			state.changed = true;
			state.error = false;
		},
		updateSpoAllData: (state, action) => {
			const data = action.payload.allData;
			const updatedData = [...state.allData.filter((d) => d.id !== data.id), data];
			state.allData = _.sortBy(updatedData, 'initialSpoDate').reverse();
			state.changed = true;
			state.error = false;
			state.uploaded = action.payload?.uploaded ?? false;
		},
		latestRecord: (state, action) => {
			state.latestRecord = action.payload;
		},
		updateAllRecordCount: (state, action) => {
			state.allRecordCount = action.payload;
		},
		updateAllRecordNext: (state, action) => {
			state.allRecordNext = action.payload;
		},
		updateAllRecordPrevious: (state, action) => {
			state.allRecordPrevious = action.payload;
		},
		existingPirNumber: (state, action) => {
			state.existingPir = action.payload;
		},
		toggleDrawer: (state, action) => {
			state.isDrawerOpen = action.payload;
		},
		updatePirById: (state, action) => {
			state.pirDetailsById = action.payload;
		},
	}
});

export const pirRequestActions = pirRequestSlice.actions;

export default pirRequestSlice;
