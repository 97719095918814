import { createSlice } from "@reduxjs/toolkit";

export const initialState = '';

const pirIssueSlice = createSlice({
  name: "pirIssue",
  initialState: {
		data: initialState
	},
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    resetPirIssue(state, action) {
      state.data = '';
      state.loading = false;
    },
    replacePirIssue(state, action) {
      state.data = action.payload;
    },
    resetPir(state, action) {
      state.data = initialState;
      state.changed = false;
      state.loading = false;
    },
    setPirNumber(state, action) {
      state.storedPir = action.payload;
    }
  },
});

export const pirIssueActions = pirIssueSlice.actions;

export default pirIssueSlice;
