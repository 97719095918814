import { pirRequestActions } from './pirRequestSlice';
import axios, { deleteRequest, getRequest, postRequest, putRequest } from '../axios';
import Toast from '../components/ui/Toast';
import { globalActions } from './globalSlice';
// import { data } from "autoprefixer";
import fileDownload from 'js-file-download';
// import { useSelector } from 'react-redux';

// const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);

export const resetChanged = (value) => {
	return (dispatch) => {
		dispatch(pirRequestActions.resetChanged());
	};
};

export const setSearchValue = (value) => {
	return (dispatch) => {
		dispatch(pirRequestActions.setSearchValue(value));
	};
};

export const resetPirRequestData = () => {
	return (dispatch) => {
		dispatch(pirRequestActions.resetPirRequestData());
	};
};

export const resetAllPirRequestData = () => {
	return (dispatch) => {
		dispatch(pirRequestActions.setSearchValue());
		dispatch(pirRequestActions.replacePirRequestAllData());
	};
};


export const toggleDrawer = (data) => {
	return async dispatch => {
		dispatch(pirRequestActions.toggleDrawer(data));
	};
};

export const resetUploaded = () => {
	return (dispatch) => {
		dispatch(pirRequestActions.resetUploaded());
	};
};

export const updateField = (field, value) => {
	return (dispatch) => {
		dispatch(pirRequestActions.updateSpoData({ field: field, value: value }));
	};
};

export const resetCheckForExisting = () => {
	return (dispatch) => {
		dispatch(pirRequestActions.existingSpo());
	};
};

export const checkForExisting = (pir) => {
	return async (dispatch) => {
		try {
			const existing = await getRequest('pir/existing/' + pir);
			dispatch(pirRequestActions.existingSpo(existing));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const completeData = (dropdowns, data) => {
	const dropdownsData = dropdowns;
	var tempData = JSON.parse(JSON.stringify(data));
	Object.preventExtensions(tempData);
	if (dropdownsData.Users.length > 0) {
		
		for (let index = 0; index < tempData.length; index++) {
			const element = tempData[index];

			// User Name
			if (element.AuditUserId_id) {
				tempData[index].UserId = dropdownsData.Users.find(x => x.id === element.AuditUserId_id)
				.first_name + ' ' + dropdownsData.Users.find(x => x.id === element.AuditUserId_id).last_name;
			}

			// Locations
			if (element.Locations.length > 0) {
				let LocationLabel = '';
				element.Locations.forEach(loc => {
					const pushData = dropdownsData.Locations.find(x => x.id === loc.PirLocationId).Name;
					if (LocationLabel === '') LocationLabel =  pushData;
					else LocationLabel = LocationLabel + ', ' + pushData;
					tempData[index].LocationLabel = LocationLabel;
				});
			}

			//Status
			let FinalLabel;

			if (element.RequestHeaderId.length === 0) {
				FinalLabel = 'New';
			} else {
				let allCancelled = element.RequestHeaderId.every(item => item.StatusId === 3);
				let hasNull = element.RequestHeaderId.some(item => item.StatusId === null);
				let hasInProgress = element.RequestHeaderId.some(item => item.StatusId === 1);

				if (allCancelled) {
					FinalLabel = 'Cancelled';
				} else if (hasNull) {
					FinalLabel = 'New';
				} else if (hasInProgress && !hasNull) {
					FinalLabel = 'In Progress';
				} else {
					FinalLabel = 'Closed';
				}
			}
			if (element.Locations.length > element.RequestHeaderId.length) FinalLabel = 'New';
			tempData[index].Status = FinalLabel;

			// Close Date
			var LatestDate = '';
			if (element.RequestHeaderId.length > 0 && (tempData[index].Status === 'Closed' || tempData[index].Status === 'Cancelled')) {
				tempData[index].CloseDate = element.RequestHeaderId[0].AuditDateTime;
				for (var i = 0; i < element.RequestHeaderId.length; i++) {
					var currentDateTime = new Date(element.RequestHeaderId[i].AuditDateTime);
					if (LatestDate === '' || currentDateTime > new Date(LatestDate)) {
						LatestDate = element.RequestHeaderId[i].AuditDateTime;
					}
				}
				tempData[index].CloseDate = LatestDate;
			}
			else {
				tempData[index].CloseDate = '';
			}

			// KPI Calculations
			let diffInHours = '0h 0m';
			if (tempData[index].RequestHeaderId.length === 0) {
				const date = new Date(tempData[index].AuditDateTime);
				const now = new Date();
				const diffInMilliseconds = now.getTime() - date.getTime();
				diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
			}
			else {
				const statusCheck = tempData[index].Status;
				if (statusCheck === 'In Progress' || statusCheck === 'New') {
					const date = new Date(tempData[index].AuditDateTime);
					const now = new Date();
					const diffInMilliseconds = now.getTime() - date.getTime();
					diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
				}
				else {
					const auditDate = new Date(tempData[index].AuditDateTime);
					const closingDate = new Date(LatestDate);
					const diffInMilliseconds = closingDate.getTime() - auditDate.getTime();
					diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
				}
			}

			const hours = Math.floor(diffInHours);
			const minutes = Math.round((diffInHours - hours) * 60);
			const formattedKpi = hours.toString() + 'h ' + minutes.toString() + 'm';

			tempData[index].Kpi = formattedKpi;

		}

		return tempData;

	}
};

export const getFormOptions = () => {
	return async (dispatch) => {
		try {
			const data = await getRequest('pir/formoptions');
			dispatch(pirRequestActions.replaceFormOptions({ formOptions: data }));
		} catch (error) {
			dispatch(globalActions.setLoading({ loading: false }));
			if (error.message.substring(0, 2) !== '40') Toast({ status: 'error', message: error.message });
		}
	};
};

export const getAllPirRequestData = () => {
	const pageSize = 1000000;
	return async (dispatch) => {
		try {
			dispatch(globalActions.setLoading({ loading: true, label: 'Loading...' }));
			const details = await getRequest('dropdown');
			// const pirRequestData = await getRequest('alldata/?months=6&page_size='+pageSize);
			const pirRequestData = await getRequest('pirdatabaseview/');
			dispatch(globalActions.setLoading({ loading: false }));
			dispatch(pirRequestActions.updateAllRecordCount(pirRequestData.results.length));
			dispatch(pirRequestActions.updateAllRecordNext(pirRequestData.next));
			dispatch(pirRequestActions.updateAllRecordPrevious(pirRequestData.previous));
			dispatch(pirRequestActions.replacePirRequestAllData({ allData: completeData(details, pirRequestData.results) || [] }));
		} catch (error) {
			dispatch(globalActions.setLoading({ loading: false }));
			if (error.message.substring(0, 2) !== '40') Toast({ status: 'error', message: error.message });
		}
	};
};

export const getLatestRecord = () => {
	const pageSize = 1;
	return async (dispatch) => {
		try {
			const pirRequestData = await getRequest('pirrequest/?page_size='+pageSize);
			dispatch(pirRequestActions.latestRecord(pirRequestData));
		} catch (error) {
			dispatch(globalActions.setLoading({ loading: false }));
			if (error.message.substring(0, 2) !== '40') Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateSpo = (pir, isUpload) => {
	return async (dispatch) => {
		try {
			dispatch(globalActions.setLoading({ loading: true, label: `Updating PIR ${pir.pir}` }));
			const data = await putRequest('pir', pir);
			Toast({ status: 'success', message: 'PIR Updated' });
			dispatch(pirRequestActions.updateSpoAllData({ allData: data, uploaded: isUpload }));
			dispatch(pirRequestActions.updateSpoData(data));
		} catch (error) {
			dispatch(pirRequestActions.setError());
			dispatch(globalActions.setLoading({ loading: false }));
			console.error('updateSpo: ' + error);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deletePirRequest = (id, pir) => {
	return async (dispatch) => {
		try {
			dispatch(setSearchValue());
			const response = await deleteRequest(`pirrequest/${id}/`);
			Toast({ status: 'success', message: response.message });
			dispatch(pirRequestActions.removePirAllData(id));
			dispatch(setSearchValue(pir));
		} catch (error) {
			console.error('Delete User Error: ' + error.message);
			Toast({ status: 'error', message: 'Error!' });
		}
	};
};

export const createPirRequest = (data) => {
	return async dispatch => {
		try {
		const response = await postRequest('pirrequest/', data);
		console.log('create pir request response', response);
		Toast({ status: "success", message: 'PIR Created!' });
		dispatch(pirRequestActions.updateSpoData(response));
		} catch (error) {
		Toast({ status: "error", message: error.message });
		}
	};
};

export const checkExistingPir = (num) => {
	return async (dispatch) => {
		try {
		const details = await getRequest('pirrequest/?Product=' + num);
		console.log('filterpirdetail product', details);
		if (details !== []) {
			console.log('dispatching...');
			dispatch(pirRequestActions.existingPirNumber(details[0].id));
		}
		} catch (error) {
		Toast({ status: "error", message: 'Error in checking existing PIR' });
		}
	};
};

export const getPirDetailsById = (id) => {
	return async (dispatch) => {
		try {
		const details = await getRequest('pirrequest/' + id);
			dispatch(pirRequestActions.updatePirById(details));
		} catch (error) {
			Toast({ status: "error", message: 'Error in getting PIR Details' });
		}
	};
};

export const email = (type) => {
	let url = '';

	if (type === 'pdf') url = 'pir/sendgridpdf';
	if (type === 'excel') url = 'pir/sendgrid';

	return async (dispatch) => {
		const sendRequest = async () => {
			Toast({ status: 'info', message: `${url}` });
			return axios
				.get(url)
				.then((response) => {
					if (response.status === 200) {
						Toast({ status: 'success', message: `${response.data}` });
						return response.data;
					}
					throw new Error(response.data.message);
				})
				.catch((error) => {
					throw new Error(error.message);
				});
		};

		try {
			await sendRequest();
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const download = (type, pir) => {
	const url = `pir/downloadspo${type}/${pir.id}`;

	return async (dispatch) => {
		try {
			const response = await getRequest(url, { responseType: 'blob' });
			if (response) {
				if (type === 'pdf') {
					const blob = new Blob([response], { type: 'application/pdf' });
					fileDownload(blob, `PIR ${pir.pir}.pdf`);
				} else {
					const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
					if (!pir?.spoVersion) fileDownload(blob, `PIR ${pir.pir}.xlsx`);
					else fileDownload(blob, `PIR ${pir.pir}_${pir.spoVersion.versionName}.xlsx`);
				}
				if (!pir?.spoVersion) Toast({ status: 'success', message: `PIR ${pir.pir} Downloaded` });
				else Toast({ status: 'success', message: `PIR ${pir.pir}_${pir.spoVersion.versionName} Downloaded` });
			}
		} catch (error) {
			console.error('download: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
