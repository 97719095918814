import useLocalStorage from '../../packages/_utils/useLocalStorage';
import FormField from '../../packages/form/FormField';
import { tableStyles } from './Settings';
import { useContext, useEffect, useState } from 'react';

import { ThemeContext } from '../../components/layout/ThemeProvider';
import { useDispatch, useSelector } from 'react-redux';
import { globalActions } from '../../store/globalSlice';

const configItems = [
	{
		id: 0,
		name: 'Auto Hide Show Search',
		state: 'localShowSearch',
		method: 'showSearch'
	},
	{
		id: 1,
		name: 'Dark Mode',
		state: 'isDarkTheme',
		method: 'toggleTheme'
	},
	{
		id: 2,
		name: 'Side Navigation Overlay',
		state: 'viewState === "overlay"',
		method: 'toggleViewState'
	},
	{
		id: 3,
		name: 'Side Navigation Peek',
		state: 'sideNavPeek',
		method: 'toggleSideNavPeek'
	}
];

const Config = () => {
	const [localShowSearch, setLocalShowSearch] = useLocalStorage('show-search');

	const showSearch = (e) => setLocalShowSearch(!localShowSearch); // eslint-disable-line no-unused-vars

	//#region Theme
	const { theme, setTheme } = useContext(ThemeContext);
	const toggleTheme = (e) => setTheme(theme === 'light' ? 'dark' : 'light'); // eslint-disable-line no-unused-vars
	const [isDarkTheme, setIsDarkTheme] = useState(theme === 'dark'); // eslint-disable-line no-unused-vars

	useEffect(() => {
		setIsDarkTheme(theme === 'dark');
	}, [theme]);
	//#endregion

	//#region View State
	const dispatch = useDispatch();
	const viewState = useSelector((state) => state.global.viewState);
	const [, setLocalViewState] = useLocalStorage('view-state', 'overlay'); // eslint-disable-line no-unused-vars

	// eslint-disable-next-line no-unused-vars
	const toggleViewState = (e) => {
		const updatedViewState = viewState === 'push' ? 'overlay' : 'push';
		dispatch(globalActions.setViewState(updatedViewState));
		setLocalViewState(updatedViewState);
	};
	//#endregion

	//#region Side Nav Peek
	const sideNavPeek = useSelector((state) => state.global.sideNavPeek);
	const [, setLocalSideNavPeek] = useLocalStorage('side-bar-peek', true); // eslint-disable-line no-unused-vars

	// eslint-disable-next-line no-unused-vars
	const toggleSideNavPeek = (e) => {
		dispatch(globalActions.setSideNavPeek(!sideNavPeek));
		setLocalSideNavPeek(!sideNavPeek);
	};
	//#endregion

	return (
		<>
			<table className={`${tableStyles.table} w-1/2`}>
				<thead className={tableStyles.thead.base}>
					<tr>
						<th>Name</th>
						<th className={tableStyles.thead.th.actions}>Actions</th>
					</tr>
				</thead>
				<tbody>
					{configItems &&
						configItems.map((config, i) => (
							<tr key={i} className={tableStyles.tbody.tr}>
								<td>{config.name}</td>
								<td className={tableStyles.tbody.td.actions}>
									<div className='flex justify-end'>
										<FormField
											name='showSearch'
											type='checkbox'
											checked={eval(config.state) /* eslint-disable-line no-eval */}
											onClick={() => eval(config.method).call() /* eslint-disable-line no-eval */}
										/>
									</div>
								</td>
							</tr>
						))}
				</tbody>
			</table>
		</>
	);
};

export default Config;
