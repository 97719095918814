import { createSlice } from '@reduxjs/toolkit';
import produce from 'immer';
import { sortComments } from './pirCommentsActions';

const initialValues = {
	spoComment: {},
	headerComments: [],
	detailComments: [],
	loading: false,
	replyLoading: false,
	token: null,
	loaded: null,
	allDataComments: [],
	refreshCommentsData: true
};
const pirCommentsSlice = createSlice({
	name: 'pirComments',
	initialState: initialValues,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload ?? false;
		},
		setReplyLoading: (state, action) => {
			state.replyLoading = action.payload ?? false;
		},
		replacePirComment: (state, action) => {
			state.spoComment = action.payload.spoComment ?? {};
		},
		replacePirComments: (state, action) => {
			state.loaded = action.payload.loaded;
			state.headerComments = action.payload.headerComments;
			state.detailComments = action.payload.detailComments;
			state.loading = false;
		},
		removePirComment: (state, action) => {
			const id = action.payload.id;
			state.changed = true;
			if (state.headerComments.filter((spoComment) => spoComment.id === id).length > 0) {
				state.headerComments = state.headerComments.filter((spoComment) => spoComment.id !== id);
			} else {
				state.detailComments = state.detailComments.filter((spoComment) => spoComment.id !== id);
			}
			state.loading = false;
		},
		updatePirComment: produce((state, action) => {
			state.loading = false;
			state.allDataComments.push(action.payload);
			const print = state.allDataComments;
		}),
		setPirComments: (state, action) => {
			state.allDataComments = action.payload;
		},
		toggleRefresh: (state, action) => {
			state.refreshCommentsData = action.payload ?? true;
		},
	}
});

export const pirCommentsActions = pirCommentsSlice.actions;

export default pirCommentsSlice;
