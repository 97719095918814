import { createSlice } from '@reduxjs/toolkit';

const initialValues = {
	Id: 1,
	RequestHeaderID: 305948,
	ReportTimestamp: '2023-01-20T11:06:12Z',
	ReportUsername: 'RRAJ6',
	CompletionDate: '2023-01-11T09:00:14Z',
	inspectionWaiting: [
		{
			Id: 1,
			InspectionDetailID: 1,
			WaitingInformation: 'some waiting information',
			WaitingComment: 'waiting because of chip shortage. we will see you soon.',
			WaitingDate: '2023-01-11T09:13:44Z'
		}
	],
	inspectionComplaints: [
		{
			Id: 1,
			InspectionDetailID: 1,
			ComplaintJustified: 'Yes',
			ComplaintComment: 'Some complaint comment added',
			InspectionReport: 'no stock to inspect'
		}
	],
	inspectionStocks: [
		{
			Id: 1,
			InspectionDetailID: 1,
			StockSampleCorrect: 'Yes',
			StockComment: 'Good Product and the Part Number etc',
			inspectionStockCode: [
				{
					Id: 1,
					RejectStockType: 'Nil Stock'
				}
			]
		}
	],
	inspectionRejects: [
		{
			Id: 1,
			InspectionDetailID: 1,
			RejectQuantity: 10,
			RmrRejectMaterial: 'abc43',
			DateCodeOfReject: '2023-01-11T09:06:05Z',
			inspectionRejectCode: [
				{
					Id: 1,
					RejectCode: 'Y - Correct label but wrong part'
				}
			]
		}
	]
};

const UpdatedInspection = {

};

const pirInspectionSlice = createSlice({
	name: 'pirInspection',
	initialState: {
		allData: [],
		data: initialValues,
		parstInspectionData: initialValues,
		updatedInspection: UpdatedInspection,
		changed: false,
		uploaded: false,
		uploading: false,
		loading: true,
		searchValue: null,
		existingSpo: null,
		urlsData: initialValues,
		selectedLocationId: null,
		inspectionDetailsByPirId: null,
		isMultipleLocation: false,
		selectedInspection: null,
	},
	reducers: {
		replacePartsInspection: (state, action) => {
			state.parstInspectionData = action.payload;
		},
		replacePirRequestAllData: (state, action) => {
			state.data = initialValues;
			state.changed = false;
			state.loading = false;
			// state.uploaded = false;
			// state.uploading = false;
		},
		replaceUpdatedInspection: (state, action) => {
			state.updatedInspection = action.payload;
		},
		replaceUrls: (state, action) => {
			state.urlsData = action.payload;
		},
		updateLocationId: (state, action) => {
			state.selectedLocationId = action.payload;
		},
		updateInspectionByPirId: (state, action) => {
			state.inspectionDetailsByPirId = action.payload;
		},
		updateMultipleLocation: (state, action) => {
			state.isMultipleLocation = action.payload;
		},
		updateSelectedInspection: (state, action) => {
			state.selectedInspection = action.payload;
		},
	}
});

export const pirInspectionActions = pirInspectionSlice.actions;

export default pirInspectionSlice;
