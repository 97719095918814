import { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useWindowSize from '../../packages/_utils/useWindowSize';
import useClickOutside from '../../packages/_utils/useClickOutside';

import menuOptions from '../../data/menu';
import { CogIcon, UserCircleIcon, UserIcon } from '@heroicons/react/solid';
import { LogoutIcon } from '@heroicons/react/outline';

import { ThemeContext } from './ThemeProvider';
import Icon from '../ui/Icon';
import { useSelector } from 'react-redux';

const SideNav = (props) => {
	const ref = useRef(null);
	const profileData = useSelector((state) => state.profile.profilesData);
	const { sideNavOpen, setSideNavOpen, viewState, setViewState, sideNavPeek } = props;
	const { theme, setTheme } = useContext(ThemeContext);
	const [scrollBarVisible, setScrollBarVisible] = useState(false);
	const [width, height] = useWindowSize();
	// const [userAvatar, setUserAvatar] = useState("https://i.pravatar.cc/100");
	const [userAvatar] = useState();

	const peek = sideNavPeek ? 'w-20' : 'w-0';

	let domNode = useClickOutside(() => {
		if (viewState !== 'push' && sideNavOpen) setSideNavOpen(!sideNavOpen);
	});

	const style = {
		sideNav: {
			button: 'focus:outline-none flex h-20 w-full items-center justify-start hover:text-primary-2 dark:hover:text-primary-2 focus:bg-secondary-2 focus:text-primary-2 hover:bg-secondary-2 text-primary-2 dark:text-primary-1 transition duration-300 ease-in-out relative',
			icon: `fill-stroke flex flex-none h-full w-20 items-center p-4`, //${!sideNavOpen && scrollBarVisible ? 'pl-5 pr-3 ' : 'p-4'}
			text: `flex w-full h-full text-base items-center p-2 whitespace-nowrap  ${
				sideNavOpen ? 'opacity-100' : 'opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out'
			}`
		},
		profile: {
			text: 'flex-none cursor-pointer text-primary-2 hover:bg-secondary-2 w-full h-10 w-10 md:p-2 transition-colors duration-300 ease-in-out'
		}
	};

	useEffect(() => {
		const scrollVisible = ref.current.scrollHeight > ref.current.clientHeight;
		if (scrollBarVisible && scrollVisible) return;
		setScrollBarVisible(ref.current.scrollHeight > ref.current.clientHeight);
	}, [width, height, setScrollBarVisible, scrollBarVisible]);

	/* eslint-disable no-unused-vars */
	const changeTheme = () => {
		let newTheme = '';
		switch (theme) {
			case 'light':
				newTheme = 'dark';
				break;
			default:
				newTheme = 'light';
				break;
		}
		if (viewState !== 'push' && sideNavOpen) setSideNavOpen(!sideNavOpen);
		return setTheme(newTheme);
	};

	const sideNavOptions = (group) => {
		if (!profileData.username) {
			return [menuOptions[0]]
			.filter((m) => m.group === group)
			.map((option, i) => {
				const icons = option.icon.split('|');
				const labels = option.label.split('|');
				let iconName = option.icon;
				let labelName = option.label;

				if (icons.length > 1) {
					if (option.action === 'changeTheme') {
						const themeIndex = theme === 'light' ? 0 : 1;
						iconName = icons[themeIndex];
						labelName = labels[themeIndex];
					}
					if (option.action === 'toggleViewState') {
						const viewIndex = viewState === 'push' ? 0 : 1;
						iconName = icons[viewIndex];
						labelName = labels[viewIndex];
					}
				}
				let notification;
				const content = (
					<button
						tabIndex={0}
						key={i}
						className={style.sideNav.button}
						onClick={
							option.action
								? () => {
										eval(option.action).call(); // eslint-disable-line no-eval
								  }
								: null
						}
					>
						<div className={style.sideNav.icon}>
							<Icon iconName={iconName} className='w-6 h-6' />
						</div>
						{notification}
						<p className={style.sideNav.text}>{labelName}</p>
					</button>
				);

				if (!option.to) return content;

				return (
					<Link
						className={`w-full outline-offset-[-2px]`}
						key={i}
						to={option.to}
						onClick={viewState === 'overlay' && sideNavOpen && setSideNavOpen}
						tabIndex={0}
					>
						{content}
					</Link>
				);
			});
		}
		return menuOptions
			.filter((m) => m.group === group)
			.map((option, i) => {
				const icons = option.icon.split('|');
				const labels = option.label.split('|');
				let iconName = option.icon;
				let labelName = option.label;

				if (icons.length > 1) {
					if (option.action === 'changeTheme') {
						const themeIndex = theme === 'light' ? 0 : 1;
						iconName = icons[themeIndex];
						labelName = labels[themeIndex];
					}
					if (option.action === 'toggleViewState') {
						const viewIndex = viewState === 'push' ? 0 : 1;
						iconName = icons[viewIndex];
						labelName = labels[viewIndex];
					}
				}

				let notification;
				const content = (
					<button
						tabIndex={0}
						key={i}
						className={style.sideNav.button}
						onClick={
							option.action
								? () => {
										eval(option.action).call();
								  }
								: null
						}
					>
						<div className={style.sideNav.icon}>
							<Icon iconName={iconName} className='w-6 h-6' />
						</div>
						{notification}
						<p className={style.sideNav.text}>{labelName}</p>
					</button>
				);

				if (!option.to) return content;

				return (
					<Link
						className={`w-full outline-offset-[-2px]`}
						key={i}
						to={option.to}
						onClick={viewState === 'overlay' && sideNavOpen && setSideNavOpen}
						tabIndex={0}
					>
						{content}
					</Link>
				);
			});
	};

	return (
		<div
			ref={domNode}
			id='sideNav'
			className={`h-full fixed top-0 ${sideNavOpen ? '!w-72' : `sm:${peek} w-0`} transition-width ease-in-out duration-500 z-20`}
		>
			<div className='h-full flex flex-col overflow-hidden'>
				<div className='bg-primary-2 h-20 flex-none'></div>
				<div
					ref={ref}
					className='flex-grow flex flex-row overflow-x-hidden overflow-y-auto bg-primary-1 dark:bg-primary-2 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-gray-500'
				>
					<div className='flex flex-col flex-1 justify-between sm:w-0 w-0'>
						<div className=''>{sideNavOptions('side-bar-top')}</div>
						<div className='px-3 my-4X w-full'>
							{profileData.username && <hr className='bg-primary-2 dark:bg-primary-1 w-full' />}
						</div>
						<div className='flex flex-col w-full items-center'>{sideNavOptions('side-bar-bottom')}</div>
					</div>
				</div>
				<div className='lg:hidden h-24 bg-primary-1 flex flex-none border-t !border-primary-2 left-10'>
					<div className='w-full flex flex-col items-center justify-start'>
						<button key={'i'} className={[style.sideNav.button, '!h-14'].join(' ')}>
							<div className={[style.sideNav.icon, '!pl-6'].join(' ')}>
								{/* {userAvatar ? (<img
                    className="flex rounded-full h-10 w-10 object-cover border-2 border-primary-2"
                    src={userAvatar}
                    alt="avatar"
                  />) : <UserCircleIcon className='w-10 h-10 right-3 text-secondary-2' />} */}
								{userAvatar ? (
									<img alt='profile-pic' src={userAvatar} className='w-8 h-8 rounded-full' />
								) : (
									<UserCircleIcon className='w-10 h-10 right-3 text-secondary-2' />
								)}
							</div>
							<p className={style.sideNav.text}>Kevin Trasler</p>
						</button>
						<ul className='flex flex-row flex-none space-x-4 justify-center  w-full'>
							<li className={`${style.profile.text} ${!sideNavOpen && 'opacity-0 pointer-events-none'} transition-opacity`}>
								<UserIcon />
							</li>
							<li className={`${style.profile.text} !w-full !flex-1`}>
								<CogIcon className='h-8 w-8' />
							</li>
							<li className={`${style.profile.text} ${!sideNavOpen && 'opacity-0 pointer-events-none'} transition-opacity`}>
								<LogoutIcon />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

SideNav.propTypes = {
	sideNavOpen: PropTypes.bool,
	sideNavPeek: PropTypes.bool,
	setSideNavOpen: PropTypes.func,
	viewState: PropTypes.string,
	setViewState: PropTypes.func,
	setUserAvatar: PropTypes.func,
	changeTheme: PropTypes.func,
	toggleViewState: PropTypes.func
};

export default SideNav;
