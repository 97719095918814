import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { waitingActions } from './waitingSlice';
import Toast from '../components/ui/Toast';

export const getWaitings = () => {
	return async (dispatch) => {
		try {
			const waitings = await getRequest('waiting');
			dispatch(waitingActions.replaceWaitings(waitings));
		} catch (error) {
			console.error('getWaiting: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(waitingActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createWaiting = (waitingData) => {
	return async (dispatch) => {
		try {
			const waiting = await postRequest('waiting/', waitingData);
			Toast({ status: 'success', message: `Waiting: ${waiting.Name} successfully added` });
			dispatch(waitingActions.updateWaiting(waiting));
		} catch (error) {
			console.error('createWaiting: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateWaiting = (waitingData) => {
	return async (dispatch) => {
		try {
			const response = await putRequest(`waiting/${waitingData.id}/`, waitingData);
			Toast({ status: 'success', message: 'Waiting Updated!' });
			dispatch(waitingActions.updateWaiting(waitingData));
		} catch (error) {
			console.error('updateWaiting: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteWaiting = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`waiting/${id}/`);
			Toast({ status: 'success', message: `Waiting Deleted!` });
			dispatch(waitingActions.removeWaiting(id));
		} catch (error) {
			console.error('deleteWaiting: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
