import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

import style from './Styles';

const CheckboxGroup = (props) => {
	const { label, name, options, ...rest } = props;
	return (
		<div className={style.formControl}>
			<label className={style.label}>{label}</label>
			<div className={style.input}>
				<Field name={name}>
					{({ field }) => {
						// console.log(options)
						return options.map((option) => {
							const isChecked = field.value.includes(option.value);
							return (
								<div className='flex items-center' key={option.key}>
									<input
										className={style.checkbox}
										type='checkbox'
										// hidden
										id={option.value}
										{...field}
										{...rest}
										value={option.value}
										checked={isChecked}
									/>
									<label htmlFor={option.value} className={style.checkLabel}>
										{option.key}
									</label>
								</div>
							);
						});
					}}
				</Field>
			</div>
			<ErrorMessage component={TextError} name={name} />
		</div>
	);
};

export default CheckboxGroup;

CheckboxGroup.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array
};
