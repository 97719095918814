export const updateObject = (oldObject, updatedProperties) => {
	return {
		...oldObject,
		...updatedProperties
	};
};

export const classNames = (...classes) => {
	return classes.filter(Boolean).join(' ');
};

// compare function
export const sortAz = (a, b) => {
	return a.id - b.id;
};

export const sortZa = (a, b) => {
	return b.id - a.id;
};

export const getRandomNumber = (min, max) => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};
