import PropTypes from 'prop-types';

const SpinnerIcon = ({ color, className }) => {
	let borderColor;

	switch (color) {
		case 'red':
			borderColor = 'border-red-600 border-t-red-200';
			break;
		case 'green':
			borderColor = 'border-green-600 border-t-green-200';
			break;
		case 'orange':
			borderColor = 'border-orange-600 border-t-orange-200';
			break;
		case 'gray':
			borderColor = 'border-gray-600 border-t-gray-200';
			break;
		default:
			borderColor = 'border-t-surface-a border-t-tertiary-cool-1';
	}

	return <div className={`flex-none mr-2 h-5 w-5 border-2 ${className || borderColor} border-solid rounded-full animate-spin`}></div>;
};

export default SpinnerIcon;

SpinnerIcon.propTypes = {
	color: PropTypes.string,
	className: PropTypes.string
};
