import { useEffect, useRef } from "react";

const useClickOutside = (handler) => {
  let domNode = useRef();
  useEffect(() => {
    let maybeHandler = (event) => {
      const parent = event.target?.parentElement;
      if (
        domNode.current
        && !domNode.current.contains(event.target)
        && !event.target.classList.contains("ignore-click-outside")
        // // this is for ignoring the close box on the select input
        && !(parent && /indicatorContainer/.test(parent.closest('div').classList.value))
      )
        handler();
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

export default useClickOutside;
