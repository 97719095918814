import { createSlice } from '@reduxjs/toolkit';

const initialValues = {
	loading: null,
	label: null,
	error: null,
	viewState: null,
	sideNavPeek: null
};

const globalSlice = createSlice({
	name: 'global',
	initialState: initialValues,
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload?.loading ?? false;
			state.label = action.payload?.label ?? null;
			state.error = action.payload?.error ?? false;
		},
		setViewState: (state, action) => {
			state.viewState = action.payload;
		},
		setSideNavPeek: (state, action) => {
			state.sideNavPeek = action.payload;
		}
	}
});

export const globalActions = globalSlice.actions;

export default globalSlice;
