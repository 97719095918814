import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

import style from './Styles';
// import Select from 'react-select'
import CustomSelect from './CustomSelect';

const Dropdown = (props) => {
	const { label, name, options, placeholder, className, isMulti, disabled, labelClass, required, ...rest } = props;

	return (
		<div className={`${className || style.formControl}`}>
			<label htmlFor={name} className={`${style.label} ${labelClass}`}>
				{label} {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
			</label>
			<Field name={name} options={options} disabled={disabled} component={CustomSelect} placeholder={placeholder} isMulti={isMulti} className={style.select} {...rest} />
			{/* <Field id={name} name={name} {...rest} className={style.input}>
        {({ field, form }) => {
          return (
            <>
              <Select
                name={name}
                isMulti={true}
                placeholder={placeholder}
                onChange={(option) => {
                  form.setFieldValue(
                    field.name,
                    isMulti
                      ? (option).map((item) => item.value)
                      : (option).value
                  );
                }} styles={customStyles} options={options} />
            </>
          )
        }} 
      </Field>*/}
			<ErrorMessage component={TextError} name={name} />
		</div>
	);
};

export default Dropdown;

Dropdown.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
	labelClass: PropTypes.string,
	className: PropTypes.string,
  required: PropTypes.bool
};
