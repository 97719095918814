import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAllPirRequestData, getLatestRecord, resetAllPirRequestData, resetUploaded, toggleDrawer } from '../../store/pirRequestActions';

import { Drawer, DrawerHeader, DrawerBody } from '../../packages/drawer/Drawer';

import Table, { SelectColumnFilter, StatusPill, DateRangeColumnFilter, dateBetweenFilterFn } from '../../components/ui/Table';

import PIRRequestForm from './form/Form';
import useToggle from '../../packages/_utils/useToggle';
import { toggleCommentsRefresh } from '../../store/pirCommentsActions';

const AllData = () => {

	const profileData = useSelector((state) => state.profile.profilesData);
	if (!profileData.is_active) {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}

	const [toggle, isOpen] = useToggle();

	const isDrawerOpen = useSelector((state) => state.pirRequest.isDrawerOpen);

	const handleClose = () => {
		dispatch(toggleCommentsRefresh(true));
		dispatch(toggleDrawer(false));
	};

	const handleClick = useCallback(
		(e) => {
			setIndex(e);
			toggle();
			dispatch(toggleDrawer(true));
		},
		[toggle]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'PIR NO',
				accessor: 'id',
				Cell: ({ cell }) => {
					return (
						<div className='flex-none'>
							<button
								className='font-bold text-md text-tertiary-warm-1 hover:text-tertiary-warm-2'
								value={cell.row.values.Id}
								onClick={() => handleClick(cell.row.index)}
							>
								{cell.row.values.id}
							</button>
						</div>
					);
				},
				className: 'w-16'
			},
			{
				Header: 'Product',
				accessor: 'Product',
				Filter: SelectColumnFilter,
				filter: "includesSome",
				className: 'w-32'
			},
			{
				Header: 'Description',
				accessor: 'Description',
				className: 'w-32'
			},
			{
				Header: 'Vin',
				accessor: 'Vin',
				className: 'w-20'
			},
			{
				Header: 'Location',
				accessor: 'LocationLabel',
				Filter: SelectColumnFilter,
				filter: "includesSome",
				className: 'w-32'
			},
			{
				Header: 'Date Code',
				accessor: 'DateCode',
				Filter: SelectColumnFilter,
				filter: "includesSome",
				className: 'w-10'
			},
			{
				Header: 'Status',
				accessor: 'Status',
				Filter: SelectColumnFilter,
				filter: "includes",
				Cell: StatusPill,
				className: 'w-20'
			},
			{
				Header: 'KPI',
				accessor: 'Kpi',
				className: 'w-12'
			},
			{
				Header: 'Requested By',
				accessor: 'UserId',
				Filter: SelectColumnFilter,
				filter: "includesSome",
				className: 'w-20'
			},
			{
				Header: 'Create Date',
				accessor: 'AuditDateTime',
				Cell: (props) => {
					//props.value will contain your date
					//you can convert your date here
					let date = new Date(props.value); //+ 'Z'
					// return date.toLocaleDateString();
					return date.toLocaleString();
				},
				// Filter: DateRangeColumnFilter,
				// filter: dateBetweenFilterFn /* Custom Filter Type */,
				className: 'w-32'
			},
			{
				Header: 'Close Date',
				accessor: 'CloseDate',
				Cell: (props) => {
					if (props.value === '') return;
					let date = new Date(props.value); //+ 'Z'
					// return date.toLocaleDateString();
					return date.toLocaleString();
				},
				// Filter: DateRangeColumnFilter,
				// filter: dateBetweenFilterFn /* Custom Filter Type */,
				className: 'w-32'
			}
		],
		[handleClick]
	);

	const data = useSelector((state) => state.pirRequest.allData);
	const latestRecord = useSelector((state) => state.pirRequest.latestRecord);
	const searchValue = '';
	const dispatch = useDispatch();
	const [index, setIndex] = useState();

	useEffect(() => {
		dispatch(getLatestRecord());
	}, [dispatch]);

	useEffect(() => {
		if (latestRecord && data.length > 0) {
			if(!(latestRecord.results[0].id === data[0].id)) {
				dispatch(resetUploaded());
				dispatch(getAllPirRequestData());
				dispatch(resetAllPirRequestData());
			}
		}
	}, [latestRecord]);

	return (
		<>
			<Drawer isOpen={isDrawerOpen} closeOnClickOutside={false} toggle={handleClose} position='right'>
				<DrawerHeader closeDrawer={handleClose}>PIR Request Details</DrawerHeader>
				<DrawerBody>
					<div className={`w-full`}>
						<div className='grid grid-cols-1 gap-x-2'>
							{isDrawerOpen && data[index]?.id && <PIRRequestForm pirRequestNo={data[index]?.id} closeDrawer={handleClose} />}
						</div>
					</div>
				</DrawerBody>
			</Drawer>

			<Table
				columns={columns}
				data={data}
				title='All PIR Requests'
				filterValue={data.length === 0 || !searchValue ? '' : searchValue.toString()}
			/>
		</>
	);
};

export default AllData;

AllData.propTypes = {
	cell: PropTypes.node,
	value: PropTypes.string
};
