import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../../packages/button/Button';
// import { FormField } from "../../packages/form/FormField";

import { MentionsInput, Mention } from 'react-mentions';
import { defaultMentionStyle } from './defaultMentionStyle';
import { defaultStyle } from './defaultStyle';
import { getRequest } from '../../axios';
import { useSelector } from 'react-redux';

export const fetchUsers = async (query, callback) => {
	if (!query) return;
	const filteredUsers = await getRequest(`users/filtered/${query}`);
	callback(filteredUsers);
};

const CommentForm = ({ handleSubmit, submitLabel, hasCancelButton = false, handleCancel, initialText = '' }) => {
	const [text, setText] = useState(initialText);

	const loadingStatus = useSelector((state) => state.pirComments.loading);

	useEffect(() => {
		if (loadingStatus) {
			setText('');
		}
	}, [loadingStatus]);

	const isTextareaDisabled = text.length === 0;

	const onChange = (e) => {
		setText(e.target.value);
	};

	const onAdd = (e) => {
		// likely not needed
		// console.log("onAdd", e);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		handleSubmit(text);
		// setText('');
	};

	const onHandleCancel = () => {
		if (initialText === '') setText('');
		handleCancel();
	};

	return (
		<form onSubmit={onSubmit} className=''>
			<MentionsInput
				value={text}
				onChange={onChange}
				placeholder={"Start writing your comment here..."}
				a11ySuggestionsListLabel={'Suggested mentions'}
				style={defaultStyle}
			>
				<Mention style={defaultMentionStyle} data={fetchUsers} onAdd={onAdd} />
			</MentionsInput>
			<div className='py-2'>
				<Button className='text-sm' disabled={isTextareaDisabled}>
					{submitLabel}
				</Button>
				{hasCancelButton && (
					<Button type='button' className='ml-2 text-sm' onClick={onHandleCancel}>
						Cancel
					</Button>
				)}
			</div>
		</form>
	);
};

export default CommentForm;

CommentForm.propTypes = {
	handleSubmit: PropTypes.func,
	handleCancel: PropTypes.func,
	submitLabel: PropTypes.string,
	hasCancelButton: PropTypes.bool,
	initialText: PropTypes.string
};
