import { getRequest } from '../axios';
import { sampleActions } from './sampleSlice';

export const getData = (data) => {
	return async (dispatch) => {
		try {
			// const data = await getRequest('api');
			// const apiResponse = 'Something from the API';
			dispatch(sampleActions.getData(data));
		} catch (error) {
			console.error('getContact: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				// dispatch(contactsActions.setLoading(false));
			}
			// if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
			// Toast({ status: 'error', message: error.message });
		}
	};
};
