import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import useClickOutside from '../../packages/_utils/useClickOutside';

const modalRoot = document.getElementById('modal-root');

let modalRef;

/*Logic*/
const style = {
	animate: 'animate-modal',
	body: `flex-shrink flex-grow p-2`,
	headerTitle: `text-2xl md:text-2xl font-light`,
	header: `items-start justify-between p-3 border-b border-gray-300`,
	container: `fixed top-0 left-0 z-50 w-full h-full m-0 overflow-y-auto igsnore-click-outside`,
	overlay: `fixed top-0 left-0 z-50 w-screen h-screen bg-black opacity-50`,
	footer: `flex flex-wrap items-center justify-between p-3 border-t border-gray-300`,
	content: {
		default: `relative flex flex-col bg-white pointer-events-auto rounded`
	},
	orientation: {
		default: 'mt-12 mx-8 pb-6 md:m-auto md:w-6/12 lg:w-4/12 md:pt-12 focus:outline-none',
		large: 'mt-12 mx-8 pb-6 md:m-auto md:w-8/12 lg:w-8/12 md:pt-12 focus:outline-none',
		extraLarge: 'mt-12 mx-8 pb-6 md:w-12/12 md:pt-12 focus:outline-none'
	}
};

export const Modal = ({ isOpen, toggle, children, animate = false, closeOnClickOutside, position = 'default' }) => {
	modalRef = useClickOutside(() => {
		// console.log('close me')
		closeOnClickOutside && toggle(false);
	});

	// close modal when you click on "ESC" key
	useEffect(() => {
		const handleEscape = (event) => {
			if (!isOpen) return;
			if (event.key === 'Escape') {
				toggle(false);
			}
		};
		document.addEventListener('keyup', handleEscape);
		return () => document.removeEventListener('keyup', handleEscape);
	}, [isOpen, toggle]);

	// Put focus on modal dialogue, hide scrollbar and prevent body from moving when modal is open
	useEffect(() => {
		if (!isOpen) return;

		modalRef.current?.focus();

		// const html = document.documentElement;
		// const scrollbarWidth = window.innerWidth - html.clientWidth;

		// html.style.overflow = "hidden";
		// html.style.paddingRight = `${scrollbarWidth}px`;

		return () => {
			document.documentElement.style.overflow = '';
			document.documentElement.style.paddingRight = '';
		};
	}, [isOpen]);

	return (
		isOpen &&
		createPortal(
			<>
				<div className={style.overlay} />
				<div className={style.container}>
					<div className={style.orientation[position]} ref={closeOnClickOutside ? modalRef : null} role='dialog' aria-modal={true}>
						<div name='modal-dialog' className={`${style.content[position]} ${style.content.default} ${animate ? style.animate : ''}`}>
							{children}
						</div>
					</div>
				</div>
			</>,
			modalRoot
		)
	);
};

Modal.propTypes = {
	children: PropTypes.array
};

export const ModalHeader = ({ children }) => {
	return (
		<div className={style.header}>
			<h4 className={style.headerTitle}>{children}</h4>
		</div>
	);
};

ModalHeader.propTypes = {
	children: PropTypes.node
};

export const ModalBody = ({ children }) => {
	return <div className={style.body}>{children}</div>;
};

ModalBody.propTypes = {
	children: PropTypes.object
};

export const ModalFooter = ({ children }) => {
	return <div className={style.footer}>{children}</div>;
};

ModalFooter.propTypes = {
	children: PropTypes.array
};
