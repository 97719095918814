import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from "formik";
import FormikControl from "../../../components/ui/Formik/FormikControl";

import Button from "../../../packages/button/Button";
import Comments from "../../../components/comments/Comments";

import { ArrowLeftIcon } from "@heroicons/react/solid";
import Toast from "../../../components/ui/Toast";

import { Role } from "../../../data/roles";
import FormField from '../../../packages/form/FormField';
import { getAllPirRequestData, toggleDrawer } from '../../../store/pirRequestActions';

let flag = true;

const PartsDetails = (props) => {
  const { pirId, data, next, prev } = props;

  if (data === undefined && flag) {
    Toast({ status: "warning", message: 'Inspection Unavailable' });
    flag = false;
  }

  const stockSampleCorrectOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];

  const complaintJustifiedOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];

  const profileData = useSelector((state) => state.profile.profilesData);
  const selectedLocationId = useSelector((state) => state.pirInspection.selectedLocationId);
  const currentUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(data);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [showRejection, setShowRejection] = useState(false);
  const [submittedInspection, setSubmittedInspection] = useState(false);

  const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);

  let waitingOptions = [];
  let statusOptions = [];
  let rejectionOptions = [];
  let inspectorOptions = [];

  if (dropdownsData.Locations.length > 0) {
		const waitings = dropdownsData.Waiting;
		waitings.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			waitingOptions.push(pushData);
		});
    const statuses = dropdownsData.Status;
		statuses.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			statusOptions.push(pushData);
		});
    const rejections = dropdownsData.RejectCode;
		rejections.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			rejectionOptions.push(pushData);
		});
    const users = dropdownsData.Users;
		users.forEach(element => {
			const pushData = { value: element.id, label: element.first_name + ' ' + element.last_name };
			inspectorOptions.push(pushData);
		});
	}

  if (formData === undefined) {
    var tempData = {};
    let LocationLabel = dropdownsData.Locations.find(x => x.id === selectedLocationId).Name;
    tempData.LocationLabel = LocationLabel;
		Object.preventExtensions(tempData);
  }
  else if (dropdownsData.Locations.length > 0) {
    var tempData = JSON.parse(JSON.stringify(formData));
    let StatusLabel = '';
    let WaitingLabel = '';
    let UserLabel = '';
    let LocationLabel = '';
    if (formData.StatusId) StatusLabel = dropdownsData.Status.find(x => x.id === formData.StatusId).Name;
    if (formData.WaitingId) WaitingLabel = dropdownsData.Waiting.find(x => x.id === formData.WaitingId).Name;
    if (formData.InspectorId) UserLabel = dropdownsData.Users.find(x => x.id === formData.InspectorId).first_name + ' ' + dropdownsData.Users.find(x => x.id === formData.InspectorId).last_name;
    if (formData.Rejection.length > 0 && formData.Rejection[0].CodeId) {
      const RejectionLabel = dropdownsData.RejectCode.find(x => x.id === formData.Rejection[0].CodeId).Name;
      tempData.RejectionLabel = RejectionLabel;
      // setShowRejection(useState(true));
    }
    LocationLabel = dropdownsData.Locations.find(x => x.id === selectedLocationId).Name;
    tempData.LocationLabel = LocationLabel;
    tempData.StatusLabel = StatusLabel;
    tempData.WaitingLabel = WaitingLabel;
    tempData.UserLabel = UserLabel;
		Object.preventExtensions(tempData);
	}

  useEffect(() => {
    setFormData(data);
  }, [data]);

  useEffect(() => {
    setIsReadOnly(currentUser.role === "view");
  }, [currentUser]);

  const handleSubmit = (values) => {

    if (values.Phone === null || values.Phone === undefined || values.Phone === '') {
			Toast({status: "warning", message: 'Phone number is a mandatory field'});
			return;
		}

    let rejectionValues = [];
    if(showRejection) {
      if (values.Rejection[0].CodeId === '' || values.Rejection[0].Quantity === '' || values.Rejection[0].RMR === '' || values.Rejection[0].StockType === '' || values.Rejection[0].DateCode === '') {
        Toast({status: "warning", message: 'All Rejection fields are mandatory'});
        return;
      }
      rejectionValues = [{
        Quantity: values.Rejection[0].Quantity,
        StockType: values.Rejection[0].StockType,
        RMR: values.Rejection[0].RMR,
        DateCode: values.Rejection[0].DateCode,
        CodeId: values.Rejection[0].CodeId
      }];
    }    

    const submitData = {
        Phone: values.Phone,
        StockSampleCorrect: values.StockSampleCorrect === '' ? null : values.StockSampleCorrect,
        ComplaintJustified: values.ComplaintJustified === '' ? null : values.ComplaintJustified,
        Report: values.Report === '' ? null : values.Report,
        Comments: values.Comments,
        Rejection: rejectionValues,
        RequestHeaderId: values.RequestHeaderId,
        WaitingId: values.WaitingId,
        StatusId: values.StatusId,
        InspectorId: values.InspectorId,
        DateCodeChecked: values.DateCodeChecked,
        LocationId: selectedLocationId
    };
    next( submitData, true, data.id);
    dispatch(toggleDrawer(false)); 
    dispatch(getAllPirRequestData());
  };

  const handleNewSubmit = (values) => {

    if (values.Phone === null || values.Phone === undefined || values.Phone === '') {
			Toast({status: "warning", message: 'Phone number is a mandatory field'});
			return;
		}

    let rejectionValues = [];
    if(showRejection) {
      if (values.Rejection[0].CodeId === '' || values.Rejection[0].Quantity === '' || values.Rejection[0].RMR === '' || values.Rejection[0].StockType === '' || values.Rejection[0].DateCode === '') {
        Toast({status: "warning", message: 'All Rejection fields are mandatory'});
        return;
      }
      rejectionValues = [{
        Quantity: values.Rejection[0].Quantity,
        StockType: values.Rejection[0].StockType,
        RMR: values.Rejection[0].RMR,
        DateCode: values.Rejection[0].DateCode,
        CodeId: values.Rejection[0].CodeId
      }];
    }

    const submitData = {
        Phone: values.Phone,
        StockSampleCorrect: values.StockSampleCorrect === '' ? null : values.StockSampleCorrect,
        ComplaintJustified: values.ComplaintJustified === '' ? null : values.ComplaintJustified,
        Report: values.Report === '' ? null : values.Report,
        Comments: [],
        Rejection: rejectionValues,
        RequestHeaderId: pirId,
        WaitingId: values.WaitingId,
        StatusId: values.StatusId,
        InspectorId: values.InspectorId,
        DateCodeChecked: values.DateCodeChecked,
        LocationId: selectedLocationId
    };
    next( submitData, true);
    setSubmittedInspection(true);
    dispatch(toggleDrawer(false));
    dispatch(getAllPirRequestData());
  };

  const handlePrev = (values) => {
    prev(values);
  };

  return (
    <>
    { formData===undefined ? 

      // New Inspection Submission Form
      <Formik
          initialValues={tempData}
          onSubmit={handleNewSubmit}
          enableReinitialize
          id="newInspectionDetails"
        >
          {({ submitForm, errors, values, resetForm, setFieldTouched }) => {
            return (
              <>
                <Form>
                  <div className="md:flex px-4 py-4 bg-tertiary-cool-4 md:p-6">
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
                      <FormikControl
                        control='input'
                        type='text'
                        label='Location'
                        name='LocationLabel'
                        disabled={true}
                      />
                      <FormikControl
                        className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
                        control='textarea'
                        type='text'
                        label='Inspection Report'
                        name='Report'
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Phone'
                        name='Phone'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        control="select"
                        label='Stock Sample Correct' 
                        name='StockSampleCorrect'
                        placeholder="Select Yes/No"
                        isMulti={false}
                        options={stockSampleCorrectOptions}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        control="select"
                        label='Complaint Justified'
                        name='ComplaintJustified'
                        placeholder="Select Yes/No"
                        isMulti={false}
                        options={complaintJustifiedOptions}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
                        control="select"
                        label='Waiting Comment'
                        name='WaitingId'
                        placeholder="Select an option"
                        isMulti={false}
                        options={waitingOptions}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        control="select"
                        label='Status'
                        name='StatusId'
                        placeholder="Select a status"
                        isMulti={false}
                        options={statusOptions}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
                        control="select"
                        label="Inspection Completed by"
                        name="InspectorId"
                        placeholder="Select Name"
                        isMulti={false}
                        options={inspectorOptions}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='DateCode Checked'
                        name='DateCodeChecked'
                        disabled={profileData.role === 'View'}
                      />
                      <span style={{ color: '#525252', fontSize: '14px', fontWeight: 'bold'}}>Stock Rejection</span>
                      <FormField
                        // label='Stock Rejection'
                        name='showRejection'
                        type='checkbox'
                        checked={showRejection}
                        onClick={() => setShowRejection(!showRejection)}
                      />
                    </div>
                  </div>
                </Form>

                <Form>
                {showRejection && <div className="md:flex px-4 py-4 bg-tertiary-cool-4 md:p-6">
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
                      <FormikControl
                        control='input'
                        type='text'
                        label='Rejection Quantity'
                        name='Rejection[0].Quantity'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Rejection Stock Type'
                        name='Rejection[0].StockType'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Rejection RMR'
                        name='Rejection[0].RMR'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        className="pb-4 !col-span-4 sm:!col-span-3 lg:!col-span-4 xl:!col-span-4 min-w-fsit"
                        control="select"
                        label='Rejection Reason'
                        name='Rejection[0].CodeId'
                        placeholder="Select a rejection reason"
                        isMulti={false}
                        options={rejectionOptions}
                        required={true}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Date Code'
                        name='Rejection[0].DateCode'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                  </div>
                </div>}
              </Form>

                <hr />
                {/* <Comments commentType='inspectionComment' pirRequestId={tempData.RequestHeaderId} pirInspectionNo={tempData.id} /> */}
                <div className="grid grid-cols-3 gap-4px-4 py-3 bg-white text-left md:px-6 rounded-b">
                  <Button
                    type="button"
                    onClick={() => {
                      if (Object.keys(errors).length === 0) {
                        return handlePrev(values);
                      }
                    }}
                    className={`bg-tertiary-cool-2 col-start-1 place-self-start flex items-center`}
                  >
                    <ArrowLeftIcon className="h-4 mr-2" />
                    PIR Details
                  </Button>
                  {currentUser.role !== Role.view && (
                    <Button
                      onClick={() => {submitForm();}}
                      color="success"
                      className={`place-self-end col-start-3`}
                      disabled={submittedInspection || profileData.role === 'View'}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </>
            );
          }}
      </Formik> :

      // Existing Inspection Save Form
      <Formik
        initialValues={tempData}
        onSubmit={handleSubmit}
        enableReinitialize
        id="existingInspectionDetails"
      >
        {({ submitForm, errors, values, resetForm, setFieldTouched }) => {
          return (
            <>
              <Form>
                <div className="md:flex px-4 py-4 bg-tertiary-cool-4 md:p-6">
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 xl:!col-span-2 2xl:!col-span-1 min-w-fift'
											control='input'
											disabled={true}
											type='text'
											label='Inspection Id'
											name='id'
										/>
                    <FormikControl
											control='input'
											type='text'
											label='Location'
											name='LocationLabel'
											disabled={true}
										/>
										<FormikControl
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control='textarea'
											type='text'
											label='Inspection Report'
											name='Report'
											disabled={profileData.role === 'View'}
										/>
										<FormikControl
											control='input'
											type='text'
											label='Phone'
											name='Phone'
											disabled={profileData.role === 'View'}
                      required={true}
										/>
                    <FormikControl
                      control="select"
                      label='Stock Sample Correct' 
                      name='StockSampleCorrect'
                      placeholder="Select Yes/No"
                      isMulti={false}
                      options={stockSampleCorrectOptions}
                      disabled={profileData.role === 'View'}
                    />
                    <FormikControl
                      control="select"
                      label='Complaint Justified'
                      name='ComplaintJustified'
                      placeholder="Select Yes/No"
                      isMulti={false}
                      options={complaintJustifiedOptions}
                      disabled={profileData.role === 'View'}
                    />
                    <FormikControl
                      className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
                      control="select"
                      label='Waiting Comment'
                      name='WaitingId'
                      placeholder="Select an option"
                      isMulti={false}
                      options={waitingOptions}
                      disabled={profileData.role === 'View'}
                    />
                    <FormikControl
                      control="select"
                      label='Status'
                      name='StatusId'
                      placeholder="Select a status"
                      isMulti={false}
                      options={statusOptions}
                      disabled={profileData.role === 'View'}
                    />
                    <FormikControl
                      className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
                      control="select"
                      label="Inspection Completed by"
                      name="InspectorId"
                      placeholder="Select Name"
                      isMulti={false}
                      options={inspectorOptions}
                      disabled={profileData.role === 'View'}
                    />
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='date'
											type='date'
											label='Audit DateTime'
											disabled={true}
											name='AuditDateTime'
										/>
                    <FormikControl
                        control='input'
                        type='text'
                        label='DateCode Checked'
                        name='DateCodeChecked'
                        disabled={profileData.role === 'View'}
                      />
                    <span style={{ color: '#525252', fontSize: '14px', fontWeight: 'bold'}}>Stock Rejection</span>
                    <FormField
                      // label='Stock Rejection'
                      style={{ marginLeft: '40px' }}
                      name='showRejection'
                      type='checkbox'
                      checked={showRejection}
                      onClick={() => setShowRejection(!showRejection)}
                    />
									</div>
                </div>
              </Form>

              <Form>
                {showRejection && <div className="md:flex px-4 py-4 bg-tertiary-cool-4 md:p-6">
                  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
                      <FormikControl
                        control='input'
                        type='text'
                        label='Rejection Quantity'
                        name='Rejection[0].Quantity'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Rejection Stock Type'
                        name='Rejection[0].StockType'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Rejection RMR'
                        name='Rejection[0].RMR'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                      <FormikControl
                        className="pb-4 !col-span-4 sm:!col-span-3 lg:!col-span-4 xl:!col-span-4 min-w-fsit"
                        control="select"
                        label='Rejection Reason'
                        name='Rejection[0].CodeId'
                        placeholder="Select a rejection reason"
                        isMulti={false}
                        options={rejectionOptions}
                        required={true}
                        disabled={profileData.role === 'View'}
                      />
                      <FormikControl
                        control='input'
                        type='text'
                        label='Date Code'
                        name='Rejection[0].DateCode'
                        disabled={profileData.role === 'View'}
                        required={true}
                      />
                  </div>
                </div>}
              </Form>
              
              <hr />
							<Comments commentType='inspectionComment' pirRequestId={tempData.RequestHeaderId} pirInspectionNo={tempData.id} />
              <div className="grid grid-cols-3 gap-4px-4 py-3 bg-white text-left md:px-6 rounded-b">
                <Button
                  type="button"
                  onClick={() => {
                    if (Object.keys(errors).length === 0) {
                      return handlePrev(values);
                    } else {
                    }
                  }}
                  className={`bg-tertiary-cool-2 col-start-1 place-self-start flex items-center`}
                >
                  <ArrowLeftIcon className="h-4 mr-2" />
                  PIR Details
                </Button>
                {currentUser.role !== Role.view && (
                  <Button
                    onClick={() => {submitForm();}}
                    color="success"
                    className={`place-self-end col-start-3`}
                    disabled={profileData.role === 'View'}
                  >
                    Save
                  </Button>
                )}
              </div>
            </>
          );
        }}
      </Formik>}
    </>
  );
};

export default PartsDetails;

PartsDetails.propTypes = {
  pirId: PropTypes.int,
	data: PropTypes.object,
	next: PropTypes.func,
  prev: PropTypes.func
};
