import { EmojiSadIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import Jumbotron from "../components/ui/Jumbotron";
import Button from "../packages/button/Button";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Jumbotron className="bg-primary-2 text-primary-1 mx-auto">
      <h1 className="text-xl py-4 md:text-2xl mb-3 flex items-center">
        <EmojiSadIcon className="h-8 pr-6" />   Network Issue!
      </h1>
      <p>We're unable to load this content at present!</p>
      <br />
      <p>
        Can you try again and if the problem persists contact
        <a href="mailto:pir-application-support_smb@jaguarlandrover.com" className="text-blue-500"> pir-application-support_smb@jaguarlandrover.com </a>
      </p>
      <br />
      <Button color="primary" onClick={() => navigate('/')}>Go to Home Page</Button>
    </Jumbotron>
  );
};

export default NotFound;
