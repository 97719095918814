import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import FormikControl from '../../../components/ui/Formik/FormikControl';
import * as Yup from 'yup';
import SearchInput from '../../../components/ui/SearchInput';
import Button from '../../../packages/button/Button';
import Comments from '../../../components/comments/Comments';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '../../../packages/modal/Modal';
import useToggle from '../../../packages/_utils/useToggle';
import { deletePirRequest, resetPirRequestData, getPirDetailsById } from '../../../store/pirRequestActions';
import Toast from '../../../components/ui/Toast';
// import { resetComments } from '../../../store/pirCommentsActions';
import { ArrowRightIcon } from '@heroicons/react/solid';

import { Role } from '../../../data/roles';
import { setComments } from '../../../store/pirCommentsActions';
import { tableStyles } from '../../settings/Settings';
import { getInspectionDetailsByPirId, updateLocationId, updateSelectedInspection } from '../../../store/pirInspectionActions';
import { StatusPill } from '../../../components/ui/Table';
import { Navigate, useNavigate } from 'react-router-dom';
import SearchHeader from '../../../components/ui/SearchHeader';
import { getImageUrls } from '../../../store/pirInspectionActions';

const validationSchema = Yup.object().shape({
	Description: Yup.string().nullable().required('Required')
});

const EditPIR = () => {
	const profileData = useSelector((state) => state.profile.profilesData);
	const isMultiple = useSelector((state) => state.pirInspection.isMultipleLocation);
	const inspectionData = useSelector((state) => state.pirInspection.inspectionDetailsByPirId);
	const allData = useSelector((state) => state.pirRequest.allData);
	const data = useSelector((state) => state.pirRequest.pirDetailsById);
	const dispatch = useDispatch();
	const imageUrls = useSelector((state) => state.pirInspection.urlsData);
	let isClosedDate = false;
	var tempUrls = JSON.parse(JSON.stringify(imageUrls));
	const [searchValue, setSearchValue] = useState('');
	const [showSearch, setShowSearch] = useState(true);
	const [autoHide, setAutoHide] = useState(showSearch);
	const [toggleExistingPir, isExistingPirOpen] = useToggle();
	const navigate = useNavigate();
	const [isMultipleLocation, setMultipleLocation] = useState(false);
	const [toggleChooseLocation, isChooseLocationOpen] = useToggle();

	useEffect(() => {
		if (isMultiple) setMultipleLocation(true);
		else setMultipleLocation(false);
	}, [isMultiple]);

	if (tempUrls.length > 0) {
		for (let index = 0; index < tempUrls.length; index++) {
			const element = tempUrls[index];
			element.index = index;
		}
	}
	const noOfImages = useSelector((state) => state.pirInspection.urlsData.length);
	const [currentUrl, setCurrentUrl] = useState(tempUrls[0]);
	
	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);

	let locationOptions = [];
	let platformOptions = [];
	let reasonOptions = [];
	let userOptions = [];

	if (dropdownsData.Locations.length > 0 && data) {
		dispatch(setComments(data.Comments));

		var tempData = JSON.parse(JSON.stringify(data));

		tempData.KpiLabel = '0.00';

		let LocationLabel = '';
		let ReasonLabel = '';
		let RequesterLabel = '';
		let AuditUserLabel = '';
		let PlatformLabel = '';
		let StatusLabel;
		let LocationsArray = [];

		if (data.LocationId) LocationLabel = dropdownsData.Locations.find(x => x.id === data.LocationId).Name;
		if (data.ReasonId) ReasonLabel = dropdownsData.Reasons.find(x => x.id === data.ReasonId).Name;
		if (data.RequesterId) RequesterLabel = dropdownsData.Users.find(x => x.id === data.RequesterId).first_name + ' ' + dropdownsData.Users.find(x => x.id === data.RequesterId).last_name;
		if (data.AuditUserId) AuditUserLabel = dropdownsData.Users.find(x => x.id === data.AuditUserId).first_name + ' ' + dropdownsData.Users.find(x => x.id === data.AuditUserId).last_name;
		if (data.Locations.length > 0) {
			data.Locations.forEach(element => {
				LocationsArray.push(element.PirLocationId);
				const pushData = dropdownsData.Locations.find(x => x.id === element.PirLocationId).Name;
				if (LocationLabel === '') LocationLabel =  pushData;
				else LocationLabel = LocationLabel + ', ' + pushData;
			});
		}

		// Dropdowns
		const locations = dropdownsData.Locations;
		locations.forEach(element => {
			const pushData = { value: element.id, label: element.Name + ' (' + element.Code +')'};
			locationOptions.push(pushData);
		});
		const platforms = dropdownsData.Platforms;
		platforms.forEach(element => {
			const pushData = { value: element.id, label: element.Name + ' (' + element.Description + ')'};
			platformOptions.push(pushData);
		});
		const reasons = dropdownsData.Reasons;
		reasons.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			reasonOptions.push(pushData);
		});
		const users = dropdownsData.Users;
		users.forEach(element => {
			const pushData = { value: element.id, label: element.first_name + ' ' + element.last_name };
			userOptions.push(pushData);
		});

		//Status in Choose Location Popup
		if (data.Locations.length > 0) {
			tempData.Locations.forEach(temp => {
				temp.Status = 'New';
			});
		}
		if (data.RequestHeaderId.length > 0) {
			for (let index = 0; index < data.RequestHeaderId.length; index++) {
				const element = data.RequestHeaderId[index];
				if (element.StatusId !== null) {
					tempData.Locations.forEach(temp => {
						if(temp.PirLocationId === element.LocationId) {
							temp.Status = dropdownsData.Status.find(x => x.id === element.StatusId).Name;
						}
					});
				}
			}
		}

		// Vehicle Model
		if (data.VehiclePlatforms.length > 0) {
			const vehicleId = data.VehiclePlatforms[0].VehiclePlatformId;
			if (vehicleId !== undefined && vehicleId !== null) {
				PlatformLabel = dropdownsData.Platforms.find(x => x.id === data.VehiclePlatforms[0].VehiclePlatformId).Name + ' ('
								+ dropdownsData.Platforms.find(x => x.id === data.VehiclePlatforms[0].VehiclePlatformId).Description + ')';
				tempData.PlatformDisplay = PlatformLabel;
			}
		}
		else {
			tempData.PlatformDisplay = '';
		}

		// KPI Calculations
		let diffInHours = '0h 0m';
		if (data.RequestHeaderId.length === 0) {
			const date = new Date(data.AuditDateTime);
			const now = new Date();
			const diffInMilliseconds = now.getTime() - date.getTime();
			diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
		}
		else {
			const statusCheck = data.RequestHeaderId[0].StatusId;
			if (statusCheck === 1) {
				const date = new Date(data.AuditDateTime);
				const now = new Date();
				const diffInMilliseconds = now.getTime() - date.getTime();
				diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
			}
			else {
				const auditDate = new Date(data.AuditDateTime);
				const closingDate = new Date(data.RequestHeaderId[0].AuditDateTime);
				const diffInMilliseconds = closingDate.getTime() - auditDate.getTime();
				diffInHours = (diffInMilliseconds / (1000 * 60 * 60)).toFixed(2).toString();
			}
		}

		const hours = Math.floor(diffInHours);
		const minutes = Math.round((diffInHours - hours) * 60);
		const formattedKpi = hours.toString() + 'h ' + minutes.toString() + 'm';

		if (data.RequestHeaderId.length > 0 && data.RequestHeaderId[0].StatusId === 2) {
			tempData.CloseDateLabel = data.RequestHeaderId[0].AuditDateTime;
			isClosedDate = true;
		}

		//Status
		if (data.RequestHeaderId.length === 0) {
			StatusLabel = 'New';
		} else {
			let allCancelled = data.RequestHeaderId.every(item => item.StatusId === 3);
			let hasNull = data.RequestHeaderId.some(item => item.StatusId === null);
			let hasInProgress = data.RequestHeaderId.some(item => item.StatusId === 1);

			if (allCancelled) {
				StatusLabel = 'Cancelled';
			} else if (hasNull) {
				StatusLabel = 'New';
			} else if (hasInProgress && !hasNull) {
				StatusLabel = 'In Progress';
			} else {
				StatusLabel = 'Closed';
			}
		}
		if (data.Locations.length > data.RequestHeaderId.length) StatusLabel = 'New';

		tempData.KpiLabel = formattedKpi;
		tempData.LocationDisplay = LocationLabel;
		tempData.ReasonDisplay = ReasonLabel;
		tempData.RequesterDisplay = RequesterLabel;
		tempData.AuditUserDisplay = AuditUserLabel;
		tempData.StatusDisplay = StatusLabel;
		tempData.LocationsArray = LocationsArray;
		Object.preventExtensions(tempData);
	}

	const handleImagePrev = () => {
		const currentIndex = currentUrl.index;
		if (currentIndex === 0) return;
		setCurrentUrl(tempUrls[currentIndex - 1]);
	};

	const handleImageNext = () => {
		const currentIndex = currentUrl.index;
		if (currentIndex === (tempUrls.length-1)) return;
		setCurrentUrl(tempUrls[currentIndex + 1]);
	};


	const handleSubmit = (values) => {
		next(values, true);
	};

	const handleNext = (values) => {
		next(values);
	};

	const [toggle, isOpen] = useToggle();
	const [imageToggle, isImagesOpen] = useToggle();
	const [noImageToggle, isNoImagesOpen] = useToggle();
	const [deleteValue, setDeleteValue] = useState("");
	const [isReadOnly, setIsReadOnly] = useState(true);

	const handleDelete = () => {
		toggle();
	};

	const handleImageModal = () => {
		if (tempUrls.length < 1) {
			noImageToggle();
			return;
		}
		setCurrentUrl(tempUrls[0]);
		imageToggle();
	};

	const confirmClick = () => {
		if (deleteValue !== `Delete`) {
			Toast({ status: 'warning', message: 'Input text does not match' });
			return;
		}
		dispatch(deletePirRequest(data.id, data.pir));
		if (closeDrawer) closeDrawer();
		dispatch(resetPirRequestData());
		toggle();
	};

	const handleDownload = () => {
		const link = document.createElement('a');
		link.href = `data:image/png;base64,${currentUrl.Base64Url}`;
		link.download = 'image.png';
		link.click();
	};

	const checkNonExistingPir = (pirNo) => {
		const existingNo = allData.find(x => x.id === Number(pirNo));
		if (existingNo === undefined) return true;
		else return false;
	};

	const searchClickHandler = (e) => {
		if (!e || e === '') {
			return;
		}
		if (checkNonExistingPir(e)) {
			toggleExistingPir();
			return;
		}
		dispatch(getInspectionDetailsByPirId(e));
		dispatch(getPirDetailsById(e));
		dispatch(getImageUrls(e));
		navigate(`/pir/view/${e}`);
	};
	
	const searchChangeHandler = (e) => {
		setSearchValue(e);
	};

	const handleMultipleLocations = (multipleInspections) => {
		if (multipleInspections.Locations.length === 1) {
			const selectedInspection = inspectionData.find(x => x.LocationId === multipleInspections.Locations[0].PirLocationId);
			dispatch(updateLocationId(multipleInspections.Locations[0].PirLocationId));
			handleNavigation(selectedInspection);
		}
		else toggleChooseLocation();
	};

	const handleLocationSelection = (location) => {
		const selectedInspection = inspectionData.find(x => x.LocationId === location.PirLocationId);
		dispatch(updateLocationId(location.PirLocationId));
		handleNavigation(selectedInspection);
	};

	const handleNavigation = (inspectionDetails) => {
		if (inspectionDetails === undefined) {
			Toast({ status: 'warning', message: 'Inspection Unavailable' });
			return;
		}
		dispatch(updateSelectedInspection(inspectionDetails));
		navigate(`/pir/inspection/${inspectionDetails.id}`);
	};

	return (
		<>
			<SearchHeader
				label="PIR Number"
				onClick={searchClickHandler}
				onChange={searchChangeHandler}
				value={searchValue}
				isSearch={true}
				showSearch={showSearch}
				setShowSearch={e => { setShowSearch(e); setAutoHide(!autoHide); resetData();}}
			/>
			<span style={{fontSize: '25px', fontWeight: 'bold'}}>View Product Inspection Request</span>
			<br /> <br /> <hr />
			<Formik 
			initialValues={tempData} 
			validationSchema={validationSchema} 
			onSubmit={handleSubmit} 
			enableReinitialize 
			validateOnMount 
			id='viewPirForm'>
				{({ submitForm, errors, values, setFieldTouched }) => {
					return (
						<>
							<Form>
								<div className='px-4 pt-2 bg-tertiary-cool-4 sm:pt-4 sm:pXb-4 '>
									<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-11 2xl:grid-cols-12 gap-x-6 '>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 xl:!col-span-2 2xl:!col-span-1 min-w-fift'
											control='input'
											disabled={true}
											type='text'
											label='PIR Number'
											name='id'
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='input'
											type='text'
											label='Product'
											name='Product'
											disabled={isReadOnly}
										/>
										<FormikControl
											className='pb-4 !col-span-2 md:!col-span-2 lg:!col-span-3 xl:!col-span-6 2xl:!col-span-5'
											control='input'
											type='text'
											label='Part Description'
											name='Description'
											disabled={isReadOnly}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Date Code'
											name='DateCode'
											disabled={isReadOnly}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='input'
											type='text'
											label='VIN'
											name='Vin'
											disabled={isReadOnly}
										/>
										<FormikControl
											className='pb-4 !col-span-4 md:!col-span-4 lg:!col-span-5 xl:!col-span-5 2xl:!col-span-6'
											control='input'
											type='text'
											label='Issue Description'
											name='AdditionalDetails'
											disabled={isReadOnly}
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control='input'
											type='text'
											label='Vehicle Model'
											name='PlatformDisplay'
											disabled={isReadOnly}
										/>
										<FormikControl
											className="pb-4 !col-span-3 sm:!col-span-2 lg:!col-span-3 xl:!col-span-3 min-w-fsit"
											control='input'
											type='text'
											label='Location'
											name='LocationDisplay'
											disabled={isReadOnly}
										/>
										<FormikControl
											control='input'
											type='text'
											label='Reason'
											name='ReasonDisplay'
											disabled={isReadOnly}
										/>
										<FormikControl
											control='input'
											type='text'
											label='WERS Number'
											name='WERSNumber'
											disabled={isReadOnly}
										/>
										<FormikControl 
											control='input' 
											type='text' 
											label='Additional Contact Info' 
											name='AdditionalContactInfo' 
											disabled={isReadOnly} 
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='date'
											type='date'
											label='Create Date'
											disabled={true}
											name='AuditDateTime'
										/>
										<FormikControl
											control='input'
											type='text'
											label='Created By'
											name='AuditUserDisplay'
											disabled={true}
										/>
										<FormikControl
											control='input'
											type='text'
											label='Requested By'
											name='RequesterDisplay'
											disabled={isReadOnly}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='Status'
											name='StatusDisplay'
											disabled={true}
										/>
										<FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1'
											control='input'
											type='text'
											label='KPI'
											name='KpiLabel'
											disabled={true}
										/>
										{isClosedDate && <FormikControl
											className='pb-4 !col-span-2 sm:!col-span-1 lg:!col-span-2'
											control='date'
											type='date'
											label='Close Date'
											disabled={true}
											name='CloseDateLabel'
										/>}
										<Button
											type='button'
											disabled={false}
											onClick={handleImageModal}
											className={`bg-tertiary-cool-2 lg:!col-span-2 place-self-center flex items-center`}
										>
											Show Images
										</Button>
									</div>
								</div>
							</Form>
							<hr />
						</>
					);
				}}
			</Formik>
			<hr />
			<div className='grid grid-cols-3 gap-4px-4 py-3 bg-white text-left sm:px-6 rounded-b'>
				<Button
					type='button'
					onClick={() => {
						return handleMultipleLocations(tempData);
					}}
					className={`bg-tertiary-cool-2 col-start-3 place-self-end flex items-center`}
				>
					{/* {isMultipleLocation ? 'Choose Location' : <>Inspection <ArrowRightIcon className='h-4 ml-2' /></>} */}
					Inspection
					<ArrowRightIcon className='h-4 ml-2' />
				</Button>
			</div>
			{isImagesOpen && (
				<Modal isOpen={isImagesOpen} toggle={imageToggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Attached Images</ModalHeader>
					<ModalBody>
						<div className=' bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								<img src={`data:image/png;base64,${currentUrl.Base64Url}`}/>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
							<Button onClick={handleImagePrev} disabled={currentUrl.index === 0}>
								Prev
							</Button>
							<Button onClick={handleImageNext} disabled={currentUrl.index === tempUrls.length-1}>
								Next
							</Button>
					</ModalFooter>
					<ModalFooter>
							<Button onClick={imageToggle} color='danger'>
								Close
							</Button>
							<div>
								<b>Total images - </b> {noOfImages}
							</div>
							<Button onClick={handleDownload}>
								Download
							</Button>
					</ModalFooter>
				</Modal>
			)}
			{isNoImagesOpen && (
				<Modal isOpen={isNoImagesOpen} toggle={noImageToggle} animate={true} closeOnClickOutside={false}>
					<ModalHeader>Attached Images</ModalHeader>
					<ModalBody>
						<div className=' bd-highlight mb-3'>
							<div className='p-2 bd-highlight'><span>No Images Uploaded for the selected PIR</span>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
							<Button onClick={noImageToggle} color='danger'>
								Close
							</Button>
					</ModalFooter>
				</Modal>
			)}
			{isExistingPirOpen && (
				<Modal isOpen={isExistingPirOpen} toggle={toggleExistingPir} animate={true} closeOnClickOutside={true}>
					<ModalHeader>Incorrect PIR Number</ModalHeader>
					<ModalBody>
						<div className='d-flex flex-row bd-highlight mb-3'>
							<div className='p-2 bd-highlight'>
								<p>The PIR Number you searched for does not exist</p>
							</div>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button onClick={toggleExistingPir} color='danger' className='ignore-click-outside'>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			)}
			{isChooseLocationOpen && (
				<Modal isOpen={isChooseLocationOpen} toggle={toggleChooseLocation} animate={true} closeOnClickOutside={false}>
					<ModalHeader>View Inspection Details</ModalHeader>
					<ModalBody>
					<div className='flex fle justify-between items-center'>
						<table className={`${tableStyles.table}`}>
							<thead className={tableStyles.thead.base}>
							<tr>
								<th>Location Name</th>
								<th>Status</th>
								<th>Select</th>
							</tr>
							</thead>
							<tbody>
							{tempData.Locations.length > 0 &&
								tempData.Locations.map((location, i) => (
								<tr key={i} className={tableStyles.tbody.tr}>
									<td> {dropdownsData.Locations.find(x => x.id === location.PirLocationId).Name
										+ ' (' + dropdownsData.Locations.find(x => x.id === location.PirLocationId).Code + ')'}
									</td>
									<td>
										<StatusPill value={location.Status} />{" "}
									</td>
									<td className={tableStyles.tbody.td.actions}>
										{location.Status !== 'New' && <Button
											color="primary"
											className={`bg-tertiary-cool-2 col-start-3 place-self-end flex items-center`}
											size="sm"
											onClick={() => handleLocationSelection(location)}
											disabled={profileData.role === 'View'}
										>
											Select
											<ArrowRightIcon className='h-4 ml-2' />
										</Button>}
									</td>
								</tr>
								))}
							</tbody>
						</table>
						</div>
					</ModalBody>
					<ModalFooter>
							<Button onClick={toggleChooseLocation} color='danger'>
								Close
							</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

export default EditPIR;