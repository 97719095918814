import { createSlice } from '@reduxjs/toolkit';

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: {
		data: null,
		loading: true
	},
	reducers: {
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		replaceDashboard: (state, action) => {
			state.data = action.payload;
		}
	}
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
