const style = {
	formControl: 'pb-4 col-span-2 sm:col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-3 2xl:col-span-2 min-w-fit',
	label: 'flex items-center text-secondary-1 text-sm font-bold leading-tight tracking-normal mb-2 whitespace-nowrap pr-4',
	radioLabel: 'text-secondary-1 text-sm font-bold leading-tight tracking-normal mb-2 ml-2 mr-6 block pt-2',
	checkLabel: 'text-secondary-1 text-sm font-bold leading-tight tracking-normal mb-2 ml-2 mr-6 block pt-2',
	checkbox:
		'w-full h-6 flex-none text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-1 dark:bg-gray-700 dark:border-gray-600 focus:!bg-red-600',
	checkboxChecked: 'checkbox--active !bg-red-800',
	input: 'flex flex-wrap items-center rounded w-full h-10 py-0 px-2 !border-secondary-2-5 outline-none !border !box-border focus:border-box focus:border focus:!border-secondary-2 hover:!border-secondary-2',
	// select: 'flex flex-wrap items-center rounded w-full h-10 py-0 px-2 !border-secondary-3 outline-none !border !box-border focus:border-box focus:border focus:!border-secondary-2 hover:!border-secondary-2',
	tableInput:
		'flex flex-wrap items-center !w-full h-10 py-0 px-2 bg-transparent outline-none !border border-transparent !box-border focus:border-box focus:border focus:!border-secondary-2 hover:!border-seconddary-2',
	tableInputlabel: 'flex items-center text-secondary-1 text-sm font-bold leading-tight tracking-normal whitespace-nowrap pl-2 pr-4 w-64',
	error: 'text-red-600',
	button: 'p-2 mb-1 block rounded w-24 text-primary-2 bg-tertiary-cool-2 disabled:bg-gray-400 disabled:!text-gray-500 focus:ring focus:ring-offset focus:ring-bg-tertiary-cool-2 focus:ring-offset-bg-tertiary-cool-2 hover:bg-tertiary-cool-1'
};

module.exports = style;
