import { postRequest } from '../axios';
import { genericEmailActions } from './genericEmailSlice';
import Toast from '../components/ui/Toast';
import { globalActions } from './globalSlice';

export const resetGenericEmail = () => {
	return (dispatch) => {
		dispatch(genericEmailActions.resetGenericEmail());
	};
};

export const replaceGenericEmail = (data) => {
	return (dispatch) => {
		dispatch(genericEmailActions.replaceGenericEmail(data));
	};
};

export const sendGenericEmail = (genericemail) => {
	return async (dispatch) => {
		try {
			dispatch(globalActions.setLoading({ loading: true, label: 'Sending Email' }));
			const response = await postRequest('genericemail/', genericemail);
			Toast({ status: 'success', message: 'Email sent!' });
			dispatch(genericEmailActions.resetGenericEmail());
		} catch (error) {
			console.error('sendGenericEmail: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(genericEmailActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		} finally {
			dispatch(globalActions.setLoading({ loading: false }));
		}
	};
};
