import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import Layout from './components/layout/Layout';
import Home from './pages/Home';
import Links from './pages/dashboard/Links';
import Login from './pages/auth/Login';
import NotFound from './pages/NotFound';
import NotAllowed from './pages/NotAllowed';
import NotLoggedIn from './pages/NotLoggedIn';
import NetworkError from './pages/NetworkError';
import Settings from './pages/settings/Settings';
import GenericEmail from './pages/email/GenericEmail';
import Users from './pages/settings/Users';
import PirEdit from './pages/pir/Edit';
import PirAllData from './pages/pir/AllData';
import PirEmail from './pages/pir/Issue';
import Sample from './pages/sample/Sample';

import { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import Spinner from './packages/spinner/Spinner';
import { getUser } from './store/authActions';
import PrivateRoute from './components/PrivateRoute';
import { ResponseInterceptor } from './axios';

import { Role } from './data/roles';
import Register from './pages/auth/Register';

import { getAllDropdowns } from './store/dropdownActions';
import { getAllPirRequestData, getPirDetailsById, resetAllPirRequestData, resetUploaded } from './store/pirRequestActions';
import { getActiveProfile } from './store/profileActions';
import EditPIR from './pages/pir/form/EditPIR';
import ViewInspection from './pages/pir/form/ViewInspection';
import { getImageUrls, getInspectionDetailsByPirId } from './store/pirInspectionActions';

const App = (props) => {
	const { buildType } = props;
	const loader = useSelector((state) => state.global);
	const loading = useSelector((state) => state.auth.loading);
	const networkError = useSelector((state) => state.auth.networkError);
	const profileData = useSelector((state) => state.profile.profilesData);
	const loginSuccess = useSelector((state) => state.profile.loginSuccess);
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();
	const [pirNumber, setPirNumber] = useState(null);

	const [persist, setPersist] = useState(localStorage.getItem('persist') || false);

	useEffect(() => {
		dispatch(getActiveProfile());
	}, [dispatch]);

	useEffect(() => {
		const url = window.location.href;
		const match = url.match(/\/pir\/view\/(\d+)$/);
		
		if (match) {
			const number = parseInt(match[1]);
			setPirNumber(number);
			dispatch(getPirDetailsById(number));
			dispatch(getImageUrls(number));
			dispatch(getInspectionDetailsByPirId(number));
		}
	  }, [loginSuccess]);

	useEffect(() => {
		if (loginSuccess) dispatch(getUser());
	}, [loginSuccess]);

	useEffect(() => {
		if (loginSuccess) dispatch(getAllDropdowns());
	}, [loginSuccess]);

	useEffect(() => {
		if (loginSuccess) {
			dispatch(resetUploaded());
			dispatch(getAllPirRequestData());
			return () => {
				dispatch(resetAllPirRequestData());
			};
		}
	}, [loginSuccess]);

	useEffect(() => {
		setPersist(localStorage.setItem('persist', location.pathname));
	}, [location, persist]);

	let routes = (
		<Routes>
			<Route path='/' element={<Home />} />
			<Route path='/login' element={<Login />} />
			{networkError && <Route path='*' element={<NetworkError />} />}
		</Routes>
	);

	if (!loading) {
		routes = (
			<Routes>
				<Route path='/' element={<Home />} />
				{profileData.username && <Route path='/register' element={<Register />} />}
				{profileData.username && <Route path='/sample' element={<Sample />} />}
				{profileData.username && <Route element={<PrivateRoute roles={[Role.user, Role.admin]} />}>
					<Route path='/genericemail' element={<GenericEmail />} />
				</Route>}
				{profileData.username && <Route element={<PrivateRoute roles={[Role.user, Role.admin, Role.view]} />}>
					<Route path='/settings' element={<Settings />} />
					<Route path='/pir/create' element={<PirEdit />}>
					</Route>
					<Route path='/pir/view' element={<EditPIR />}>
						<Route path=':pirNumber' element={<EditPIR />} />
					</Route>
					<Route path='/pir/inspection' element={<ViewInspection />}>
						<Route path=':inspectionNumber' element={<ViewInspection />} />
					</Route>
					<Route path='/pir/alldata' element={<PirAllData />} />
					<Route path='/pir/email' element={<PirEmail />} />
					<Route path='/pir/links' element={<PirEmail />} />
				</Route>}
				{profileData.username && <Route element={<PrivateRoute roles={[Role.admin]} />}>
					<Route path='/users' element={<Users />} />
				</Route>}
				{profileData.username && <Route path='/unauthorised' element={<NotAllowed />} />}
				{profileData.username && <Route path='/links' element={<Links />} />}
				{profileData.username && <Route path='*' element={<NotFound />} />}
				{!profileData.username && <Route path='*' element={<NotLoggedIn />} />}
			</Routes>
		);
	}

	const devLabel = `${
		buildType === 'PROD' ? 'hidden' : 'fixed'
	} block bottom-4 opacity-30 pointer-events-none -right-24 origin-bottom -rotate-45 bg-tertiary-cool-3 p-6 w-56 text-center text-2xl font-bold`;

	return (
		<Layout>
			<Toaster
				position='top-right'
				toastOptions={{
					className: 'shadow-md !rounded-none !min-w-[540px]'
				}}
			/>
			<Spinner isOpen={loader.loading} label={loader.label} />
			{routes}
			<div className={devLabel}>{buildType}</div>
			<ResponseInterceptor />
		</Layout>
	);
};

App.propTypes = {
	buildType: PropTypes.string
};

export default App;
