import { getRequest, putRequest, postRequest, deleteRequest } from '../axios';
import Toast from '../components/ui/Toast';
import { globalActions } from './globalSlice';
import { pirInspectionActions } from './pirInspectionSlice';

export const resetAllPirInspectionData = () => {
	return (dispatch) => {
		dispatch(pirInspectionActions.resetPirRequestData());
	};
};

export const getAllPirInspectionData = (partNumber) => {
	return async (dispatch) => {
		try {
			let url = 'pirinspection/';
			let label = 'Fetching All PIR Inspections';
			dispatch(globalActions.setLoading({ loading: true, label: label }));
			const pirInspectionData = await getRequest(url);
			dispatch(globalActions.setLoading({ loading: false }));
			dispatch(pirInspectionActions.replacePirRequestAllData({ allData: pirInspectionData || [] }));
		} catch (error) {
			dispatch(globalActions.setLoading({ loading: false }));
			if (error.message.substring(0, 2) !== '40') Toast({ status: 'error', message: error.message });
		}
	};
};

export const partsInspection = (num) => {
	// if(!num) return;
	return async (dispatch) => {
		try {
		const details = await getRequest('pirinspection/?RequestHeaderId=' + num);
		dispatch(pirInspectionActions.replacePartsInspection(details));
		} catch (error) {
		Toast({ status: "error", message: error.message });
		}
	};
};

export const getImageUrls = (num) => {
	// if(!num) return;
	return async (dispatch) => {
		try {
		dispatch(globalActions.setLoading({ loading: true, label: 'Loading...' }));
		const urls = await getRequest('url/?RequestHeaderId=' + num);
		dispatch(pirInspectionActions.replaceUrls(urls));
		dispatch(globalActions.setLoading({ loading: false }));
		} catch (error) {
		Toast({ status: "error", message: error.message });
		}
	};
};

export const updatePirInspection = (id, data) => {
	return async dispatch => {
		try {
		const response = await putRequest('pirinspection/'+ id +'/', data);
		Toast({ status: "success", message: 'Data Updated' });
		dispatch(pirInspectionActions.replaceUpdatedInspection(response));
		} catch (error) {
		// console.error("updateSpoComment: " + error.message)
		Toast({ status: "error", message: error.message });
		}
	};
};

export const createPirInspection = (data) => {
	return async dispatch => {
		try {
		const response = await postRequest('pirinspection/', data);
		Toast({ status: "success", message: 'Inspection Created!' });
		dispatch(pirInspectionActions.replaceUpdatedInspection(response));
		} catch (error) {
		Toast({ status: "error", message: error.message });
		}
	};
};

export const updateLocationId = (data) => {
	return async dispatch => {
		dispatch(pirInspectionActions.updateLocationId(data));
	};
};

export const updateSelectedInspection = (data) => {
	return async dispatch => {
		dispatch(pirInspectionActions.updateSelectedInspection(data));
	};
};

export const getInspectionDetailsByPirId = (pirId) => {
	return async (dispatch) => {
		try {
			const details = await getRequest('pirinspection/?RequestHeaderId=' + pirId);
			dispatch(pirInspectionActions.updateInspectionByPirId(details));
			if (details.length > 0) {
				dispatch(pirInspectionActions.updateMultipleLocation(true));
			}
		} catch (error) {
			Toast({ status: "error", message: 'Error in getting PIR Details' });
		}
	};
};