import { getRequest, postRequest } from "../axios";
import { pirIssueActions } from "./pirIssueSlice";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";

export const resetPirIssue = () => {
  return dispatch => {
    dispatch(pirIssueActions.resetPirIssue());
  };
};

export const setPirNumberAction = (pirNumber) => {
  return dispatch => {
    dispatch(pirIssueActions.setPirNumber(pirNumber));
  };
};

export const replacePirIssue = (data) => {
  return dispatch => {
    dispatch(pirIssueActions.replacePirIssue(data));
  };
};

export const resetPir = () => {
  return (dispatch) => {
    dispatch(pirIssueActions.resetPir());
  };
};

export const resetEmailData = () => {
  return (dispatch) => {
    dispatch(pirIssueActions.resetPirIssue());
  };
};

export const getPirEmailData = (pirId) => {
  return async (dispatch) => {
	  try {
    dispatch(globalActions.setLoading({ loading: true, label: 'Loading Email Details...' }));
		const details = await getRequest('faultymaterail/'+pirId+'/');
		dispatch(pirIssueActions.replacePirIssue(details));
    dispatch(globalActions.setLoading({ loading: false }));
	  } catch (error) {
		Toast({ status: "error", message: 'PIR Not Found' });
    dispatch(globalActions.setLoading({ loading: false }));
	  }
	};
};



export const getPirIssueEmailData = (param, byId) => {
  let url = 'pirissue/';

  if (byId) url += 'id/';
  return async dispatch => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: 'Fetching SPO email content' }));
      const pirIssueEmailData = await getRequest(url + param);
      dispatch(pirIssueActions.replacePirIssue(pirIssueEmailData));
    } catch (error) {
      console.error("getPirIssueEmailData: " + error.message);
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(pirIssueActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40")
        Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, }));
    }
  };
};

export const issuePirEmail = (pirIssue) => {
  return async dispatch => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: 'Sending email...' }));
      const response = await postRequest('faultymaterail/', pirIssue);
      Toast({ status: "success", message: 'Email sent successfully!' });
      dispatch(pirIssueActions.resetPirIssue(response));
    } catch (error) {
      console.error("issuePirEmail: " + error.message);
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(pirIssueActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40")
        Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, }));
    }
  };
};