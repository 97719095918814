import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FormField from '../../packages/form/FormField';
import useLocalStorage from '../../packages/_utils/useLocalStorage';

const PageTitle = ({ header, label, showSearch, onClick }) => {
	const [localShowSearch] = useLocalStorage('show-search');
	const pir = useSelector((state) => state.pirRequest.data);

	return (
		<div className='mt-0 mb-6 text-primary-1 '>
			<h2 className='text-lg font-bold md:text-2xl flex max-w-fit'>
				<div className='mr-4'>
					{!pir.id ? (
						header
					) : (
						<>
							<span>PIR Project : {pir.pir} </span>
							<br />
						</>
					)}
				</div>
				{localShowSearch && (
					<div
						className={`flex gap-x-2 text-xs items-center text-primary-1 flex-row-reversse cursor-pointer ${showSearch && 'hidden'}`}
						onClick={onClick}
					>
						<FormField label={label} name='showSearch' type='checkbox' checked={showSearch} onClick={onClick} />
					</div>
				)}
			</h2>
			{pir.id && <h5 className='h6 py-2'>Project Title : {pir.spoTitle}</h5>}
		</div>
	);
};

export default PageTitle;

PageTitle.propTypes = {
	header: PropTypes.string,
	label: PropTypes.string,
	showSearch: PropTypes.bool,
	onClick: PropTypes.func
};
