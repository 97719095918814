import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from 'prop-types';
import Button from "../../packages/button/Button";
import { useSelector, useDispatch } from "react-redux";
// import { download } from "../../store/pirActions";
import { issuePirEmail } from "../../store/pirIssueActions";
import { MailIcon, PencilIcon } from "@heroicons/react/solid";

import Tippy from "@tippyjs/react";
// import { emailString } from './emailTemplate';
import RichTextEditor from "../../components/richTextEditor/RichTextEditor";
import { updateObject } from "../../utils/utility";
import SelectInput from "../../components/ui/SelectInput";
import useToggle from "../../packages/_utils/useToggle";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";

import { Role } from "../../data/roles";
import FormField from "../../packages/form/FormField";
import Toast from "../../components/ui/Toast";

const IssueEmail = ({ pirNumber }) => {
  const profileData = useSelector((state) => state.profile.profilesData);
  const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
  const storedPirNumber = useSelector((state) => state.pirIssue.storedPir);
  const emailSubjectString = 'Faulty Material Email for PIR Number: ' + storedPirNumber;
  const [emailSubject, setEmailSubject] = useState(emailSubjectString);
  const dispatch = useDispatch();

  let mailingLists = [];
	let apiMailingLists = [];
	let apiContacts = [];
  let emailToOptionsList = [];
  let emailCcOptionsList = [];
  let mailingGroupsOptionList = [];
	if (dropdownsData.Contacts.length > 0) {
    apiMailingLists = dropdownsData.MailingList;
		apiContacts = dropdownsData.Contacts;
		const contacts = dropdownsData.Contacts;
		contacts.forEach(element => {
			const pushData = { value: element.id, label: element.Email };
			emailToOptionsList.push(pushData);
      emailCcOptionsList.push(pushData);
		});
    const mailingGroups = dropdownsData.MailingGroup;
		mailingGroups.forEach(element => {
			const pushData = { value: element.id, label: element.Name };
			mailingLists.push(pushData);
			mailingGroupsOptionList.push(pushData);
		});
	}
  const [selectedMailingList, setSelectedMailingList] = useState('');
  const pir = {
    advisedOkToLaunchDate: null,
    auditDateTime: null,
    auditUserName: "",
    brand: "",
    currentVersionDate: null,
    engineer: null,
    fsaRef: null,
    id: null,
    initialSpoDate: null,
    ownerId: "",
    csqCommsLead: "",
    spo: null,
    spoTitle: "",
    spoTypeId: null,
    spoStatusId: null,
    targetLaunchDate: null,
    spoDetail: [],
    pirVersion: null
  };
  const pirIssue = useSelector((state) => state.pirIssue.data);
  const [editorData, setEditorData] = useState();
  const currentUser = useSelector((state) => state.auth.user);

  const [reIssueConfirmation, setReIssueConfirmation] = useState();

  // email options
  const [emailToOptions, setEmailToOptions] = useState(emailToOptionsList);
  const [emailCcOptions, setEmailCcOptions] = useState(emailCcOptionsList);
  const [selectedEmailToOptions, setSelectedEmailToOptions] = useState([]);
  const [selectedEmailCcOptions, setSelectedEmailCcOptions] = useState([]);

  // toggle options
  const [issueConfirmationToggle, issueConfirmationIsOpen] = useToggle();
  const [toListToggle, toListIsOpen] = useToggle();
  const [ccListToggle, ccListIsOpen] = useToggle();

  // Adding manually typed email addresses
  const selectHandle = (e, type) => {
    const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
		if (e.length > 0) {
			const checkEmail = e[e.length - 1];
			if (!emailRegex.test(checkEmail)) {
				Toast({ status: "warning", message: 'Invalid Email' });
				return;
			}
		}
    if (type === "to") {
      const newOption = e.filter(
        (m) => !emailToOptions.map((o) => o.label).includes(m)
      );
      if (newOption.length > 0)
        setEmailToOptions([
          ...emailToOptions,
          { value: newOption[0], label: newOption[0] },
        ]);
      setSelectedEmailToOptions(e);
    } else {
      const newOption = e.filter(
        (m) => !emailCcOptions.map((o) => o.label).includes(m)
      );
      if (newOption.length > 0)
        setEmailCcOptions([
          ...emailCcOptions,
          { value: newOption[0], label: newOption[0] },
        ]);
      setSelectedEmailCcOptions(e);
    }
  };

  const emailHandler = () => {
    const emailParams = {
      Subject: emailSubject,
      Body: editorData.replace(/\n/g, ''),
      ToEmail: selectedEmailToOptions,
      CcEmail: selectedEmailCcOptions,
      BccEmail: []
    };
    dispatch(issuePirEmail(emailParams));
    issueConfirmationToggle();
  };

  const handleToggle = (type) => {
    if (type === "toList") {
      if (ccListIsOpen) ccListToggle();
      toListToggle();
    } else {
      if (toListIsOpen) toListToggle();
      ccListToggle();
    }
  };

  const confirmClick = () => {
    emailHandler();
    issueConfirmationToggle();
  };

  const onSelectChangeHandler = (value, field) => {
		let updatedMailingList = updateObject(selectedMailingList, {
			[field]: value
		});
		setSelectedMailingList(updatedMailingList);
		let emailList = [];
		if (value) {
			const mailingListsFilter = apiMailingLists.filter((x) => x.MailingGroupId === value);
			mailingListsFilter.forEach(element => {
				const pushData = apiContacts.find((x) => x.id === element.ContactId).Email;
				emailList.push(pushData);
			});
			setSelectedEmailToOptions(emailList);
		}
		else {
			setSelectedEmailToOptions([]);
		}
	};

  return (
    <>
      {pirIssue && (
        <div className="p-3 bg-tertiary-cool-4 text-primary-1 m-2 rounded max-w-5xl">
          {currentUser.role !== Role.view ? (
            <div className="flex flex-col md:flex-row items-center bodrder-b border-secondary-2 mb-2">
              <div className='pr-4 h5 w-1/5'>Mailing Groups</div>
                <div className='flex-1 flex flex-col md:flex-row'>
                  <div className='w-full flex mx-2 py-2 items-center font-semibold'>
                    <SelectInput
                      className='border rounded w-60 mx-4'
                      name={'mailingGroups'}
                      value={selectedMailingList.mailingGroupId}
                      label={'Groups'}
                      options={mailingGroupsOptionList}
                      onChangeHandler={(e) => onSelectChangeHandler(e, 'mailingGroupId')}
                    />
                  </div>
                </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex mx-2 py-2 items-center font-semibold">
                  <p>
                    To:{" "}
                    <span className="pr-2">{`${
                      selectedEmailToOptions?.length ?? 0
                    } Email Addresses`}</span>
                  </p>
                  <Tippy
                    arrow={true}
                    content={
                      <span className="bg-secondary-2 text-sm rounded px-2 py-1 shadow text-primary-2">
                        View/Edit To: emails
                      </span>
                    }
                  >
                    <Button
                      type="button"
                      onClick={() => handleToggle("toList")}
                      className={`!p-1 my-1 cursor-pointer rounded-sm text-primary-2 hover:text-primary-1 ${
                        toListIsOpen
                          ? "!bg-tertiary-cool-2"
                          : "bg-tertiary-cool-1"
                      } hover:bg-tertiary-cool-2`}
                    >
                      <PencilIcon className="h-6 " />
                    </Button>
                  </Tippy>
                </div>
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex mx-2 py-2 items-center font-semibold">
                  <p>
                    Cc:
                    <span className="pr-2">{`${
                      selectedEmailCcOptions?.length ?? 0
                    } Email Addresses`}</span>
                  </p>
                  <Tippy
                    arrow={true}
                    content={
                      <span
                        className={`bg-secondary-2 text-sm rounded px-2 py-1 shadow text-primary-2`}
                      >
                        View/Edit Cc: emails
                      </span>
                    }
                  >
                    <Button
                      type="button"
                      onClick={() => handleToggle("ccList")}
                      className={`!p-1 my-1 cursor-pointer rounded-sm text-primary-2 hover:text-primary-1 ${
                        ccListIsOpen
                          ? "!bg-tertiary-cool-2"
                          : "bg-tertiary-cool-1"
                      } hover:bg-tertiary-cool-2`}
                    >
                      <PencilIcon className="h-6 " />
                    </Button>
                  </Tippy>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row items-center bodrder-b border-secondary-2 mb-2">
              <div className="pr-4 h5 w-1/3"> {pir.brand} Mailing List </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className="w-full flex mx-2 py-2 items-center text-red-600">
                  <p>You don't have the required access to email.</p>
                </div>
              </div>
            </div>
          )}
          {toListIsOpen && (
            <div className="bordser-b border-secondary-2 pb-4 mb-2">
              <SelectInput
                name={"ToList"}
                className={"text-sm"}
                isClearable={true}
                isCreatable={true}
                byLabel={true}
                isMulti={true}
                label={"To Email Addresses"}
                options={emailToOptions}
                value={selectedEmailToOptions}
                onChangeHandler={(e) => selectHandle(e, "to")}
              />
            </div>
          )}
          {ccListIsOpen && (
            <div className="borsder-b border-secondary-2 pb-4 mb-2">
              <SelectInput
                name={"CcList"}
                className={"text-sm"}
                isClearable={true}
                isCreatable={true}
                byLabel={true}
                isMulti={true}
                label={"Cc Email Addresses"}
                options={emailCcOptions}
                value={selectedEmailCcOptions}
                onChangeHandler={(e) => selectHandle(e, "cc")}
              />
            </div>
          )}
          <div className="flex flex-col md:flex-row border-y border-secondary-2 pt-2 pb-4 mb-2">
            <div className="w-32 font-bold h-6 mx-2 mt-3 text-primary-1">
              Subject:
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
              <div className="w-full flex-1 mx-2 py-2">
                <FormField
                  autoComplete='off'
                  name='subject'
                  placeholder='Subject'
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row border-b border-secondary-2 pb-4 mb-2">
            <div className="w-32 font-bold h-6 mx-2 mt-3 text-primary-1 ">
              Content:
              <br />
              <br />
              <div className="text-xs bg-white text-black p-2 -ml-2 rounded">
                <p className="pb-2">Information:</p>
                <ul className="list-disc bg-white">
                  <li className="text-green-700 pb-2 ml-3">
                    Green text is dynamic based on project data.
                  </li>
                  <li className="pb-2 ml-3">
                    The loaded green color is removed prior to emailing.
                  </li>
                  <li className="pb-2 ml-3">
                    Any formatting changes made by the user are maintained in the email.
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex-1 flex flex-col md:flex-row">
              <div className="w-full flex-1 mx-2 py-2 dark:text-primary-2">
                <RichTextEditor
                  originalData={pirIssue}
                  editorData={(e) => setEditorData(e)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row mb-2">
            <div className="flex-1 flex flex-col md:flex-row items-center">
              {!profileData.view ? (
                <>
                  <div className="w-fuldl fdlex-1 mx-2 py-2 ">
                    <Button
                      onClick={issueConfirmationToggle}
                      className="flex items-center"
                      disabled={selectedEmailToOptions.length===0 && selectedEmailCcOptions.length===0}
                    >
                      <MailIcon className="h-6 w-6 mr-4 " />
                      Send Email
                    </Button>
                  </div>
                  <p className="text-sm">
                    This will send the above formatted{" "}
                    <span className="font-semibold">'Email'</span>
                  </p>
                </>
              ) : (
                <p className="text-sm text-red-500 mt-3">
                  You don't have the required access to Send Email
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      {issueConfirmationIsOpen && (
        <Modal
          isOpen={issueConfirmationIsOpen}
          toggle={issueConfirmationToggle}
          animate={true}
          closeOnClickOutside={false}
        >
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            <div className="ml-3">
              Are you sure you want to send the email?
            </div>
            </ModalBody>
          <ModalFooter>
            <Button
              onClick={issueConfirmationToggle}
              color="danger"
              className="ignore-click-outside"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmClick}
              color="primary"
              className="mr-1 ignore-click-outside"
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default IssueEmail;

IssueEmail.propTypes = {
	pirNumber: PropTypes.func
};