import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';

import style from './Styles';

const Input = (props) => {
	const { label, name, className, disabled, labelClass, required, ...rest } = props;
	return (
		<div className={`${className || style.formControl}`}>
			<label htmlFor={name} className={`${style.label} ${labelClass}`}>
				{label} {required && <span style={{ color: 'red' }}>&nbsp;*</span>}
			</label>
			{/* <Field id={name} name={name} {...rest} className={style.input} /> */}
			<Field name={name} {...rest}>
				{({ field }) => {
					return <input {...field} disabled={disabled} className={style.input} {...rest} value={field.value || ''} />;
				}}
			</Field>
			<ErrorMessage component={TextError} name={name} />
		</div>
	);
};

export default Input;

Input.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	labelClass: PropTypes.string,
	required: PropTypes.bool
};
