import { Tab, Tabs } from '../../packages/tabs/Tabs';
import SimpleCard from '../../components/ui/SimpleCard';

// import Markets from './Markets';
// import Users from './Users';
import MailingGroup from './MailingGroup';
import MailingList from './MailingList';
import Config from './Config';
import Contacts from './Contacts';
import Users from './Users';
import Reason from './Reason';
import Status from './Status';
import Waiting from './Waiting';
import Rejection from './Rejection';
import Region from './Region';
import VehiclePlatform from './VehiclePlatform';
import Location from './Location';
//const MainDetails = lazy(() => import('./MainDetails'));

export const tableStyles = {
	table: 'table table-striped min-w-fit dark:!text-primary-2 mt-2',
	thead: {
		base: 'dark:bg-secondary-4 dark:!text-primary-1',
		tr: 'dark:bg-secondary-3 dark:odd:bg-secondary-2 dark:odd:!text-inherit h-20',
		th: {
			actions: 'text-right'
		}
	},
	tbody: {
		base: '',
		tr: 'dark:bg-secondary-2-5 dark:odd:bg-secondary-2 dark:odd:!text-inherit border-b dark:border-b-secondary-3',
		td: {
			actions: 'text-right w-32'
		}
	}
};

const Settings = () => (
	<SimpleCard className='p-4 '>
		<Tabs>
			<Tab title='Contacts'>
				<Contacts />
			</Tab>
			<Tab title='Mailing Groups'>
				<MailingGroup />
			</Tab>
			<Tab title='Mailing Lists'>
				<MailingList />
			</Tab>
			<Tab title='Reason'>
				<Reason />
			</Tab>
			{/* <Tab title='Status'>
				<Status />
			</Tab> */}
			<Tab title='Waiting'>
				<Waiting />
			</Tab>
			<Tab title='Rejection'>
				<Rejection />
			</Tab>
			<Tab title='Vehicle Platform'>
				<VehiclePlatform />
			</Tab>
			<Tab title='Location'>
				<Location />
			</Tab>
			<Tab title='Users'>
				<Users />
			</Tab>
			<Tab title='Config'>
				<Config />
			</Tab>
		</Tabs>
	</SimpleCard>
);

export default Settings;
