import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateObject } from "../../utils/utility";
import useToggle from "../../packages/_utils/useToggle";
import {
    getWaitings,
    createWaiting,
    updateWaiting,
    deleteWaiting,
} from "../../store/waitingActions";
import Button from "../../packages/button/Button";
import Icon from "../../components/ui/Icon";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "../../packages/modal/Modal";
import { tableStyles } from "./Settings";

import { Role } from "../../data/roles";
import Toast from "../../components/ui/Toast";

const initialWaiting = {
    id: 0,
    Name: "",
};

const Waiting = () => {
    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.profile.profilesData);
    const waitings = useSelector((state) => state.waiting.data);
    const currentUser = useSelector((state) => state.auth.user);

    useEffect(() => {
        dispatch(getWaitings());
    }, [dispatch]);

    const [toggle, isOpen] = useToggle();
    const [modalTitle, setModalTitle] = useState("");
    const [selectedWaiting, setSelectedWaiting] =
        useState(initialWaiting);

    const addClick = () => {
        setModalTitle("Create New Waiting");
        toggle();
        setSelectedWaiting(initialWaiting);
    };

    const createClick = () => {

        if (selectedWaiting.Name === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}

        dispatch(createWaiting(selectedWaiting));
        toggle();
    };

    const updateClick = () => {

        if (selectedWaiting.Name === "") {
			Toast({ status: "warning", message: 'All fields are mandatory' });
			return;
		}
        
        dispatch(updateWaiting(selectedWaiting));
        toggle();
    };

    const editClick = (waiting) => {
        setModalTitle("Edit Waiting");
        setSelectedWaiting(waiting);
        toggle();
    };

    const deleteClick = (id) => {
        dispatch(deleteWaiting(id));
    };

    const onChangeHandler = (e, field) => {
        let updatedWaiting = updateObject(selectedWaiting, {
        [field]: e.target.value,
        });
        setSelectedWaiting(updatedWaiting);
    };

    return (
        <>
        {currentUser.role !== Role.view && (
            <Button onClick={addClick} color="primary" disabled={profileData.role === 'View'}>
                Add Waiting
            </Button>
        )}
        <table className={`${tableStyles.table} w-1/2`}>
            <thead className={tableStyles.thead.base}>
            <tr>
                <th>Name</th>
                {currentUser.role !== Role.view && (
                <th className={tableStyles.thead.th.actions}>Actions</th>
                )}
            </tr>
            </thead>
            <tbody>
            {waitings &&
                waitings.map((waiting, i) => (
                <tr key={i} className={tableStyles.tbody.tr}>
                    <td>{waiting.Name}</td>
                    {currentUser.role !== Role.view && (
                    <td className={tableStyles.tbody.td.actions}>
                        <Button
                        color="primary"
                        className="btn mr-1"
                        size="sm"
                        onClick={() => editClick(waiting)}
                        disabled={profileData.role === 'View'}
                        >
                        <Icon iconName="Pencil" />
                        </Button>
                        <Button
                        color="danger"
                        className="btn mr-1"
                        size="sm"
                        onClick={() => deleteClick(waiting.id)}
                        disabled={profileData.role !== 'Admin'}
                        >
                        <Icon iconName="Trash" />
                        </Button>
                    </td>
                    )}
                </tr>
                ))}
            </tbody>
        </table>

        <Modal
            isOpen={isOpen}
            toggle={toggle}
            animate={true}
            closeOnClickOutside={true}
        >
            <ModalHeader>{modalTitle}</ModalHeader>
            <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
                <div className="p-2 w-96 bd-highlight">
                <div className="input-group mb-3">
                    <span className="input-group-text">Waiting</span>
                    <input
                    type="text"
                    className="form-control"
                    value={selectedWaiting.Name}
                    onChange={(e) => onChangeHandler(e, "Name")}
                    />
                </div>
                </div>
            </div>
            </ModalBody>
            <ModalFooter>
            <Button onClick={toggle} color="danger">
                Cancel
            </Button>
            {selectedWaiting.id === 0 ? (
                <Button onClick={createClick} color="primary" className="mr-1">
                Create
                </Button>
            ) : null}

            {selectedWaiting.id !== 0 ? (
                <Button onClick={updateClick} color="primary" className="mr-1">
                Update
                </Button>
            ) : null}
            </ModalFooter>
        </Modal>
        </>
    );
};

export default Waiting;
