import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';

/* Extract Data */
import Papa from 'papaparse';
import 'jspdf-autotable';
/* Extract Data */

import style from './Formik/Controls/Styles';

import fileDownload from 'js-file-download';

import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, SearchIcon, DownloadIcon } from '@heroicons/react/solid';

import { classNames } from '../../utils/utility';
import Icon from './Icon';
import SearchInput from './SearchInput';
import SelectInput from './SelectInput';
import Button from '../../packages/button/Button';
import { getAllPirRequestData, setSearchValue } from '../../store/pirRequestActions';
import { useDispatch, useSelector } from 'react-redux';

export const AvatarCell = ({ value, column, row }) => {
	return (
		<div className='flex items-center'>
			<div className='flex-shrink-0 h-10 w-10'>
				<img className='h-10 w-10 rounded-full' src={row.original[column.imgAccessor]} alt='' />
			</div>
			<div className='ml-4'>
				<div className='text-sm font-medium'>{value}</div>
				<div className='text-sm'>{row.original[column.emailAccessor]}</div>
			</div>
		</div>
	);
};

AvatarCell.propTypes = {
	value: PropTypes.string,
	column: PropTypes.object,
	row: PropTypes.object
};

export const StatusPill = ({ value }) => {
	const status = value ? value.toString().toUpperCase() : 'unknown';

	let optionClass;
	switch (status) {
		case 'NEW':
			optionClass = 'bg-success   text-primary-2';
			break;
		case 'CLOSED':
			optionClass = 'bg-secondary   text-primary-2';
			break;
		case 'IN PROGRESS':
			optionClass = 'bg-tertiary-warm-3   text-primary-1';
			break;
		case 'CANCELLED':
			optionClass = 'bg-danger   text-primary-2';
			break;
		case 'VIEW':
			optionClass = 'bg-warning   text-primary-1';
			break;
		case 'USER':
			optionClass = 'bg-success   text-primary-2';
			break;
		case 'ADMIN':
			optionClass = 'bg-dark   text-primary-2';
			break;
		default:
			optionClass = 'bg-light  text-primary-1';
			break;
	}

	if (status === 'unknown') return '';

	return <span className={classNames('px-2.5 py-1 leading-wide font-semibold text-xs rounded-full shadow-sm', optionClass)}>{status}</span>;
};

StatusPill.propTypes = {
	value: PropTypes.any
};

// Define a default UI for filtering
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, initalSearch }) => {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);

	const dispatch = useDispatch();

	useEffect(() => {
		if (initalSearch) {
			const count = preGlobalFilteredRows.filter((p) => p.values.pir === initalSearch).length;
			if (count > 0) {
				setValue(initalSearch);
				onChange(initalSearch);
			} else {
				setValue();
				onChange();
				dispatch(setSearchValue());
			}
		}
	}, [dispatch, initalSearch, onChange, preGlobalFilteredRows]);

	const searchHandler = (value) => {
		setValue(value);
		onChange(value);
		if (value === '') {
			dispatch(setSearchValue());
		}
	};

	return (
		<div className={`flex flex-col`}>
			<SearchInput
				isSearch={true}
				name='Search'
				label='Search'
				icon={<SearchIcon />}
				className='w-36 shadow-sm'
				iconPosition='right'
				onClick={(e) => searchHandler(e)}
				onChange={(e) => searchHandler(e)}
				value={value ?? ''}
				placeholder='Search...'
			/>
		</div>
	);
};

GlobalFilter.propTypes = {
	preGlobalFilteredRows: PropTypes.array,
	globalFilter: PropTypes.string,
	setGlobalFilter: PropTypes.func,
	initalSearch: PropTypes.string
};

// This is a custom filter UI for selecting
// a unique option from a list
export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id, render, filter } }) => {

	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
	// Calculate the options for filtering
	// using the preFilteredRows
	const options = useMemo(() => {
		const options = new Set();
		preFilteredRows.forEach((row) => {
			options.add(row.values[id]);
		});
		return [...options.values()];
	}, [id, preFilteredRows]);

	let locationOptions = [];

	if (dropdownsData.Locations.length > 0) {
		const locations = dropdownsData.Locations;
		locations.forEach(element => {
			const pushData = { value: element.id, label: element.Name};
			locationOptions.push(pushData);
		});
	}

	const optionList = options.map((option, i) => {
		return { value: i, label: option };
	});

	const selectHandle = (value) => {
		setFilter(value || undefined);
	};

	useEffect(() => {
		if (filterValue && filterValue.length > 0 && filterValue[0] === undefined) filterValue.shift();
	}, [filterValue]);

	// const isMulti = filterOptions && filterOptions[id] ? filterOptions[id].isMulti : false || false;
	// Render a multi-select box
	return (
		<>
		{(id !== 'LocationLabel' && id !=='DateCode') && 
		<div className={`flex flex-col w-56 text-sm`}>
			<SelectInput
				byLabel={true}
				isMulti={filter === 'includesSome'}
				name={id}
				value={filterValue}
				label={render('Header')}
				options={optionList}
				onChangeHandler={(e) => selectHandle(e)}
			/>
		</div>}

		{id ==='DateCode' && 
		<div className={`flex flex-col w-32 text-sm`}>
			<SelectInput
				byLabel={true}
				isMulti={filter === 'includesSome'}
				name={id}
				value={filterValue}
				label={render('Header')}
				options={optionList}
				onChangeHandler={(e) => selectHandle(e)}
			/>
		</div>}

		{id === 'LocationLabel' && 
		<div className={`flex flex-col w-56 text-sm`}>
			<SelectInput
				byLabel={true}
				isMulti={filter === 'includesSome'}
				name={id}
				value={filterValue}
				label={render('Header')}
				options={locationOptions}
				onChangeHandler={(e) => selectHandle(e)}
			/>
		</div>}

		</>
	);
};

SelectColumnFilter.propTypes = {
	column: PropTypes.object
};

	export const DataExportOptions = ({ data }) => {
	const dropdownsData = useSelector((state) => state.dropdown.dropdownsData);
	// Manipulate Data Variable here
	var tempData = [];

	data.forEach(element => {
		let ReasonName = '';
		let RequestedByName = '';
		// Reason Name
		if (element.ReasonId) ReasonName = dropdownsData.Reasons.find(x => x.id === element.ReasonId).Name;
		// Requested By Name
		if (element.RequesterId) RequestedByName = dropdownsData.Users.find(x => x.id === element.RequesterId).first_name + ' ' + dropdownsData.Users.find(x => x.id === element.RequesterId).last_name;

		const pushData = {
			'PIR No': element.id,
			'Product': element.Product,
			'Description': element.Description,
			'VIN': element.Vin,
			'DateCode': element.DateCode,
			'Create Date': element.AuditDateTime,
			'Created By' : element.UserId,
			'Additional Contact Info': element.AdditionalContactInfo,
			'Issue Description': element.AdditionalDetails,
			'Reason': ReasonName,
			'Requested By': RequestedByName,
			'Status': element.Status,
			'WERS Number': element.WERSNumber,
			'Locations': element.LocationLabel,
			'Close Date': element.CloseDate,
			'KPI': element.Kpi
		};
		tempData.push(pushData);
	});

	return (
		<div className={`flex gap-x-2 text-sm`}>
			<Button
				className='bg-tertiary-cool-2 col-start-3 place-self-end flex items-center !px-2'
				tippy='Download'
				color='primary'
				onClick={() => {
					getAllExportFileBlob(tempData);
				}}
			>
				Export as CSV &nbsp;
				<DownloadIcon className='h-4 w-4' />
			</Button>
		</div>
	);
};

DataExportOptions.propTypes = {
	exportData: PropTypes.array,
	data: PropTypes.array
};

const getAllExportFileBlob = (data) => {
	// CSV example
	console.log('Export Data', data);
	const csvString = Papa.unparse({ data });
	const blob = new Blob([csvString], { type: 'text/csv' });
	fileDownload(blob, `pir-data.csv`);
};

export const DateRangeColumnFilter = ({ column: { filterValue = [], preFilteredRows, setFilter, id, Header } }) => {
	const [min, max] = useMemo(() => {
		let min = preFilteredRows.length && preFilteredRows.length > 1 ? new Date(preFilteredRows[0].values[id]) : new Date(0);
		let max = preFilteredRows.length && preFilteredRows.length > 1 ? new Date(preFilteredRows[0].values[id]) : new Date(0);

		preFilteredRows.forEach((row) => {
			const rowDate = new Date(row.values[id]);
			min = rowDate <= min ? rowDate : min;
			max = rowDate >= max ? rowDate : max;
		});

		return [min, max];
	}, [id, preFilteredRows]);

	return (
		<div className='flex'>
			<div>
				<label className={style.label}>{Header} From</label>
				<div className='flex'>
					<input
						className={style.input}
						min={min !== 'Invalid Date' && min.toISOString().slice(0, 10)}
						max={max !== 'Invalid Date' && max.toISOString().slice(0, 10)}
						onChange={(e) => {
							const val = e.target.value;
							// console.log(val)
							setFilter((old = []) => [val ? val : undefined, old[1]]);
						}}
						type='date'
						value={filterValue[0] ?? ''}
					/>
				</div>
			</div>
			<div>
				<label className={`${style.label} h-4`}>{''}</label>
				<div className='flex items-center h-10'>
					<p className=' font-bold text-secondary-1 text-sm px-1'>to</p>
				</div>
			</div>
			<div>
				<label className={`${style.label}`}>{Header} To</label>
				<div className='flex'>
					<input
						className={style.input}
						min={min !== 'Invalid Date' && min.toISOString().slice(0, 10)}
						max={max !== 'Invalid Date' && max.toISOString().slice(0, 10)}
						onChange={(e) => {
							const val = e.target.value;
							setFilter((old = []) => [old[0], val ? val.concat('T23:59:59.999Z') : undefined]);
						}}
						type='date'
						value={filterValue[1]?.slice(0, 10) || ''}
					/>
				</div>
			</div>
		</div>
	);
};

DateRangeColumnFilter.propTypes = {
	column: PropTypes.object
};

export const dateBetweenFilterFn = (rows, id, filterValues) => {
	const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
	const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
	if (ed || sd) {
		return rows.filter((r) => {
			let cellDate = new Date(r.values[id] + 'Z');

			if (ed && sd) {
				return cellDate.valueOf() >= sd.valueOf() && cellDate.valueOf() <= ed.valueOf();
			} else if (sd) {
				return cellDate.valueOf() === sd.valueOf();
			} else {
				return cellDate.valueOf() === ed.valueOf();
			}
		});
	} else {
		return rows;
	}
};

const Table = ({ columns, data, filterValue, title, getByPartNumber }) => {

	const allRecordCount = useSelector((state) => state.pirRequest.allRecordCount);
	const dispatch = useDispatch();

	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		rows,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		exportData
	} = useTable(
		{
			columns,
			initialState: {
				sortBy: [
					{
						id: 'id',
						desc: true
					}
				]
			},
			data,
			// defaultColumn,
			dateBetweenFilterFn
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		usePagination
		// useExportData
	);

	const [pageSize, setValue] = useState(10);

	const optionList = [5, 10, 20, 50, 100].map((option, i) => {
		return { value: option, label: `Show ${option} Rows` };
	});

	const selectHandle = (value) => {
		const firstPageNumber = 1;
		var result = optionList.find((option) => option.value === value);
		if (!result) {
			setValue(10);
			setPageSize(10);
			dispatch(getAllPirRequestData(firstPageNumber, 10));
			return;
		}
		setValue(result.value);
		setPageSize(result.value);
		dispatch(getAllPirRequestData(firstPageNumber, result.value));
	};

	const { pageIndex } = state;

	let pageInfo = 'No records';
	let recordInfo = '';

	if (rows.length > 0) {
		pageInfo = `Page: ${pageIndex + 1} of ${pageCount}`;

		recordInfo = `Record Count: ${allRecordCount}`;
		if (rows.length < preGlobalFilteredRows.length) recordInfo += `  of ${preGlobalFilteredRows.length}`;
	}

	const handleRefresh = () => {
		dispatch(getAllPirRequestData());
	};

	// Render the UI for your table
	return (
		<>
			<div className='flex gap-4 text-primary-1 font-bold pb-2 mb-2 items-center justify-between border-b border-secondary-1'>
				<p className='flex-none text-lg font-bold md:text-2xl'>{title}</p>
				<p className='flex-1 text-center'>{pageInfo}</p>
				<p className='flex-none'>{recordInfo}</p>
			</div>

			<div className='sm:flex gap-x-2 sm:max-w-none max-w-sm'>
				<GlobalFilter
					preGlobalFilteredRows={preGlobalFilteredRows}
					globalFilter={state.globalFilter}
					setGlobalFilter={setGlobalFilter}
					initalSearch={filterValue}
				/>
				{/* new */}
				{headerGroups.map((headerGroup) =>
					headerGroup.headers.map((column, i) => (column.Filter ? <div key={i}>{column.render('Filter')}</div> : null))
				)}
				{/* <Button
					type='button'
					disabled={false}
					onClick={handleRefresh}
					className={`bg-tertiary-cool-2 lg:!col-span-2 place-self-center flex items-center mt-4`}
				>
					Refresh
				</Button> */}
			</div>
			<div className='mt-2 flex flex-col sm:madx-w-none max-w-fitX'>
				<div className='-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8'>
					<div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8'>
						<div className='p-2 space-y-8 text-sm mx-auto shadow-sm overflow-hidden text-primary-1 bg-primary-2 sm:rounded'>
							<table {...getTableProps()} className='w-full whitespace-nowrap table-fixedX'>
								<thead className='flex-1 sm:flex-none'>
									{headerGroups.map((headerGroup, i) => (
										<tr key={i} {...headerGroup.getHeaderGroupProps()} className='hidden md:table-row'>
											{headerGroup.headers.map((column, i) => (
												// Add the sorting props to control sorting. For this example
												// we can add them into the header props
												<th
													key={i}
													scope='col'
													className={`${
														column.className ?? ''
													} bg-tertiary-cool-3 border !border-tertiary-cool-2-5 first:roundfed-tl-lg last:roundefd-tr-lg group first:!text-center px-1 md:px-1 text-left text-xs font-medium uppercase tracking-wider`}
													{...column.getHeaderProps(column.getSortByToggleProps())}
												>
													<div className={`flex items-cendter justify-between`}>
														<span className={`${column.className ?? ''} px-1 h-10 whitespace-normal flex items-center`}>
															{column.render('Header')}
														</span>
														{/* Add a sort direction indicator */}
														<span className='w-4 h-10 flex flex-none items-center'>
															{column.isSorted ? (
																column.isSortedDesc ? (
																	<Icon iconName='ArrowDown' className='w-4 h-4 text-red-800' />
																) : (
																	<Icon iconName='ArrowUp' className='w-4 h-4 text-red-400' />
																)
															) : (
																<Icon
																	iconName='SwitchVertical'
																	className='w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100'
																/>
															)}
														</span>
													</div>
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()} className='flex-1 sm:flex-none bg-secondary-4'>
									{page.map((row, i) => {
										// new
										prepareRow(row);
										return (
											<tr
												{...row.getRowProps()}
												className={`h-12 hover:bg-secondary-3 odd:bg-secondary-3-5`} // ${i % 2 === 0 && 'bg-secondary-4'}
											>
												{row.cells.map((cell, i) => {
													return (
														<td
															key={i}
															{...cell.getCellProps()}
															className={`p-1 md:p-3 md:table-cell md:w-auto flex flex-row border !border-secondary-3`}
															role='cell'
														>
															{cell.column.Cell.name === 'defaultRenderer' ? (
																<>
																	<div className='text-sm md:hidden pr-2 pl-1 font-bold text-secondary-fg-2 whitespace-normal'>
																		{cell.column.Header}:
																	</div>
																	<div className='text-sm md:pr-0 pl-1 pr-2 max-w-[500px] whitespace-normal'>
																		{cell.render('Cell')}
																	</div>
																</>
															) : (
																<>
																	<div className='text-sm md:hidden pr-2 pl-1 font-bold'>{cell.column.Header}</div>
																	<div className='text-sm md:pr-0 pl-1 pr-2 max-w-[500px] whitespace-normal'>
																		{cell.render('Cell')}
																	</div>
																</>
															)}
														</td>
													);
												})}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{/* Export Data / Row Display / Pagination */}
			<div className='py-3 flex items-center justify-between'>
				<div className='flex-1 flex justify-between sm:hidden'>
					<Button color='secondary' onClick={() => previousPage()}>
						Previous
					</Button>
					<Button color='secondary' onClick={() => nextPage()}>
						Next
					</Button>
				</div>
				<div className='hidden sm:flex-1 sm:flex gap-x-2 justify-end sm:items-center sm:justify-between'>
					<DataExportOptions exportData={exportData} data={data} />
					<div className='flex gap-x-2'>
						<div className={`flex flex-col w-64 text-sm`}>
							<SelectInput 
								name='pageSize' 
								menuPlacement='top' 
								value={pageSize} 
								options={optionList} 
								onChangeHandler={(e) => selectHandle(e)} 
							/>
						</div>
						<nav className='relative z-0 flex rounded-md shadow-sm space-x-1' aria-label='Pagination'>
							<Button className='!px-2' tippy='First' color='secondary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								<ChevronDoubleLeftIcon className='h-5 w-5' aria-hidden='true' />
							</Button>
							<Button className='!px-2' tippy='Previous' color='secondary' onClick={() => previousPage()} disabled={!canPreviousPage}>
								<ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
							</Button>
							<Button className='!px-2' tippy='Next' color='secondary' onClick={() => nextPage()} disabled={!canNextPage}>
								<ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
							</Button>
							<Button className='!px-2' tippy='Last' color='secondary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								<ChevronDoubleRightIcon className='h-5 w-5' aria-hidden='true' />
							</Button>
						</nav>
					</div>
				</div>
			</div>
		</>
	);
};

Table.propTypes = {
	value: PropTypes.string,
	columns: PropTypes.array,
	data: PropTypes.array,
	preGlobalFilteredRows: PropTypes.object,
	filterValue: PropTypes.string,
	title: PropTypes.string,
	getByPartNumber: PropTypes.func
};

export default Table;
