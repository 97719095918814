import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { locationActions } from './locationSlice';
import Toast from '../components/ui/Toast';

export const getLocations = () => {
	return async (dispatch) => {
		try {
			const locations = await getRequest('location');
			dispatch(locationActions.replaceLocations(locations));
		} catch (error) {
			console.error('getLocation: ' + error.message);
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(locationActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createLocation = (locationData) => {
	return async (dispatch) => {
		try {
			const location = await postRequest('location/', locationData);
			Toast({ status: 'success', message: `Added to Vehicle Platform!` });
			dispatch(locationActions.updateLocation(location));
		} catch (error) {
			console.error('createLocation: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateLocation = (locationData) => {
	return async (dispatch) => {
		try {
			const location = await putRequest(`location/${locationData.id}/`, locationData);
			Toast({ status: 'success', message: `Updated Vehicle Platform!` });
			dispatch(locationActions.updateLocation(location));
		} catch (error) {
			console.error('updateMailinglist: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteLocation = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`location/${id}`);
			Toast({ status: 'success', message: `Vehicle Platform Deleted!` });
			dispatch(locationActions.removeLocation(id));
		} catch (error) {
			console.error('deleteLocation: ' + error.message);
			Toast({ status: 'error', message: error.message });
		}
	};
};
