import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import useClickOutside from '../../packages/_utils/useClickOutside';
import Transition from 'react-transition-group/Transition';

import { XIcon } from '@heroicons/react/solid';
import Button from '../button/Button';
import { createPortal } from 'react-dom';

const drawerRoot = document.getElementById('drawer-root');

const w = 'md:w-11/12 w-full';
const r = 'md:-right-11/12 -right-full';
const l = 'md:-left-11/12 -left-full';

const style = {
	body: `flex-shrink flex-grow p-4 overflow-y-scroll relative`,
	headerTitle: `text-2xl md:text-3xl text-primary-1 pl-6`,
	content: `relative flex flex-col bg-secondary-4 pointer-events-auto w-full transition duration-300 ease-out !shadow !shadow-black`,
	header: ` bg-primary-2 items-center justify-between h-20 flex flex-none border-b border-secondary-2`,
	overlay: `fixed top-0 left-0 z-30 w-screen h-screen bg-primary-1 duration-300 transition-opacity`,
	footer: `flex flex-wrap items-center justify-end p-3 border-t border-gray-300`,
	orientation: {
		left: `flex w-full h-full left-0 mx-0 my-0 absolute focus:outline-none `,
		right: `flex w-full h-full right-0 mx-0 my-0 absolute focus:outline-none `
	}
};

const animationTiming = {
	enter: 0,
	exit: 300
};

let drawerRef;

export const Drawer = ({ children, isOpen, toggle, closeOnClickOutside, position, preventBackgroundScroll, width }) => {
	// const ref = useRef(null);

	drawerRef = useClickOutside(() => {
		closeOnClickOutside && toggle(false);
	});

	// close drawer when you click on "ESC" key
	useEffect(() => {
		const handleEscape = (event) => {
			if (!isOpen) return;
			if (event.key === 'Escape') {
				toggle(false);
			}
		};
		document.addEventListener('keyup', handleEscape);
		return () => document.removeEventListener('keyup', handleEscape);
	}, [isOpen, toggle]);

	// hide scrollbar and prevent body from moving when drawer is open
	// put focus on drawer dialogue
	useEffect(() => {
		if (!isOpen || !preventBackgroundScroll) return;

		drawerRef.current?.focus();

		const html = document.documentElement;
		const scrollbarWidth = window.innerWidth - html.clientWidth;

		html.style.overflow = 'hidden';
		html.style.paddingRight = `${scrollbarWidth}px`;

		return () => {
			html.style.overflow = '';
			html.style.paddingRight = '';
		};
	}, [isOpen, preventBackgroundScroll]);

	const positionClasses = {
		container: {
			left: `fixed top-0 left-11/12 z-40 left-0 ${w} h-full m-0 overflow-hidden `,
			right: `fixed top-0 left-11/12 z-40 right-0 ${w} h-full m-0 overflow-hidden `
		}
	};

	const slideClasses = {
		entering: position === 'right' ? r : l,
		entered: position === 'right' ? 'right-0' : 'left-0',
		exiting: position === 'right' ? r : l,
		exited: position === 'right' ? r : l
	};

	const opacityClasses = {
		entering: 'opacity-0',
		entered: 'opacity-60',
		exiting: 'opacity-0',
		exited: 'opacity-0'
	};

	return createPortal(
		<Transition mountOnEnter unmountOnExit in={isOpen} timeout={animationTiming}>
			{(state) => {
				return (
					<div>
						<div
							className={`${style.overlay} ${opacityClasses[state]}`}
							onClick={() => {
								toggle(false);
							}}
						/>
						<div className={`${position === 'right' ? positionClasses.container.right : positionClasses.container.left}`}>
							<div
								aria-modal={true}
								className={`${position === 'right' ? style.orientation.right : style.orientation.left}`}
								ref={closeOnClickOutside ? drawerRef : null}
								role='dialog'
								tabIndex={-1}
							>
								<div className={`${style.content} ${slideClasses[state]}`}>{children}</div>
							</div>
						</div>
					</div>
				);
			}}
		</Transition>,
		drawerRoot
	);
};

export const DrawerHeader = ({ children, closeDrawer }) => {
	return (
		<div className={style.header}>
			<h4 className={style.headerTitle}>{children}</h4>
			<Button color='light' className='h-full !shadow-none !rounded-none group' onClick={closeDrawer}>
				<XIcon className='h-8 w-8 text-primadry-1 group-hover:!text-primary-2 duration-200' />
			</Button>
		</div>
	);
};

DrawerHeader.propTypes = {
	children: PropTypes.node,
	closeDrawer: PropTypes.func
};

export const DrawerBody = ({ children }) => {
	return <div className={style.body}>{children}</div>;
};

DrawerBody.propTypes = {
	children: PropTypes.node
};

export const DrawerFooter = ({ children }) => {
	return <div className={style.footer}>{children}</div>;
};

DrawerFooter.propTypes = {
	children: PropTypes.node
};
