import PropTypes from 'prop-types';

import { forwardRef } from 'react';
// import Tippy, { tippy } from '@tippyjs/react';

const style = {
	default: `text-primary-2 focus:outline-none shadfow-sm rounded px-3 py-1 font-medium transition ease-in duration-200 ignore-click-outside`,
	color: {
		primary: `bg-tertiary-cool-1 focus:ring-2 focus:ring-offset-2 focus:ring-tertiary-cool-1 disabled:opacity-40`,
		secondary: `bg-secondary-1 focus:ring-2 focus:ring-offset-2 focus:ring-secondary-1 disabled:opacity-40`,
		success: `bg-green-700 text-white focus:ring-2 focus:ring-offset-2 focus:ring-green-700 disabled:opacity-40`,
		danger: `bg-red-700 text-white focus:ring-2 focus:ring-offset-2 focus:ring-red-700 disabled:opacity-40`,
		dark: `bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:opacity-40`,
		light: `bg-primary-2 !text-primary-1 hover:!bg-primary-1 focus:ring-2 focus:ring-offset-2 focus:ring-primary-2  disabled:opacity-40`,
		warning: `bg-yellow-500 focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 disabled:opacity-40`,
		indigo: `bg-indigo-900 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 disabled:opacity-40`
	}
};

const Button = forwardRef(({ color, className, children, tippy, ...props }, ref) => {
	// const InputContainer = ({ children }) => {
	//   let container = <>{children}</>

	//   if (tippy) {
	//     container = (
	//       <Tippy arrow={true} placement="bottom" content={<span className='text-sm bg-secondary-3 rounded px-2 py-1 shadow text-primary-1'>{tippy}</span>}>
	//         {children}
	//       </Tippy>
	//     )
	//   }
	//   return container
	// }

	return (
		// <InputContainer>
		//     </InputContainer>
		<button ref={ref} className={`${style.default} ${style.color[color || 'primary']} ${className}`} {...props}>
			{children}
		</button>
	);
});

Button.propTypes = {
	color: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node,
	tippy: PropTypes.string
};

export default Button;
