import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Transition from 'react-transition-group/Transition';
import { globalActions } from '../../store/globalSlice';
import toast from 'react-hot-toast';

const Spinner = ({ color = '', label = 'Please Wait...', isOpen }) => {
	const error = useSelector((state) => state.global.error);
	const dispatch = useDispatch();

	let borderColor;

	if (error) color = 'red';

	switch (color) {
		case 'red':
			borderColor = 'border-red-600 border-t-red-200';
			break;
		case 'green':
			borderColor = 'border-green-600 border-t-green-200';
			break;
		case 'orange':
			borderColor = 'border-orange-600 border-t-orange-200';
			break;
		case 'gray':
			borderColor = 'border-gray-600 border-t-gray-200';
			break;
		default:
			borderColor = 'border-blue-600 border-t-blue-200';
	}

	const opacityClasses = {
		entering: 'opacity-0 -mt-40',
		entered: 'opacity-100 -mt-20',
		exiting: 'opacity-0 mt-40',
		exited: 'opacity-0 mt-40'
	};

	const animationTiming = {
		enter: 0,
		exit: 300
	};

	const handleClose = () => {
		dispatch(globalActions.setLoading());
		toast.remove();
	};

	return (
		<Transition mountOnEnter unmountOnExit in={isOpen} timeout={animationTiming}>
			{(state) => {
				return (
					<div
						className={`fixed top-0 left-0 z-50 w-full h-full overflow-y-auto flex items-center ${
							!error && 'cursor-not-allowed podinter-events-none'
						}`}
					>
						<div
							className={`${opacityClasses[state]} mx-auto opacity-100 duration-300 ease-in-out flex items-center justify-between bg-gray-50 w-96 p-4 !shadow !shadow-gray-400 border border-gray-600`}
						>
							<div className={`flex-none w-16 h-16 mr-4 border-5 ${borderColor} border-solid rounded-full ${!error && 'animate-spin'}`}></div>
							<div className='flex-1 justify-center relative'>
								<p className='flex-1 text-2xl text-center'>{label}</p>
								<br />
								{error && (
									<p
										onClick={handleClose}
										className='cursor-pointer w-full absolute text-center mt-2 text-sm text-red-600 p-2 bg-white hover:!bg-red-100 !shadow !shadow-gray-400 border border-gray-600'
									>
										Please contact support. <br />
										Click this message to close
									</p>
								)}
							</div>
						</div>
					</div>
				);
			}}
		</Transition>
	);
};

Spinner.propTypes = {
	color: PropTypes.string,
	label: PropTypes.string,
	isOpen: PropTypes.bool
};

export default Spinner;
