import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	Users: [],
    Locations: [],
    LocationTypes: [],
    Platforms: [],
    Brand: [],
    Reasons: [],
    Waiting: [],
    Status: []
};

const dropdownSlice = createSlice({
	name: 'dropdown',
	initialState: {
		dropdownsData: initialState
	},
	reducers: {
		replaceDropdowns: (state, action) => {
			state.dropdownsData = action.payload;
		}
	}
});

export const dropdownActions = dropdownSlice.actions;

export default dropdownSlice;
