import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { forwardRef } from 'react';

/*  COMPONENT LOGIC */

const style = {
	dot: `after:content-['*'] after:ml-0.5 after:text-red-500`,
	error: `ring-red-500 ring-1`,
	disabled: `cursor-not-allowed`,
	container: `relative mb-6 mt-3`,
	errorMessage: `text-sm text-red-500 mt-2`,
	checkboxLabel: `block overflow-hidden h-6 rounded-full bg-white border-2 !border-secondary-3`,
	checkboxContainer: `relative w-10 mr-2 items-center align-middle select-none`,
	iconContainer: `absolute flex border border-transparent left-0 top-0 h-full w-10`,
	icon: `absolute right-2 h-full w-4 text-red-500 items-center top-0 flex items-center justify-center rounded-tl rounded-bl z-10 text-gray-400 text-lg h-full w-full`,
	checkbox: `!bg-gray-400 checked:!bg-green-700 disabled:checked:!bg-green-700/50 disabled:!bg-gray-400/50 checked:right-0 focus:outline-none right-4 duration-200 ease-in absolute block w-6 h-6 rounded-full border-2 !border-secondary-3 appearance-none cursor-pointer`,
	// default: `text-base relative flex flex-1 w-full mt-1 rounded-md py-2 px-4 bg-primary-2 text-gray-700 placeholder-gray-900 text-base focus:outline-none focus:ring-1 focus:border-transparent border`,
	default:
		'flex flex-wrap items-center rounded w-full h-10 py-0 px-2 text-black !border-secondary-3 outline-none !border !border-secondary-1 !box-border focus:border-box focus:border-2 focus:!border-secondary-2 hover:!border-secondary-2'
};

const FormField = forwardRef(({ disabled, dot, error, icon, label, name, type = 'text', checked, styleTag, className, ...rest }, ref) => {
	let component;

	// if you won't use select, you can delete this part
	if (type === 'select') {
		component = (
			<select
				aria-required={dot}
				aria-invalid={!!error}
				className={`${style.default} ${disabled ? style.disabled : ''}
             ${error ? style.error : 'border-gray-300'}
          `}
				disabled={disabled}
				id={name}
				name={name}
				ref={ref}
				{...rest}
			/>
		);
	}

	// if you won't use textarea, you can delete this part
	if (type === 'textarea') {
		component = (
			<textarea
				aria-required={dot}
				aria-invalid={!!error}
				className={`${className} ${style.default} ${disabled ? style.disabled : ''}
             ${error ? style.error : 'border-gray-300'}
          `}
				disabled={disabled}
				id={name}
				name={name}
				ref={ref}
				{...rest}
			/>
		);
	}

	// if you won't use checkbox, you can delete this part and the classes checkbox, checkboxContainer and checkboxLabel
	if (type === 'checkbox') {
		component = (
			<div className={`${style.checkboxContainer} `}>
				<input
					aria-required={dot}
					aria-invalid={!!error}
					className={`${style.checkbox} ${disabled ? style.disabled : ''}`}
					disabled={disabled}
					id={name}
					name={name}
					type='checkbox'
					checked={checked}
					onChange={() => {}}
					value={checked}
					{...rest}
				/>
				<span className={`${style.checkboxLabel} ${disabled && '!bg-gray-300'}`} />
			</div>
		);
	}

	// if you won't use input, you can delete this part
	if (type !== 'checkbox' && type !== 'select' && type !== 'textarea') {
		component = (
			<div className='relative w-full'>
				{icon && (
					<div className={style.iconContainer}>
						<div className={style.icon}>{icon}</div>
					</div>
				)}
				<input
					aria-required={dot}
					aria-invalid={!!error}
					className={`${style.default} ${className}
                            ${icon ? '!pl-12' : ''}
                            ${error ? style.error : 'border-gray-300'}
                            ${disabled ? style.disabled : ''}
                        `}
					disabled={disabled}
					id={name}
					name={name}
					type={type}
					ref={ref}
					{...rest}
				/>
				{error && <ExclamationCircleIcon className={style.icon} />}
			</div>
		);
	}

	return (
		// <div className={`${style.container} ${disabled ? 'opacity-50' : ''}`}>
		<>
			{label && (
				<label htmlFor={name} className={`${dot && style.dot} pointer-events-none`}>
					{label}
				</label>
			)}
			{component}
			{error && (
				<span role='alert' className={style.errorMessage}>
					{error}
				</span>
			)}
		</>
		// </div>
	);
});

FormField.displayName = 'Field';

FormField.propTypes = {
	disabled: PropTypes.bool,
	dot: PropTypes.string,
	error: PropTypes.string,
	icon: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	checked: PropTypes.bool,
	styleTag: PropTypes.bool,
	className: PropTypes.bool
};

export default FormField;
