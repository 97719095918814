import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormikControl from '../../components/ui/Formik/FormikControl';
import Button from '../../packages/button/Button';

import { login } from '../../store/authActions';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const initialValues = {
	email: '',
	password: ''
};

// Yup validation schema
const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email').required('Email required'),
	password: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Password required')
});

const Login = () => {
	const navigate = useNavigate();
	let location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	let state = location.state;
	let from = state ? state?.from?.pathname ?? '/' : '/';

	const handleSubmit = (formData) => {
		// window.alert(JSON.stringify(formData));
		// console.log('formdata', formData);
		const username = formData.email.split('@')[0];
		const loginData = {username: username, password: formData.password};
		dispatch(login(loginData));
	};

	useEffect(() => {
		console.log(user);
		if (!user.userName) return;
		if (from === '/register') {
			navigate('/', { replace: true });
			return;
		}

		navigate(from, { replace: true });
	}, [user, navigate, from]);

	return (
		<div className='m-auto max-w-md'>
			<h2 className='mb-4 text-primary-1 text-lg font-bold md:text-2xl'>User Login</h2>

			<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnBlur={false}>
				{() => (
					<Form>
						<div className=' border !border-primary-2 p-6 bg-primary-2 shadow-sm'>
							<FormikControl control='input' type='text' label='Email' name='email' />
							<FormikControl
								// className="!col-span-2 md:!col-span-3 xl:!col-span-5 2xl:!col-span-6"
								control='input'
								type='password'
								label='Password'
								name='password'
								autoComplete='off'
							/>
							<Button type='submit'>Login</Button>
						</div>
					</Form>
				)}
			</Formik>
			{/* <Link className='my-4 block' to='/register'>Register</Link> */}
		</div>
	);
};

export default Login;
