import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { pirCommentsActions } from './pirCommentsSlice';
import Toast from '../components/ui/Toast';

export const resetComments = () => {
	return async (dispatch) => {
		dispatch(pirCommentsActions.replacePirComments({ detailComments: null, headerComments: null, loading: null }));
	};
};

export const sortComments = (comments) => {
	return comments.sort((a, b) => new Date(a.auditDateTime).getTime() - new Date(b.auditDateTime).getTime());
};

export const getPirComments = (id) => {
	return async (dispatch) => {
		try {
			const pirComments = await getRequest('comments/?RequestHeaderId=' + id);
			// Further actions here

		} catch (error) {
			// console.error("getPirComment: " + error.message)
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(pirCommentsActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createPirComment = (pirCommentData) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.setLoading(true));
			const pirComment = await postRequest('comments/', pirCommentData);
			dispatch(pirCommentsActions.updatePirComment(pirComment));
			Toast({ status: 'success', message: 'Comment added' });
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const createPirCommentReply = (pirCommentReplyData) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.setLoading(true));
			const pirComment = await postRequest('commentsreply/', pirCommentReplyData);
			Toast({ status: 'success', message: 'Reply added' });
			dispatch(pirCommentsActions.updatePirComment(pirComment));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updatePirComment = (spoCommentData) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.setLoading(true));
			const response = await putRequest('pirComments', spoCommentData);
			Toast({ status: 'success', message: response });
			dispatch(pirCommentsActions.updatePirComment(spoCommentData));
		} catch (error) {
			// console.error("updatePirComment: " + error.message)
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deletePirComment = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`comments/${id}/`);
			Toast({ status: 'success', message: `Comment Deleted!` });
			dispatch(pirCommentsActions.removePirComment({ id }));
		} catch (error) {
			// console.error("deletePirComment: " + error.message)
			Toast({ status: 'error', message: 'Error Occured' });
		}
	};
};

export const setComments = (commentsData) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.setPirComments(commentsData));
		} catch (error) { }
	};
};


export const toggleCommentsRefresh = (value) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.toggleRefresh(value));
		} catch (error) { }
	};
};

export const toggleAddCommentLoading = (value) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.setLoading(value));
		} catch (error) { }
	};
};

export const toggleReplyCommentLoading = (value) => {
	return async (dispatch) => {
		try {
			dispatch(pirCommentsActions.setReplyLoading(value));
		} catch (error) { }
	};
};