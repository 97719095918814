import { useDispatch, useSelector } from 'react-redux';

const Links = () => {

	const profileData = useSelector((state) => state.profile.profilesData);
	if (!profileData.is_active) {
		return <Navigate to='/unauthorised' state={{ from: '/' }} />;
	}
	
	const user = useSelector((state) => state.auth.user);

	return (
		<>
			<div className='overflow-auto pdx-4 mdd:px-6 !text-primary-1 xl:Xmax-w-[calc(100%-0.5rem)] xl:madx-w-fit '>
				<h1 className='text-2xl font-semibold text-secondary-1 pb-2'>Hello {user.givenName},</h1>
				<div className='flex items-center'>
					<p className='text-md font-semibold text-secondary-1 pt-2'>
						Redirect Links to other useful pages are here {'>'}
					</p>
				</div>

				<div className='grid gdrid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 h-30 max-h-[140px] overflow-hidden xl:max-w-nodne dmax-w-fit'>
					<div className='w-full col-span-1'>
						<a href='https://prd.jlr-ecat.clifford-thames.com/auth/realms/jlr/protocol/openid-connect/auth?client_id=gui&redirect_uri=https%3A%2F%2Fepc.jlr-apps.com%2Fhome%2Fvehicle-selector%2Fvisual-access&state=2c68924e-c25b-47de-b06d-a168b9a40c50&response_mode=fragment&response_type=code&scope=openid&nonce=30e1fb4e-227c-438e-8221-13cdbf641147' target='blank'>
							<div className='shadow-md ppx-4 pb-6 w-full bg-primary-2  relative h-full'>
								<p className='h5 w-full text-primary-1 p-3 px-4 font-semibold bg-tertiary-cool-3'>EPC (Electronic Parts Catalogues)</p>
								<div className='flex items-end w-full space-x-2 px-4 my-3'>
									<p className='text-4xl w-full text-tertiary-cool-1 text-center font-bold'>Click Here</p>
								</div>
							</div>
						</a>
					</div>

					<div className='w-full col-span-1'>
						<a href='https://infostore.jlr-apps.com/reds-search' target='blank'>
							<div className='shadow-md ppx-4 pb-6 w-full bg-primary-2  relative font-bold'>
								<p className='h5 w-full text-primary-1 p-3 px-4 font-semibold bg-tertiary-cool-3'>REDS (Electronic Drawing Sections)</p>
								<div className='flex items-end w-full space-x-2 px-4 my-3'>
									<p className='text-4xl w-full text-tertiary-cool-1 text-center font-bold'>Click Here</p>
								</div>
							</div>
						</a>
					</div>

					<div className='w-full col-span-1'>
						<a href='https://jlrglobal-my.sharepoint.com/:w:/r/personal/dhemmi12_jaguarlandrover_com/_layouts/15/Doc.aspx?sourcedoc=%7B4F7A73B1-6753-4F4F-9C07-F7A4F2E10F81%7D&file=REDS%20screens%20(2).docx&action=default&mobileredirect=true' target='blank'>
							<div className='shadow-md ppx-4 pb-6 w-full bg-primary-2  relative font-bold'>
								<p className='h5 w-full text-primary-1 p-3 px-4 font-semibold bg-tertiary-cool-3'>REDS - How to ? (Sharepoint)</p>
								<div className='flex items-end w-full space-x-2 px-4 my-3'>
									<p className='text-4xl w-full text-tertiary-cool-1 text-center font-bold'>Click Here</p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Links;
