import React from 'react';
import { createRoot } from 'react-dom/client';
import './Global.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';

import { ThemeProvider } from './components/layout/ThemeProvider';

import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);

/* eslint-disable-next-line no-undef*/
const buildType = process.env.REACT_APP_BUILD_TYPE;

root.render(
	// <React.StrictMode>
	<ThemeProvider>
		<BrowserRouter>
			<Provider store={store}>
				<App buildType={buildType} tab='home' />
			</Provider>
		</BrowserRouter>
	</ThemeProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
