const menuOptions = [
	{
		label: 'Home',
		icon: 'Home',
		group: 'side-bar-top',
		to: '/'
	},
	{
		label: 'View All PIRs',
		icon: 'Table',
		group: 'side-bar-top',
		to: '/pir/alldata'
	},
	{
		label: 'Create PIR',
		icon: 'DocumentAdd',
		group: 'side-bar-top',
		to: '/pir/create'
	},
	{
		label: 'Email',
		icon: 'Mail',
		group: 'side-bar-top',
		to: '/pir/email'
	},
	{
		label: "External Links",
		icon: "HashTag",
		group: "side-bar-top",
		to: "/links",
	  },
	{
		label: 'Light Mode|Dark Mode', //"Light Mode|Dark Mode|Custom Mode"
		icon: 'Sun|Moon', // "Sun|Moon|ColorSwatch"
		group: 'side-bar-bottom',
		action: 'changeTheme'
	},
	{
		label: 'Settings',
		icon: 'Adjustments',
		group: 'side-bar-bottom',
		to: '/settings'
	},
	{
		label: 'Help',
		group: 'nav-bar'
	},
	{
		label: 'Messages',
		group: 'nav-bar'
	}
];

module.exports = menuOptions;
