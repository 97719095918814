import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	data: []
};

const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		profilesData: initialState,
		loginSuccess: false
	},
	reducers: {
		replaceProfiles: (state, action) => {
			state.profilesData = action.payload;
		},
		updateLoginStatus: (state, action) => {
			state.loginSuccess = action.payload;
		}
	}
});

export const profileActions = profileSlice.actions;

export default profileSlice;
