import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const customStyles = {
	control: (base, state) => ({
		...base,
		minHeight: '2.5rem',
		padding: '0px',
		outline: '1px solid transparent',
		borderRadius: '0.25rem',
		boxShadow: state.isFocused || state.isActive ? '0 0 0 1px rgb(140,140,140)' : 0,
		borderColor: 'rgb(168,168,168)',
		borderWidth: '1px',
		'&:hover': {
			border: '1px solid rgb(140,140,140)'
		},
		'&:focus': {
			// border: '1px solid rgb(140,140,140)',
			// outline: '1px solid rgb(140,140,140)',
		},
		'&:active': {
			border: '1px solid rgb(140,140,140)'
		},
		width: '100%'
	}),
	option: (base, state) => ({
		...base,
		backgroundColor: state.isSelected ? 'rgb(87,87,87)' : '',
		'&:hover': {
			backgroundColor: state.isSelected ? 'rgb(140,140,140)' : 'rgb(232,232,232)',
			color: state.isSelected ? 'black' : 'rgb(30,30,30)'
		}
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: 'rgb(30,30,30)'
	}),
	menuPortal: (base) => ({ ...base, zIndex: '1000' })
};

const SelectInput = ({
	className,
	placeholder,
	name,
	value,
	onChangeHandler,
	label,
	options,
	isMulti = false,
	isClearable = true,
	isCreatable = false,
	byLabel = false,
	...rest
}) => {
	const onChange = (option) => {
		// console.log('onChange', option)
		let values;
		if (isMulti) values = !option ? [] : option.map((item) => (byLabel ? item.label : item.value));
		else values = !option ? '' : byLabel ? option.label : option.value;
		onChangeHandler(values);
	};

	const getValue = () => {
		const isArray = Array.isArray(value);
		// console.log('getValue', isMulti, isArray, options, value, byLabel)
		let val;
		if (options) {
			if (isMulti && isArray) val = options.filter((option) => value.includes(byLabel ? option.label : option.value)) ?? [];
			else val = options.find((option) => (byLabel ? option.label : option.value) === value) ?? '';
		} else {
			val = isMulti ? [] : '';
		}

		return val;
	};

	return (
		<>
			<label htmlFor={name} className={`${!label && 'hidden'} text-secondary-1 text-sm font-bold leading-tight tracking-normal mb-2`}>
				{label}
			</label>
			{isCreatable ? (
				<CreatableSelect
					{...rest}
					className={`shadow-sm ${className || ''}`}
					name={name}
					value={getValue()}
					onChange={onChange}
					placeholder={placeholder}
					options={options}
					isMulti={isMulti}
					styles={customStyles}
					isClearable={isClearable}
					menuPortalTarget={document.body}
				/>
			) : (
				<Select
					{...rest}
					className={`shadow-sm ${className || ''}`}
					name={name}
					value={getValue()}
					onChange={onChange}
					placeholder={placeholder}
					options={options}
					isMulti={isMulti}
					styles={customStyles}
					isClearable={isClearable}
					menuPortalTarget={document.body}
				/>
			)}
		</>
	);
};

export default SelectInput;

SelectInput.propTypes = {
	buildType: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.number,
	onChangeHandler: PropTypes.func,
	label: PropTypes.string,
	options: PropTypes.array,
	isMulti: PropTypes.bool,
	isClearable: PropTypes.bool,
	isCreatable: PropTypes.bool,
	byLabel: PropTypes.bool
};
