import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { usersActions } from './usersSlice';
import Toast from '../components/ui/Toast';

export const getUsers = () => {
	return async (dispatch) => {
		try {
			const users = await getRequest('users');
			dispatch(usersActions.replaceUsers(users));
		} catch (error) {
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(usersActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createUser = (userData) => {
	return async (dispatch) => {
		try {
			const user = await postRequest('users/', userData);
			Toast({ status: 'success', message: `User Added Successfully!` });
			dispatch(usersActions.updateUser(user));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateUser = (userData) => {
	return async (dispatch) => {
		try {
			const response = await putRequest(`users/${userData.id}/`, userData);
			Toast({ status: 'success', message: 'User Updated!' });
			dispatch(usersActions.updateUser(userData));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteUser = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`users/${id}/`);
			Toast({ status: 'success', message: `User Deleted!` });
			dispatch(usersActions.removeUser(id));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};
