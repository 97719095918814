import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
} from "@heroicons/react/solid";
import { cloneElement } from "react";
import toast from "react-hot-toast";

const Toast = ({ status, message, duration, position }) => {
  let msg = {};

  switch (status) {
    case "success":
      msg.color = "text-green-600";
      msg.background = "bg-green-50";
      msg.backgroundHover = "hover:!bg-green-100";
      msg.icon = <CheckCircleIcon />;
      duration = 7000;
      break;
    // case "pending":
    // case "info":
    //   msg.color = "text-blue-600";
    //   msg.background = "bg-blue-100";
    //   msg.icon = <InformationCircleIcon />;
    //   break;
    case "error":
      msg.color = "text-red-600";
      msg.background = "bg-red-50";
      msg.backgroundHover = "hover:!bg-red-100";
      msg.icon = <ExclamationCircleIcon />;
      duration = 600000;
      break;
    case "warning":
      msg.color = "text-yellow-600";
      msg.background = "bg-yellow-50";
      msg.backgroundHover = "hover:!bg-yellow-100";
      msg.icon = <ExclamationIcon />;
      duration = 7000;
      break;
    default:
      msg.color = "text-blue-600";
      msg.background = "bg-blue-50";
      msg.backgroundHover = "hover:!bg-blue-100";
      msg.icon = <InformationCircleIcon />;
      duration = 10000;
      break;
  }

  var icon = cloneElement(msg.icon, {
    className: `w-6 h-6 ${msg.color}`,
  });

  return toast.custom(
    (t) => (
      <div
        className={`flex items-center justify-between min-w-[240px] p-4 ${msg.background} ${msg.backgroundHover} border cursor-pointer rounded-sm shadow-sm ignore-click-outside`}
        onClick={() => toast.remove(t.id)}
      >
        <div className="flex items-center pointer-events-none">
          {icon}
          <p className={`ml-3 text-sm font-bold pointer-events-none ${msg.color}`}>{message}</p>
        </div>
      </div>
    ),
    {
      duration: duration,
      position: position
    }
  );
};

export default Toast;
