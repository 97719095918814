import { getRequest, postRequest, putRequest, deleteRequest } from '../axios';
import { contactsActions } from './contactsSlice';
import Toast from '../components/ui/Toast';

export const getContacts = () => {
	return async (dispatch) => {
		try {
			const contacts = await getRequest('contacts');
			dispatch(contactsActions.replaceContacts(contacts));
		} catch (error) {
			if (error.message.substring(0, 14) === 'Request failed' || error.message.substring(0, 2) === '40') {
				dispatch(contactsActions.setLoading(false));
			}
			if (error.message.substring(0, 14) !== 'Request failed' && error.message.substring(0, 2) !== '40')
				Toast({ status: 'error', message: error.message });
		}
	};
};

export const createContact = (contactData) => {
	return async (dispatch) => {
		try {
			const contact = await postRequest('contacts/', contactData);
			Toast({ status: 'success', message: `Contact: ${contact.Email} successfully added` });
			dispatch(contactsActions.updateContact(contact));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const updateContact = (contactData) => {
	return async (dispatch) => {
		try {
			const response = await putRequest(`contacts/${contactData.id}/`, contactData);
			Toast({ status: 'success', message: 'Contact Updated!' });
			dispatch(contactsActions.updateContact(contactData));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};

export const deleteContact = (id) => {
	return async (dispatch) => {
		try {
			await deleteRequest(`contacts/${id}/`);
			Toast({ status: 'success', message: `Contact Deleted!` });
			dispatch(contactsActions.removeContact(id));
		} catch (error) {
			Toast({ status: 'error', message: error.message });
		}
	};
};
