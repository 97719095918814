import { BanIcon } from "@heroicons/react/solid";
import { useNavigate, useLocation } from "react-router-dom";
import Jumbotron from "../components/ui/Jumbotron";
import Button from "../packages/button/Button";

const NotLoggedIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const from = state ? state.from.pathname : '/';

  return (
    <Jumbotron className="bg-primary-2 text-primary-1 mx-auto">
      <h1 className="text-lg md:text-2xl py-4 mb-3 flex items-center">
        <BanIcon className="h-12 pr-6" /> Sorry, you aren't logged in
      </h1>
      <p>
        If you want to access this page, please Login or Register yourself. For any additional support, please contact
        <a href="mailto:pir-application-support_smb@jaguarlandrover.com" className="text-blue-500"> pir-application-support_smb@jaguarlandrover.com </a>
      </p>
      <br />
      <Button color="primary" onClick={() => navigate(from, { replace: true })}>Go Back</Button>
    </Jumbotron>
  );
};

export default NotLoggedIn;
